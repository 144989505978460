import { SignupUserRequest } from "../types/SafeswimUser";
import SafeswimUser from "../types/SafeswimUser";
import { del, get, post, put } from "./instance";
import SafeswimLocation from "../types/SafeswimLocation";

export async function me(): Promise<SafeswimUser> {
    return await get<SafeswimUser>(`/v2/me`);
}

export async function signupUser(
    request: SignupUserRequest
): Promise<SafeswimUser> {
    return await post<SafeswimUser>(`/v1/users/safeswim`, request);
}

export async function updateUser(
    userId: string,
    request: SignupUserRequest
): Promise<SafeswimUser> {
    return await put<SafeswimUser>(
        `/v1/users/safeswim/update/${userId}`,
        request
    );
}

export async function getUserFavouriteLocations(
    userId: string
): Promise<SafeswimLocation[]> {
    return await get<SafeswimLocation[]>(
        `/v1/users/safeswim/favourites/${userId}`
    );
}

export async function removeBeachFromFavourites(
    userId: string,
    locationId: number
): Promise<SafeswimLocation[]> {
    return await put<SafeswimLocation[]>(
        `/v1/users/safeswim/favourites/remove/${userId}/${locationId}`
    );
}

export async function addBeachToFavourites(
    userId: string,
    locationId: number
): Promise<SafeswimLocation[]> {
    return await post<SafeswimLocation[]>(
        `/v1/users/safeswim/favourites/${userId}/${locationId}`
    );
}

export async function deleteAccount() {
    return await del(`/v1/users/me`);
}

export async function updateUserNotificationToken(
    notificationToken: string
): Promise<void> {
    await post(`/v1/users/notifications/notification-tokens`, {
        notificationToken
    });
}
