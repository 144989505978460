import classNames from "classnames";
import { addDays, format, getHours, set } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useEffect, useMemo, useRef } from "react";
import SafeswimLocation, {
    Forecast,
    ForecastItem
} from "../../../types/SafeswimLocation";
import WeatherForecast, {
    WeatherForecastItem
} from "../../../types/WeatherForecast";
import {
    containsTag,
    getAlertForecast,
    getCurrentWaterQualityLevel,
    getWaterQualityLevelIgnoreAlerts,
    WaterQualityLevel
} from "../../../utils/location";
import {
    getIconPhrase,
    getWeatherIcon,
    getWindIcon
} from "../../drawer/locationDrawer/components/forecastItems/util";
import WaterQualityIcon from "../../images/icons/WaterQuality";
import WaterTempIcon from "../../images/icons/WaterTempIcon";
import styles from "./WeatherCard.module.scss";

type WeatherCardProps = {
    location?: SafeswimLocation;
    weatherForecast?: WeatherForecast;
    forecast?: Forecast;
};

export default function WeatherCard({
    location,
    weatherForecast,
    forecast
}: WeatherCardProps) {
    const ref = useRef<HTMLDivElement>(null);

    const hasNoWaterQuality = useMemo((): boolean => {
        if (
            containsTag(location, "no_water_quality") ||
            containsTag(location, "fresh_water_hazard") ||
            !location
        ) {
            return true;
        }
        return false;
    }, [location]);

    useEffect(() => {
        if (ref.current) {
            const nowHours = new Date().getHours();
            const now = set(new Date(), {
                hours: nowHours % 2 === 0 ? nowHours - 1 : nowHours,
                minutes: 0,
                seconds: 0,
                milliseconds: 0
            });

            const element = document.getElementById(
                `weather-${now.toISOString()}`
            );

            if (element) {
                ref.current.scrollBy(
                    element.getBoundingClientRect().x -
                        element.getBoundingClientRect().width +
                        36,
                    0
                );
            }
        }
    }, [ref]);

    if (
        !location ||
        !weatherForecast ||
        !forecast ||
        !weatherForecast.today ||
        !forecast.today ||
        location.tags.includes("fresh_water_hazard")
    ) {
        return null;
    }
    const today = new Date();
    const tomorrow = addDays(today, 1);
    const dayAfter = addDays(today, 2);

    const items = [
        ...getListItems(
            weatherForecast.today || {},
            forecast.today || {},
            today,
            location,
            hasNoWaterQuality,
            true
        ),
        ...getListItems(
            weatherForecast.tomorrow || {},
            forecast.tomorrow || {},
            tomorrow,
            location,
            hasNoWaterQuality
        ),
        ...getListItems(
            weatherForecast.dayAfter || {},
            forecast.dayAfter || {},
            dayAfter,
            location,
            hasNoWaterQuality
        )
    ];

    return (
        <div className={styles.weather_card}>
            <div className={styles.title}>
                {hasNoWaterQuality
                    ? "Weather Forecast"
                    : "Water and weather Forecast"}
            </div>
            <div className={styles.divider} />
            <div ref={ref} className={styles.forecast_list}>
                {items}
            </div>
            {!hasNoWaterQuality && (
                <div className={styles.key}>
                    <div className={styles.key_item}>
                        {" "}
                        <WaterQualityIcon
                            level="green"
                            className={getColourClasses("green")}
                        />
                        <span className={styles.no_wrap}>Low risk</span>
                    </div>
                    <div className={styles.key_item}>
                        {" "}
                        <WaterQualityIcon
                            level="red"
                            className={getColourClasses("red")}
                        />
                        <span className={styles.no_wrap}>High risk</span>
                    </div>
                    <div className={styles.key_item}>
                        {" "}
                        <WaterQualityIcon
                            level="black"
                            className={getColourClasses("black")}
                        />
                        <div className={styles.no_wrap_height}>
                            <span>
                                High risk <span>(Wastewater overflow)</span>
                            </span>
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.btm_divider} />
            <div className={styles.water_temp}>
                <WaterTempIcon />
                <div>Water temp: {getWaterTempItem(forecast.today)}</div>
            </div>
        </div>
    );
}

function getWaterTempItem(forecast: ForecastItem) {
    if (!forecast.waterTemp) {
        return "Unavailable";
    }

    const nowHours = getHours(new Date());

    let waterTempItem = undefined;
    if (nowHours % 2 === 1) {
        waterTempItem = forecast.waterTemp[nowHours];
    } else {
        waterTempItem = forecast.waterTemp[nowHours - 1];
    }

    if (!waterTempItem) {
        return "Unavailable";
    }

    return <span>{Number(waterTempItem.value).toFixed(0)}&#176;C</span>;
}

export const getColourClasses = (level?: string) =>
    classNames({
        [styles.green]: level === WaterQualityLevel.GOOD,
        [styles.red]: level === WaterQualityLevel.HIGH_RISK,
        [styles.yellow]: level === "yellow",
        [styles.dark_grey]: level === WaterQualityLevel.VERY_HIGH_RISK,
        [styles.grey]: level === WaterQualityLevel.UNAVAILABLE || !level
    });

function getListItems(
    weatherForecast: { [hourOfDay: number]: WeatherForecastItem },
    forecast: ForecastItem,
    date: Date,
    location: SafeswimLocation,
    hasNoWaterQuality: boolean = false,
    isToday: boolean = false
) {
    const items = [];
    date = set(date, {
        minutes: 0,
        seconds: 0,
        milliseconds: 0
    });
    const alert = (location.alerts?.waterQuality || [])[0] || null;

    let nowHours = new Date().getHours();

    if (nowHours < 5) {
        nowHours = 5;
    } else if (nowHours > 21) {
        nowHours = 21;
    }

    nowHours = nowHours % 2 === 0 ? nowHours - 1 : nowHours;

    const now = set(
        utcToZonedTime(
            set(new Date(), { minutes: 0, seconds: 0, milliseconds: 0 }),
            "Pacific/Auckland"
        ),
        {
            hours: nowHours,
            minutes: 0,
            milliseconds: 0
        }
    );

    for (let i = isToday ? nowHours : 5; i <= 21; i += 2) {
        const itemDate = set(utcToZonedTime(date, "Pacific/Auckland"), {
            hours: i
        });
        const formattedDay = format(itemDate, "eee");
        const formattedTime = format(itemDate, "haaa");
        const weatherItem = weatherForecast[i];
        const windItem = forecast.wind[i];
        let level;

        if (location.unavailable) {
            level = WaterQualityLevel.UNAVAILABLE;
        } else if (location.isPermanent) {
            level = getCurrentWaterQualityLevel(location);
        } else if (forecast.water[i.toString()]) {
            level = getWaterQualityLevelIgnoreAlerts(
                location,
                forecast.water[i.toString()].value
            );
            if (alert) {
                level = getAlertForecast(alert, date, i, level);
            }
        } else {
            level = WaterQualityLevel.UNAVAILABLE;

            if (alert) {
                level = getAlertForecast(alert, date, i);
            }
        }

        const currentForecast = itemDate.toISOString() === now.toISOString();

        items.push(
            <div
                id={`weather-${itemDate.toISOString()}`}
                key={`weather-${itemDate.toISOString()}`}
                className={classNames(styles.forecast_item, {
                    [styles.current_forcast]: currentForecast
                })}
            >
                <span
                    className={classNames(styles.capital, styles.bold, {
                        [styles.bold_black]: currentForecast
                    })}
                >
                    {currentForecast ? "Now" : formattedDay}
                </span>
                {!hasNoWaterQuality && (
                    <>
                        {" "}
                        <WaterQualityIcon
                            level={level}
                            className={getColourClasses(level)}
                        />
                        <span className={styles.time}>{formattedTime}</span>
                        <div className={styles.spacer} />
                    </>
                )}
                {getWeatherIcon(weatherItem?.IconPhrase, styles.weather_icon)}
                <span
                    className={classNames(
                        styles.bold_black,
                        styles.weather_direction
                    )}
                >
                    {weatherItem?.Temperature?.Value.toFixed(0)}&#176;C
                </span>
                <span className={styles.subtext}>
                    {getIconPhrase(weatherItem?.IconPhrase)}
                </span>
                <div className={styles.spacer} />
                {getWindIcon(windItem?.speed, windItem?.direction)}
                {!!windItem && (
                    <span
                        className={classNames(
                            styles.bold_black,
                            styles.weather_direction
                        )}
                    >
                        {windItem.direction}
                    </span>
                )}
                <span
                    className={classNames({
                        [styles.subtext]: windItem
                    })}
                >
                    {!windItem
                        ? "Not available"
                        : `${Number(windItem.speed).toFixed(0)}km/h`}
                </span>
            </div>
        );
    }

    return items;
}
