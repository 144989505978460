type Props = {
    className?: string;
};

export default function HamburgerIcon(props: Props) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="none"
        >
            <g data-name="Interface-Essential / Menu / navigation-menu-1">
                <path
                    d="M2.66663 4H13.3333M2.66663 8H13.3333M2.66663 12H13.3333"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
