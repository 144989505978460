import SafeswimLocation from "../types/SafeswimLocation";
import {
    containsTag,
    getCurrentWaterQualityLevel,
    isCurrentlyPatrolled,
    WaterQualityLevel
} from "./location";
import WaterQuality from "../components/map/pins/WaterQuality";
import Branded from "../components/map/pins/Branded";

export const DEFAULT_LAT = -36.8509;
export const DEFAULT_LNG = 174.7645;

export const CENTERED_MAP_COORDS = {
    LAT: DEFAULT_LAT,
    LNG: DEFAULT_LNG
};

export const getMarkerIcon = (location: SafeswimLocation): string => {
    const hazardAlerts = location.alerts?.hazards || [];
    const hasRedFlagAlert =
        location.alerts &&
        location.alerts.hazards.filter(
            (a) =>
                typeof a.value === "string" &&
                a.value === "dangerous_conditions"
        ).length > 0;
    const isPatrolled = containsTag(location, "sls_patrolled");
    const isSLSOnly = containsTag(location, "sls_only");
    const isFreshWaterHazard = containsTag(location, "fresh_water_hazard");
    const noWaterQuality = containsTag(location, "no_water_quality");
    const currentlyPatrolled = isCurrentlyPatrolled(location);

    if (isFreshWaterHazard) {
        return WaterQuality.freshWaterHazard;
    }

    if (isSLSOnly || noWaterQuality) {
        if (hazardAlerts.length > 0) {
            if (hasRedFlagAlert && hazardAlerts.length > 1) {
                return Branded.brandedHazardRedFlag;
            } else if (hasRedFlagAlert && hazardAlerts.length === 1) {
                return Branded.brandedRedFlag;
            }

            if (isPatrolled) {
                if (currentlyPatrolled) {
                    return Branded.brandedHazardSLS;
                }

                return Branded.brandedHazardSLSOff;
            }

            return Branded.brandedHazard;
        }
        if (isPatrolled) {
            if (currentlyPatrolled) {
                return Branded.brandedSLS;
            }
            return Branded.brandedSLSOff;
        }
        return Branded.branded;
    }

    const waterQualityLevel = getCurrentWaterQualityLevel(location);

    if (
        location.isPermanent &&
        location.permanentWaterQuality &&
        waterQualityLevel === WaterQualityLevel.HIGH_RISK
    ) {
        return WaterQuality.longTermAlert;
    }

    if (hazardAlerts.length > 0) {
        if (hasRedFlagAlert && hazardAlerts.length > 1) {
            return WaterQuality[`${waterQualityLevel}_HazardRedFlag`];
        } else if (hasRedFlagAlert && hazardAlerts.length === 1) {
            return WaterQuality[`${waterQualityLevel}_RedFlag`];
        }

        if (isPatrolled) {
            if (currentlyPatrolled) {
                return WaterQuality[`${waterQualityLevel}_HazardSLS`];
            }
            return WaterQuality[`${waterQualityLevel}_HazardSLSOff`];
        }

        return WaterQuality[`${waterQualityLevel}_Hazard`];
    }

    if (isPatrolled) {
        if (currentlyPatrolled) {
            return WaterQuality[`${waterQualityLevel}_SLS`];
        }
        return WaterQuality[`${waterQualityLevel}_SLSOff`];
    }

    return WaterQuality[`${waterQualityLevel}`];
};

export const getMarkerIconSize = (
    zoom: number,
    location?: SafeswimLocation
): Partial<google.maps.Icon> => {
    let hasRedFlagAlert = false;
    let hasHazardAlert = false;
    let isPatrolled = containsTag(location, "sls_patrolled");
    if (location && location.alerts && location.alerts.hazards) {
        hasRedFlagAlert =
            location.alerts.hazards.filter(
                (a) =>
                    typeof a.value === "string" &&
                    a.value === "dangerous_conditions"
            ).length > 0;
        hasHazardAlert = !hasRedFlagAlert && location.alerts.hazards.length > 0;
    }

    let iconSize = {
        scaledSize: new google.maps.Size(35.5, 55.5),
        size: new google.maps.Size(35, 55.5),
        anchor: new google.maps.Point(20, 50)
    };

    if (hasRedFlagAlert || isPatrolled) {
        iconSize = {
            scaledSize: new google.maps.Size(55, 71),
            size: new google.maps.Size(55, 71),
            anchor: new google.maps.Point(20, 69)
        };
    } else if (!hasRedFlagAlert && hasHazardAlert && !isPatrolled) {
        iconSize = {
            scaledSize: new google.maps.Size(40.5, 66.5),
            size: new google.maps.Size(40.5, 66.5),
            anchor: new google.maps.Point(20, 65)
        };
    }

    if (zoom < 11) {
        iconSize = {
            scaledSize: new google.maps.Size(17, 27),
            size: new google.maps.Size(17, 27),
            anchor: new google.maps.Point(8, 26)
        };

        if (hasRedFlagAlert || isPatrolled) {
            iconSize = {
                scaledSize: new google.maps.Size(27.5, 35.5),
                size: new google.maps.Size(27.5, 35.5),
                anchor: new google.maps.Point(8, 33)
            };
        } else if (!hasRedFlagAlert && hasHazardAlert && !isPatrolled) {
            iconSize = {
                scaledSize: new google.maps.Size(20.25, 33.25),
                size: new google.maps.Size(20.25, 33.25),
                anchor: new google.maps.Point(8, 32)
            };
        }
    }

    return iconSize;
};
