import { useEffect, useRef } from "react";

declare global {
    interface Window {
        ReactNativeWebView?: {
            postMessage?: (message: string) => void | Promise<void>;
        };
    }
}

const postMessageToWebview = async (message: Record<string, any>) => {
    await window.ReactNativeWebView?.postMessage?.(JSON.stringify(message));
};

/**
 * Emit an event signifying the current user is authed.
 */
export const useEmitAuthedToWebview = (hasUser: boolean) => {
    const hasEmitted = useRef(false);

    useEffect(() => {
        if (hasEmitted.current) {
            return;
        }

        if (hasUser && !hasEmitted.current) {
            console.debug("Posting authed message to webview");

            postMessageToWebview({
                type: "webview/authed"
            });
        }
    }, [hasUser, hasEmitted]);
};
