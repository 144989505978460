import { useEffect, useRef, useState } from "react";
import { updateUserNotificationToken } from "../../api/users";
import { z } from "zod";

const WebviewTokenMessage = z.object({
    type: z.literal("webview/token"),
    token: z.string()
});

/**
 * Listen to token from URL params and/or message event
 */
export const useTokenListener = (hasUser: boolean) => {
    const hasRegistered = useRef(false);
    const [token, setToken] = useState<string>();
    const hasToken = token !== undefined;

    // A: Capture token from initial query params
    useEffect(() => {
        const { search } = window.location;
        const token = new URLSearchParams(search).get("token");

        const isExpoToken = token !== null && token.startsWith("Expo");

        console.debug("Found token param", token, { isExpoToken });

        if (isExpoToken) {
            console.debug("Saving expo token.");
            setToken(token);
        }
    }, []);

    // B: capture token from webview message
    useEffect(() => {
        const listener = (event: Event) => {
            if (!(event instanceof MessageEvent)) {
                return;
            }

            const { data } = event;

            if (typeof data !== "string") {
                return;
            }

            let raw;
            try {
                raw = JSON.parse(data);
            } catch (e) {
                console.warn(e);
            }

            if (raw === undefined) {
                return;
            }

            const parsed = WebviewTokenMessage.safeParse(raw);

            if (!parsed.success) {
                return;
            }

            const { token } = parsed.data;

            console.debug("Received token from weview", token);
            setToken(token);
        };

        [document, window].forEach((x) => {
            x.addEventListener("message", listener);
        });

        return () => {
            [document, window].forEach((x) => {
                x.addEventListener("message", listener);
            });
        };
    }, []);

    // Register device token if token + user
    useEffect(() => {
        if (hasRegistered.current) {
            console.debug("Already registered device.");
            return;
        }

        if (!hasUser) {
            console.debug("No user found. Skipping token registration.");
            return;
        }

        if (!hasToken) {
            return;
        }

        console.debug("Registering device token...");
        updateUserNotificationToken(token).then(() => {
            console.debug("Device token forwarded to API");
        });
    }, [hasUser, hasToken, hasRegistered]);
};
