import { ModalActionType, useModalContext } from "../../../context/modal";

import { Modal } from "../Modal";
import styles from "./LifeguardModal.module.scss";
interface LifeguardModalProps {
    show: boolean;
}

export const LifeguardModal = (props: LifeguardModalProps) => {
    const { show } = props;
    const { hideModal } = useModalContext();

    return (
        <Modal
            isOpen={show}
            onClose={() => hideModal(ModalActionType.LIFEGUARD_MODAL)}
        >
            <div className={styles.content}>
                <h3>Lifeguards not on duty until next Summer</h3>
                <p>
                    <strong>IF SOMEONE IN THE WATER IS IN TROUBLE:</strong>{" "}
                    <br />{" "}
                    <a href="tel:111" className={styles.highlight}>
                        Dial 111
                    </a>{" "}
                    and ask for the Police.
                </p>
            </div>
        </Modal>
    );
};
