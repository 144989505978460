type Props = {
    className?: string;
    alertCount?: number;
    active?: boolean;
};

export default function AlertIcon(props: Props) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${!!props.alertCount ? 30 : 16} 16`}
            width={!!props.alertCount ? 30 : 16}
            height={16}
            fill="none"
        >
            <g
                data-name="Group 53587"
                transform={!!props.alertCount ? "translate(7, 0)" : ""}
            >
                <g data-name="Icon / 24px / alert-diamond">
                    <path
                        d="M8 6.5V9"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M7.1374 2.49991L1.6374 11.9999C1.54978 12.1517 1.50357 12.3238 1.50342 12.499C1.50326 12.6743 1.54916 12.8465 1.63651 12.9984C1.72386 13.1503 1.8496 13.2766 2.00113 13.3646C2.15266 13.4526 2.32466 13.4993 2.4999 13.4999H13.4999C13.6751 13.4993 13.8471 13.4526 13.9987 13.3646C14.1502 13.2766 14.2759 13.1503 14.3633 12.9984C14.4506 12.8465 14.4965 12.6743 14.4964 12.499C14.4962 12.3238 14.45 12.1517 14.3624 11.9999L8.8624 2.49991C8.77543 2.34796 8.64987 2.22167 8.49841 2.13384C8.34695 2.04601 8.17498 1.99976 7.9999 1.99976C7.82481 1.99976 7.65284 2.04601 7.50138 2.13384C7.34992 2.22167 7.22436 2.34796 7.1374 2.49991V2.49991Z"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8 12C8.41421 12 8.75 11.6642 8.75 11.25C8.75 10.8358 8.41421 10.5 8 10.5C7.58579 10.5 7.25 10.8358 7.25 11.25C7.25 11.6642 7.58579 12 8 12Z"
                        fill="currentColor"
                    />
                    <path
                        d="M8.25 11.25C8.25 11.3881 8.13807 11.5 8 11.5C7.86193 11.5 7.75 11.3881 7.75 11.25C7.75 11.1119 7.86193 11 8 11C8.13807 11 8.25 11.1119 8.25 11.25Z"
                        stroke="currentColor"
                    />
                </g>
                {!!props.alertCount && (
                    <g
                        data-name="Group 53059"
                        transform="scale(0.8),translate(2,0)"
                    >
                        <path
                            data-name="Path 320960"
                            d="M18 0a7 7 0 0 1 7 7 7.21 7.21 0 0 1-2.05 4.95A6.759 6.759 0 0 1 18 14a7 7 0 0 1 0-14Z"
                            fill="#ee3124"
                        />
                        <text
                            data-name="3"
                            transform="translate(18 10.5)"
                            fill="#fff"
                            fontSize="10"
                            fontFamily="National2"
                            fontWeight="700"
                            textAnchor="middle"
                        >
                            <tspan>{props.alertCount}</tspan>
                        </text>
                    </g>
                )}
            </g>
        </svg>
    );
}
