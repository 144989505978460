import styles from "./Button.module.scss";
import classNames from "classnames";
import React, {
    ButtonHTMLAttributes,
    PropsWithChildren,
    ReactElement
} from "react";

type Props = {
    color?:
        | "primary"
        | "secondary"
        | "darker"
        | "darkest"
        | "lighter"
        | "destructive"
        | "white"
        | "sunrise";
    size?: "sm" | "md" | "lg";
    variant?: "filled" | "outline" | "link";
    icon?: ReactElement | string;
    iconPosition?: "left" | "right";
    iconSize?: "sm" | "md" | "lg";
    shadow?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Icon = (props: { icon?: string | ReactElement; className?: string }) => {
    if (!props.icon) {
        return null;
    }

    if (typeof props.icon === "string") {
        return <img src={props.icon} alt={""} />;
    }

    return <span className={props.className}>{props.icon}</span>;
};

export default function Button(props: PropsWithChildren<Props>) {
    const {
        children,
        variant,
        size,
        color,
        icon,
        iconPosition,
        type,
        iconSize = "sm",
        ...buttonProps
    } = props;

    const className = classNames(
        styles.button,
        {
            [styles.md]: size === "md",
            [styles.lg]: size === "lg",
            [styles.sm]: !size || size === "sm"
        },
        {
            [styles.primary]: color === "primary",
            [styles.darker]: !color || color === "darker",
            [styles.darkest]: color === "darkest",
            [styles.lighter]: color === "lighter",
            [styles.destructive]: color === "destructive",
            [styles.white]: color === "white",
            [styles.secondary]: color === "secondary",
            [styles.sunrise]: color === "sunrise"
        },
        {
            [styles.filled]: !variant || variant === "filled",
            [styles.outline]: variant === "outline",
            [styles.link]: variant === "link"
        },
        {
            [styles.left]: iconPosition === "left",
            [styles.right]: !iconPosition || iconPosition === "right"
        },
        props.className
    );

    const iconClasses = classNames({
        [styles.icon_sm]: iconSize === "sm",
        [styles.icon_md]: iconSize === "md",
        [styles.icon_lg]: iconSize === "lg"
    });
    return (
        <button {...buttonProps} className={className} type={type ?? "button"}>
            {icon && iconPosition === "left" ? (
                <Icon icon={icon} className={iconClasses} />
            ) : null}
            {children}
            {icon && (!iconPosition || iconPosition === "right") ? (
                <Icon icon={icon} className={iconClasses} />
            ) : null}
        </button>
    );
}
