export default function SafeswimIcon(props: React.HTMLAttributes<SVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <rect
                width="40"
                height="40"
                rx="8"
                fill="#23221F"
                fillOpacity="0.6"
            />
            <defs>
                <clipPath id="clip">
                    <circle cx="20" cy="20" r="18" fill="#D9D9D9" />
                </clipPath>
            </defs>
            <g clipPath="url(#clip)">
                <path
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
                    fill="#005284"
                />
                <path
                    d="M19.4736 33.9908C19.6427 34.0004 19.8161 34.0004 20.0034 34.0004V33.9994C20.7622 34.0004 21.5199 33.9406 22.2692 33.8206C22.5517 28.8449 21.3284 24.4203 19.0744 22.2722C16.248 24.4591 12.777 25.6487 9.20332 25.6554C8.50864 25.656 7.81479 25.6074 7.12699 25.5098C7.38637 26.1144 7.68748 26.7002 8.02816 27.263H8.07418C12.3435 27.263 16.3035 26.1477 18.6581 24.2779C19.8864 27.3632 20.1702 30.7439 19.4736 33.9908Z"
                    fill="white"
                />
                <path
                    d="M14.545 32.9002C15.2043 33.1791 15.8842 33.4067 16.5786 33.5809V33.5798C17.9309 31.1673 18.4819 28.3875 18.1518 25.6415C15.1568 27.1535 11.855 27.9589 8.50015 27.9961C8.91135 28.5847 9.36682 29.1411 9.86261 29.6604C12.3258 29.4668 14.7198 28.7517 16.8857 27.5627C16.2962 29.4408 15.3382 31.1826 14.0677 32.6861C14.2154 32.7568 14.3717 32.8263 14.545 32.9002Z"
                    fill="white"
                />
                <path
                    d="M19.2082 20.7663C22.7132 23.8934 24.9939 28.1636 25.6437 32.8156H25.648C26.4767 32.4508 27.2678 32.006 28.0101 31.4874C27.1234 28.6834 25.7933 26.0395 24.0704 23.6561C22.743 21.6733 20.9322 20.0614 18.809 18.9725C17.7859 20.4163 16.4363 21.598 14.8702 22.4216C13.304 23.2451 11.5656 23.6872 9.79625 23.7118C8.63715 23.7044 7.48463 23.537 6.37138 23.2141C6.49885 23.7561 6.6586 24.2901 6.8498 24.8131C7.66624 24.9649 8.49489 25.0416 9.32533 25.0421C11.6381 25.0304 13.9141 24.4621 15.961 23.3853C17.2448 22.7967 18.361 21.8964 19.2082 20.7663Z"
                    fill="white"
                />
                <path
                    d="M18.5778 17.4838C23.1051 18.9565 27.9545 23.3789 30.9319 28.7485L30.9426 28.7518C31.5376 28.0093 32.0555 27.2081 32.4881 26.3607C28.9222 21.9426 24.3392 18.4539 19.1311 16.193C19.1365 16.1919 19.7262 16.1384 20.6659 16.1384C22.72 16.1372 24.7673 16.375 26.7665 16.8469C29.4264 17.4272 31.9078 18.6386 34.0015 20.3789C34.0068 20.2526 34.0068 20.1242 34.0068 19.9968C34.0106 18.1243 33.6364 16.2703 32.9066 14.5458C31.647 11.5711 29.398 9.12321 26.5404 7.61666C23.6829 6.1101 20.3925 5.63751 17.2264 6.27891C14.0604 6.92032 11.2134 8.63629 9.16746 11.1362C7.12156 13.6362 6.00257 16.7664 6 19.9968C5.9999 20.7992 6.06793 21.6001 6.20335 22.391C7.29205 22.7147 8.42179 22.8794 9.55758 22.8801C11.5955 22.8884 13.5973 22.3424 15.3488 21.3004C16.8491 20.4579 17.9955 19.1029 18.5778 17.4838Z"
                    fill="white"
                />
                <path
                    d="M20 40C8.97504 40 0 31.0298 0 20C0 8.9702 8.9702 0 20 0C31.0298 0 40 8.9702 40 20C40 31.0298 31.0298 40 20 40ZM20 4.85098C11.6453 4.85098 4.84614 11.6501 4.84614 20.0048C4.84614 28.3596 11.6453 35.1587 20 35.1587C28.3547 35.1587 35.1539 28.3596 35.1539 20.0048C35.1539 11.6501 28.3547 4.85098 20 4.85098Z"
                    fill="white"
                />
            </g>
        </svg>
    );
}
