import SafeswimLocation, { Forecast, Hazard } from "../types/SafeswimLocation";
import { get, post } from "./instance";
import WeatherForecast from "../types/WeatherForecast";
import ListResult from "../types/ListResult";
import Patrol from "../types/Patrol";

export async function getLocation(id: number): Promise<SafeswimLocation> {
    return await get<SafeswimLocation>(`/v1/locations/${id}`);
}

export async function getLocationForecast(id: number): Promise<Forecast> {
    return await get<Forecast>(`/v1/location/${id}/forecast`);
}

export async function getWeatherForecast(id: number): Promise<WeatherForecast> {
    return await get<WeatherForecast>(`/v1/accuweather/forecast/${id}`);
}

export async function getLocationHazards(id: number): Promise<Hazard[]> {
    return await get<Hazard[]>(`/v1/locations/${id}/hazards`);
}

export async function getMapLocations(
    lat?: number,
    lng?: number
): Promise<SafeswimLocation[]> {
    return await get<SafeswimLocation[]>("/v1/map/locations", {
        lat,
        lng,
        distance: 100000
    });
}

export async function getAllMapLocations(): Promise<SafeswimLocation[]> {
    return await get<SafeswimLocation[]>("/v1/map/locations");
}

export async function searchLocations(
    name: string
): Promise<SafeswimLocation[]> {
    return await get<SafeswimLocation[]>("/v1/map/locations/search", { name });
}

export async function signGoogleMapsRequest(
    path: string
): Promise<{ url: string }> {
    return await post("/v2/maps/static", path);
}

export async function getPatrolsByLocation(
    locationId: number,
    limit?: number,
    cursor?: string
): Promise<ListResult<Patrol>> {
    return await get(`v1/locations/${locationId}/patrols`, { limit, cursor });
}

export async function getAllLocationsPatrolsToday(): Promise<Array<Patrol>> {
    return await get(`v1/locations/patrols`);
}
