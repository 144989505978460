import classNames from "classnames";
import { addDays, format, isAfter, parseISO } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useEffect, useMemo } from "react";
import { getPatrolsByLocation } from "../../../api/locations";
import { useApiContext } from "../../../context/api";
import { DrawerActionType, useDrawerContext } from "../../../context/drawer";
import Calendar from "../../../resources/images/random/calendar.svg";
import SLS from "../../../resources/images/random/sls_flag_blue.svg";
import SLS_opaque from "../../../resources/images/random/sls_flag_blue_opaque.svg";
import SafeswimLocation from "../../../types/SafeswimLocation";
import { containsTag } from "../../../utils/location";
import Button from "../../buttons/button/Button";
import ChevronRight from "../../images/chevrons/ChevronRight";
import styles from "./PatrolCard.module.scss";

type Props = {
    location: SafeswimLocation;
    onClickCalendar?: () => void;
};

export default function PatrolCard(props: Props) {
    const { location, onClickCalendar } = props;
    const { setPatrols, patrols } = useApiContext();
    const { show } = useDrawerContext();

    const isPatrolled = containsTag(location, "sls_patrolled");

    useEffect(() => {
        (async () => {
            if (isPatrolled) {
                const { items } = await getPatrolsByLocation(location.id, 365);
                setPatrols(items);
            }
        })();
    }, []);

    const locPatrols = useMemo(() => {
        if (!isPatrolled) {
            return undefined;
        }
        const lpatrols = patrols[location.id] ?? {};
        const today = utcToZonedTime(new Date(), "Pacific/Auckland");
        const _locPatrols: { dstr: string; pstr: string }[] = [];
        let patrolledNow = false;
        for (let i = 0; i < 4; i++) {
            const day = addDays(today, i);
            const dstr =
                i === 0
                    ? `Today, ${format(day, "EEE d MMM")}`
                    : format(day, "EEE, d MMM");
            const date = format(day, "yyyy-MM-dd");
            const patrolled = lpatrols[date];
            if (patrolled) {
                const { from, to, flagOn } = patrolled;
                const dfrom = zonedTimeToUtc(
                    parseISO(`${date}T${from}`),
                    "Pacific/Auckland"
                );
                const dto = zonedTimeToUtc(
                    parseISO(`${date}T${to}`),
                    "Pacific/Auckland"
                );
                const pstr = `${i === 0 ? "On-duty," : ""} ${format(
                    utcToZonedTime(dfrom, "Pacific/Auckland"),
                    "h:mmaaa"
                )} - ${format(
                    utcToZonedTime(dto, "Pacific/Auckland"),
                    "h:mmaaa"
                )}`;
                _locPatrols.push({ dstr, pstr });
                if (i === 0) {
                    const now = Date.now();
                    //no later than patrol hours and flag is on
                    patrolledNow = !isAfter(now, dto) && !!flagOn;
                }
            } else {
                const pstr = "Not lifeguarded";
                _locPatrols.push({ dstr, pstr });
            }
        }
        return { _locPatrols, patrolledNow };
    }, [Object.values(patrols[location.id] ?? {}).length, location]);

    const isPatrolledNow = locPatrols?.patrolledNow;

    const lifeguardStatus = isPatrolledNow
        ? "Lifeguards on duty"
        : "Lifeguards not on duty";
    const title = isPatrolled ? lifeguardStatus : "Beach not lifeguarded";

    if (
        containsTag(location, "fresh_water_hazard") ||
        containsTag(location, "fresh_water")
    ) {
        return null;
    }

    const offSeason = !Object.values(patrols[location.id] ?? {}).length;

    const Header = () => {
        if (!isPatrolled) {
            return (
                <div className={styles.not_lifeguarded}>
                    <h4 className={styles.subtitle}>{title}</h4>
                </div>
            );
        }

        if (offSeason) {
            return (
                <>
                    <img src={SLS_opaque} alt={""} />
                    <div className={styles.title}>
                        <div className={styles.strapline_header}>
                            Surf life saving
                        </div>
                        <h4 className={styles.subtitle}>
                            Lifeguards not on duty
                        </h4>
                    </div>
                </>
            );
        }

        return (
            <>
                <img src={isPatrolledNow ? SLS : SLS_opaque} alt={""} />
                <div className={styles.title}>
                    <div className={styles.strapline}>Surf life saving</div>
                    <h4 className={styles.subtitle}>{title}</h4>
                </div>
            </>
        );
    };

    const Description = () => {
        if (!isPatrolled || offSeason) {
            return (
                <div
                    className={classNames(
                        styles.not_lifeguarded_message,
                        styles.highlight_block
                    )}
                >
                    <span className={styles.strapline}>
                        If someone in the water is in trouble:
                    </span>
                    <div>
                        <a href="tel:111" className={styles.highlight}>
                            Dial 111
                        </a>{" "}
                        and ask for the{" "}
                        <span className={styles.highlight}>Police</span>.
                    </div>
                </div>
            );
        }

        if (isPatrolledNow) {
            return <div>Always swim between the flags.</div>;
        }

        return (
            <div className={styles.highlight_block}>
                We recommend swimming at this beach when lifeguards are on duty.
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Header />
            </div>
            <div className={styles.description}>
                <Description />
            </div>
            {locPatrols && !offSeason && (
                <div className={styles.schedule_table}>
                    {locPatrols?._locPatrols.map(({ dstr, pstr }, i) => (
                        <div
                            key={`${dstr}_${pstr}`}
                            className={classNames(styles.row, {
                                [styles.today]: i === 0
                            })}
                        >
                            <span>{dstr}</span>
                            <span>{pstr}</span>
                        </div>
                    ))}
                </div>
            )}
            <div className={styles.button_group}>
                {isPatrolled && !offSeason && (
                    <Button
                        variant={"filled"}
                        color="darker"
                        icon={Calendar}
                        iconPosition="left"
                        // TODO
                        // iconRight={<ChevronRight />}
                        onClick={onClickCalendar}
                    >
                        Calendar
                    </Button>
                )}
                <Button
                    variant={isPatrolled && !offSeason ? "link" : "filled"}
                    color="darker"
                    icon={<ChevronRight />}
                    onClick={() =>
                        show(DrawerActionType.WATER_SAFETY_101_DRAWER)
                    }
                >
                    Beach safety
                </Button>
            </div>
        </div>
    );
}
