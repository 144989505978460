import styles from "./FAQDrawer.module.scss";
import { DrawerActionType, useDrawerContext } from "../../../../context/drawer";
import Drawer from "../../Drawer";
import { HomeButton } from "../../../buttons/drawerButton/DrawerButton";
import { useNavigate } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import pinGreen from "../../../../resources/images/mapkey/green.svg";
import pinRed from "../../../../resources/images/mapkey/red.svg";
import pinBlack from "../../../../resources/images/mapkey/black.svg";
import pinGrey from "../../../../resources/images/mapkey/grey.svg";
import pinLongTerm from "../../../../resources/images/mapkey/permanent.svg";
import pinLogo from "../../../../resources/images/mapkey/branded.svg";
import pinPatrol from "../../../../resources/images/mapkey/sls.svg";
import pinPatrolDanger from "../../../../resources/images/mapkey/red_flag.svg";
import pinHazard from "../../../../resources/images/mapkey/hazard.svg";
import pinInfo from "../../../../resources/images/mapkey/information.svg";
import ChevronRight from "../../../images/chevrons/ChevronRight";

const Title = ({ children }: any) => (
    <div className={styles.title}>{children}</div>
);

export default function FAQDrawer() {
    const { show, hideAll, hideAllRightHandDrawers, faqDrawerOpen } =
        useDrawerContext();
    const navigate = useNavigate();

    const onContactClick = () => {
        hideAllRightHandDrawers();
        show(DrawerActionType.CONTACT_DRAWER);
        navigate("/");
    };

    const closeFAQDrawer = () => hideAll();

    const AccordionItemWrapper = ({ title, index, children }: any) => (
        <AccordionItem className={styles.accordion_item} uuid={index}>
            <AccordionItemHeading aria-level={6}>
                <AccordionItemButton className={styles.accordion_button}>
                    <Title>{title}</Title>
                    <ChevronRight />
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion_panel}>
                {children}
            </AccordionItemPanel>
        </AccordionItem>
    );

    return (
        <Drawer
            id={DrawerActionType.FAQ_DRAWER}
            isOpen={!!faqDrawerOpen}
            position={"right"}
        >
            <div className={styles.container}>
                <HomeButton onClick={closeFAQDrawer} />
                <h2>FAQ</h2>
                <h5 className={styles.accordion_header}>Water Quality</h5>

                <Accordion
                    className={styles.accordion}
                    allowZeroExpanded
                    allowMultipleExpanded
                >
                    <AccordionItemWrapper
                        index={1}
                        title={"What do the symbols on Safeswim mean?"}
                    >
                        <p>
                            Most regional councils around New Zealand recommend
                            that people shouldn’t swim for at least 48 hours –
                            and sometimes up to five days – after heavy rain.
                            But not all beaches are the same. Depending on the
                            level of contamination and physical conditions -
                            tides, winds, currents, sunlight – water quality at
                            some beaches may return to normal faster than that
                            and some beaches may take longer.{" "}
                        </p>
                        <p>
                            Safeswim provides a much more accurate advice
                            tailored to the specific conditions of each location
                            in the programme.
                        </p>
                        <p>
                            The Safeswim website uses colour-coded pins to alert
                            users of health and safety risks in relation to
                            current and predicted swimming conditions.
                        </p>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={"faq_pin_table"}>
                                        <img
                                            className="faq_pin"
                                            src={pinGreen}
                                            alt="Green water quality pin"
                                        />
                                    </td>
                                    <td className={"faq_pin_table"}>
                                        <p>
                                            Water quality predicted to meet
                                            guidelines – low risk of illness
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"faq_pin_table"}>
                                        <img
                                            className="faq_pin"
                                            src={pinRed}
                                            alt="Red water quality pin"
                                        />
                                    </td>
                                    <td className={"faq_pin_table"}>
                                        <p>
                                            Water quality predicted to exceed
                                            guidelines – high risk of illness
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"faq_pin_table"}>
                                        <img
                                            className="faq_pin"
                                            src={pinBlack}
                                            alt="Black water quality pin"
                                        />
                                    </td>
                                    <td className={"faq_pin_table"}>
                                        <p>
                                            Wastewater overflow detected nearby
                                            – very high risk of illness
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"faq_pin_table"}>
                                        <img
                                            className="faq_pin"
                                            src={pinGrey}
                                            alt="Grey water quality pin"
                                        />
                                    </td>
                                    <td className={"faq_pin_table"}>
                                        <p>Data temporarily unavailable</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"faq_pin_table"}>
                                        <img
                                            className="faq_pin"
                                            src={pinLongTerm}
                                            alt="Long term water quality pin"
                                        />
                                    </td>
                                    <td className={"faq_pin_table"}>
                                        <p>
                                            Water quality consistently poor,
                                            swimming not advised
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"faq_pin_table"}>
                                        <img
                                            className="faq_pin"
                                            src={pinLogo}
                                            alt="Logo pin"
                                        />
                                    </td>
                                    <td className={"faq_pin_table"}>
                                        <p>
                                            Water safety information only
                                            (real-time water quality not
                                            available)
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"faq_pin_table"}>
                                        <img
                                            className="faq_pin"
                                            src={pinPatrol}
                                            alt="Red and yellow patrol flag pin"
                                        />
                                    </td>
                                    <td className={"faq_pin_table"}>
                                        <p>
                                            Beach is currently patrolled by surf
                                            lifeguards
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"faq_pin_table"}>
                                        <img
                                            className="faq_pin"
                                            src={pinPatrolDanger}
                                            alt="Red patrol flag pin"
                                        />
                                    </td>
                                    <td className={"faq_pin_table"}>
                                        <p>
                                            Lifeguard warning - ‘Dangerous
                                            conditions’
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"faq_pin_table"}>
                                        <img
                                            className="faq_pin"
                                            src={pinHazard}
                                            alt="Hazard pin"
                                        />
                                    </td>
                                    <td className={"faq_pin_table"}>
                                        <p>Hazard warning</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"faq_pin_table"}>
                                        <img
                                            className="faq_pin"
                                            src={pinInfo}
                                            alt="Information pin"
                                        />
                                    </td>
                                    <td className={"faq_pin_table"}>
                                        <p>
                                            Take note (used, for instance, when
                                            rāhui are in place)
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </AccordionItemWrapper>
                    <AccordionItemWrapper
                        index={2}
                        title={
                            "What is the risk of swimming when a red water droplet is showing?"
                        }
                    >
                        <p>
                            The health risk posed by faecal contamination in
                            recreational waters is traditionally assessed by
                            monitoring Faecal Indicator Bacteria (FIB). The
                            presence of FIBs, which can come from humans, birds,
                            dogs, cows, and other mammals, indicates the
                            presence of pathogens that can make you sick.{" "}
                        </p>
                        <p>
                            New Zealand’s public health guidelines require
                            councils to notify the public when levels of FIBs
                            indicate that more than 1 in 50 people are likely to
                            become ill after putting their head underwater.
                        </p>
                        <p>
                            But contaminants don’t necessarily mix evenly in the
                            water. If you are unlucky you may swim through a
                            patch of water where contaminants are more
                            concentrated and the risk of falling ill may be much
                            higher than 1 in 50.
                        </p>
                    </AccordionItemWrapper>
                    <AccordionItemWrapper
                        index={3}
                        title={
                            "What causes poor water quality at beaches and what is being done about it?"
                        }
                    >
                        <p>
                            Water quality at New Zealand’s beaches is generally
                            good and is continuing to improve as councils and
                            water utilities work together to investigate and fix
                            problems with the wastewater and stormwater
                            networks. However, our beaches, lagoons and rivers
                            do suffer from poor water quality from time to time.
                        </p>
                        <p>
                            In general, there is a higher risk of poor water
                            quality at beaches:
                        </p>
                        <ul>
                            <li>after rain, especially after heavy rain,</li>
                            <li>
                                in or near stormwater outlets and urban streams
                                feeding onto beaches,
                            </li>
                            <li>
                                in areas serviced by ageing network
                                infrastructure in the city centre,
                            </li>
                            <li>
                                in areas that have experienced significant
                                residential growth in the past few decades
                                putting existing infrastructure under stress,
                                and
                            </li>
                            <li>
                                in areas serviced by ageing onsite septic
                                systems on the edge of cities or in rural areas
                                where development has exceeded the capacity of
                                those systems.
                            </li>
                        </ul>
                        <p>
                            Rainfall after long spells of dry weather can also
                            carry a ‘first flush’ of dirty water through the
                            stormwater network onto beaches and into urban
                            waterways – including water contaminated with animal
                            faeces, oil, metals and rubber from tyres,
                            microplastics and litter.{" "}
                        </p>
                        <p>
                            For more information on the causes of poor water
                            quality, find out what is being done to ensure that
                            your beaches are clean and healthy visit your local
                            regional council website.
                        </p>
                    </AccordionItemWrapper>
                    <AccordionItemWrapper
                        index={4}
                        title={
                            "Why is my beach pin red and the neighbouring beach pin is green?"
                        }
                    >
                        <p>
                            Safeswim’s water quality information is calibrated
                            to each individual beach. Beaches next to each other
                            may show a different status because water quality is
                            influenced by factors specific to each beach. This
                            includes tide, wind and wave conditions, the flow of
                            currents and location of channels, and the nature of
                            discharges to the beach. The source of these
                            discharges could be stormwater or wastewater
                            networks, or streams. Local discharges can vary a
                            great deal depending on the nature of land use in
                            the area feeding onto the beach (the ‘catchment’),
                            and the age and state of infrastructure.
                        </p>
                    </AccordionItemWrapper>
                    <AccordionItemWrapper
                        index={5}
                        title={
                            "What is Safeswim’s water quality advice based on?"
                        }
                    >
                        <p>
                            Safeswim’s water quality predictions consider rain
                            intensity, duration and location, as well as tide,
                            wind speed and direction and sunlight. In addition,
                            data from rain gauges are fed into the system to
                            ensure current water quality predictions reflect
                            observed rainfall.{" "}
                        </p>
                        <p>
                            Safeswim’s water quality predictions have been built
                            based on a long term programme of water quality
                            sampling. The sampling programme comprises:
                        </p>
                        <ul>
                            <li>
                                the record of all samples taken at each location
                                in the programme – usually a minimum of three
                                years of samples and going back over 20 years in
                                some locations
                            </li>
                            <li>
                                ongoing routine sampling at each site in the
                                programme (excluding those subject to long-term
                                warnings, which are managed under a different
                                system)
                            </li>
                            <li>
                                high-frequency sampling at targeted locations
                                during heavy, moderate, and light rainfall
                            </li>
                            <li>community-led sampling.</li>
                        </ul>
                        <p>
                            Monitoring continues to play a central role in the
                            Safeswim programme. After switching to a ‘sampling
                            and modelling’ approach in the 2017/18 summer,
                            Auckland Council has for instance, collected over
                            2,000 water quality samples per year at up to 244
                            sites around the Auckland region. The previous
                            ‘monitoring only’ programme collected approximately
                            1,500 samples per year at 68 sites.
                        </p>
                        <p>
                            Safeswim’s sampling programme has confirmed that
                            contamination behaves differently at beaches
                            depending on whether:
                        </p>
                        <ul>
                            <li>
                                it hits the water during an incoming or outgoing
                                tide – pushing the contaminants against the
                                beach or out to sea.
                            </li>
                            <li>
                                the wind is offshore or onshore – offshore winds
                                can blow contaminants out to sea and onshore
                                waves can keep contaminants close to the shore
                                or blow them along the beach. High winds can
                                also cause choppy waves which can ‘churn-up’ the
                                seafloor and ‘re-suspend’ contaminants.
                            </li>
                            <li>
                                there is sunshine, which kills many pathogens.
                            </li>
                        </ul>
                        <p>
                            Safeswim’s water quality predictions are
                            complemented by sensors at pump stations, engineered
                            overflow points on the wastewater network and at key
                            points on the stormwater network designed to detect
                            overflows. This is important because models can’t
                            predict everything that can cause poor water quality
                            i.e. faults or breakages in the wastewater network
                            causing overflow in dry weather conditions.{" "}
                        </p>
                        <p>
                            Safeswim also has a manual ‘back-up’ that allows
                            Councils/ Pollution Response Teams and water network
                            operational contractors to upload manual alerts if
                            sewage overflows or contamination events are spotted
                            by operational teams or by members of the public.{" "}
                        </p>
                    </AccordionItemWrapper>
                    <AccordionItemWrapper
                        index={6}
                        title={"How accurate are Safeswim’s models?"}
                    >
                        <p>
                            Each location in Safeswim has its own site-specific
                            model. Independent audits of Safeswim completed by
                            Audit New Zealand in 2020 and 2021 found that a
                            random sample of Safeswim’s water quality
                            predictions in Auckland were 89% accurate and
                            concluded that no changes to the system were
                            required.
                        </p>
                        <p>
                            All Safeswim’s water quality models are overseen by
                            an independent panel of public health experts. The
                            Safeswim expert panel consists of Professor Gillian
                            Lewis (University of Auckland), Professor Colin
                            Fricker (independent consultant), Graham McBride
                            (NIWA) and Dr David Sinclair (Medical Officer of
                            Health, Auckland Regional Public Health Service).{" "}
                        </p>
                        <p>
                            Safeswim has set the following performance
                            benchmarks for its water quality models:
                        </p>
                        <ul>
                            <li>overall accuracy</li>
                            <li>
                                ability to accurately predict exceedances in
                                water quality for national guidelines for water
                                quality, also referred to as sensitivity
                            </li>
                            <li>
                                ability to accurately predict compliances in
                                national guidelines for water quality, also
                                referred to as specificity.
                            </li>
                        </ul>
                        <p>
                            These are standards adopted from the United States
                            Geological Survey (USGS) for the type of models that
                            are employed in the Safeswim programme. The USGS
                            requires 80% overall accuracy, 50% sensitivity and
                            80% specificity.
                        </p>
                    </AccordionItemWrapper>
                    <AccordionItemWrapper
                        index={7}
                        title={"What should I do if I notice pollution?"}
                    >
                        <h4>Auckland</h4>
                        <ul>
                            <li>
                                If you notice pollution being discharged into
                                streams or onto beaches call the Auckland
                                Council Pollution hotline seven days a week on
                                09 377-3107
                            </li>
                            <li>
                                If you notice an oil spill to the ocean, call
                                the Harbourmaster on 09 362-0397 (press 1).
                            </li>
                            <li>
                                If you suspect an overflow from the wastewater
                                network in Auckland contact Watercare Services
                                24 hours a day, 7 days a week on 09 442-2222
                                (press 1), or{" "}
                                <a
                                    href={
                                        "https://www.watercare.co.nz/faults-outages/report-a-fault"
                                    }
                                >
                                    report a fault online
                                </a>
                                .
                            </li>
                        </ul>

                        <h4>Northland</h4>
                        <ul>
                            <li>
                                Call the 24/7 Environmental Hotline 0800 504 639
                                to report all pollution and environmental
                                incidents, including oil or chemical spills,
                                water pollution, dangerous boating, and
                                navigation hazards and breakdowns of navigation
                                lights.
                            </li>
                        </ul>

                        <h4>Other regions</h4>
                        <ul></ul>
                        <p>Contact your regional council.</p>
                        <p>
                            Or contact the Safeswim team directly by clicking{" "}
                            <span
                                className="link-button"
                                onClick={onContactClick}
                            >
                                here
                            </span>
                            . We protect your privacy. We will keep your details
                            confidential and will not pass them on to anyone.
                        </p>
                    </AccordionItemWrapper>
                </Accordion>

                <h5 className={styles.accordion_header}>Swimming conditions</h5>
                <Accordion className={styles.accordion}>
                    <AccordionItemWrapper
                        index={1}
                        title="How should I stay safe at the beach?"
                    >
                        <div
                            onClick={() =>
                                show(
                                    DrawerActionType.WATER_SAFETY_101_DRAWER_RIGHT
                                )
                            }
                            className={styles.link}
                        >
                            Beach safety page
                        </div>
                    </AccordionItemWrapper>
                    <AccordionItemWrapper
                        index={2}
                        title={
                            "What do I need to know about swimming in cold water?"
                        }
                    >
                        <p>
                            When water is below 15°C there is a risk of
                            cold-shock. Cold shock can cause a gasp response,
                            make it hard for you to hold your breath or cause
                            you to hyperventilate. The gasp response may cause
                            you to swallow water and could be dangerous. It is
                            safest to enter very cold water slowly.
                        </p>
                        <p>
                            After about 10 minutes in cold water you may find it
                            harder to float or swim and there may be a risk of
                            hypothermia.
                        </p>
                    </AccordionItemWrapper>
                    <AccordionItemWrapper
                        index={3}
                        title={
                            "What do I need to know about swimming in rivers and waterfalls?"
                        }
                    >
                        <p>
                            Fresh water in rivers and aerated water (full of air
                            bubbles) around the base of waterfalls, is less
                            dense and therefore less buoyant. It is easier to
                            sink and more difficult to float in these types of
                            water.{" "}
                        </p>
                        <p>
                            After heavy rain the water flow or current in
                            creeks, rivers, and waterfalls becomes stronger,
                            making it more dangerous to wade or swim.{" "}
                        </p>
                        <p>
                            Rocks at the edge of rivers and waterfalls can
                            become very slippery when wet. Slipping and falling
                            could cause serious injury.
                        </p>
                        <p>
                            Always check the depth of the water before jumping
                            into a river or waterhole, even if you visit the
                            same spot regularly. Depths can vary and new
                            underwater obstructions (sand, rocks, branches,
                            rubbish) may have been brought downstream or been
                            tipped in. Hitting an object submerged under the
                            water could cause serious injury.
                        </p>
                        <p>
                            Do not jump into the landing area of a large
                            waterfall, the undercurrents directly below a large
                            waterfall or weir could hold you under and you may
                            not surface.{" "}
                        </p>
                    </AccordionItemWrapper>
                    <AccordionItemWrapper
                        index={4}
                        title={
                            "Why can’t I supervise my child from the side of a waterhole?"
                        }
                    >
                        <p>
                            Slippery rocks and sudden changes in depth mean your
                            child could disappear under water quickly. Always
                            supervise within arms’ reach.
                        </p>
                    </AccordionItemWrapper>
                    <AccordionItemWrapper
                        index={5}
                        title={"What should I do if I spot someone in trouble?"}
                    >
                        <p>
                            If you spot someone in trouble at an unpatrolled
                            beach, ensure your own safety, and ring 111 and ask
                            for the police.
                        </p>
                    </AccordionItemWrapper>
                </Accordion>

                <h5 className={styles.accordion_header}>Public Health</h5>
                <Accordion className={styles.accordion}>
                    <AccordionItemWrapper
                        index={1}
                        title={
                            "What should I do if I become sick after swimming?"
                        }
                    >
                        <p>
                            If you have become sick, especially within 3 days of
                            swimming, you may have contracted a water borne
                            illness. Symptoms could be respiratory,
                            gastroenteritis and/or ear, eye and skin infections.
                            If you have symptoms and are concerned, please visit
                            your doctor or call Healthline for advice on
                            0800-611-116. People who are immune-compromised
                            should follow the advice of their doctor before
                            swimming.
                        </p>
                        <p>
                            Marine water can contain life forms that may be
                            harmful to your health such as jellyfish, algal
                            blooms, toxic sea slugs and contaminated shellfish.
                        </p>
                        <p>Follow these links for more information on: </p>
                        <ul>
                            <li>
                                <a
                                    href={
                                        "https://dermnetnz.org/topics/sea-bathers-eruption/"
                                    }
                                    rel={"noopener noreferrer"}
                                >
                                    Bather’s eruption
                                </a>{" "}
                                or
                                <a
                                    href={
                                        "https://www.arphs.health.nz/public-health-topics/healthy-environments/jellyfish-stings-and-swimmers-rash/"
                                    }
                                    rel={"noopener noreferrer"}
                                >
                                    {" "}
                                    jellyfish stings
                                </a>
                            </li>
                            <li>
                                <a
                                    href={"Shellfish poisoning"}
                                    rel={"noopener noreferrer"}
                                >
                                    Shellfish poisoning
                                </a>
                            </li>
                            <li>
                                <a
                                    href={
                                        "https://www.mpi.govt.nz/fishing-aquaculture/recreational-fishing/where-unsafe-to-collect-shellfish/what-toxic-algal-blooms/"
                                    }
                                    rel={"noopener noreferrer"}
                                >
                                    Algal blooms
                                </a>
                            </li>
                            <li>
                                <a
                                    href={
                                        "https://niwa.co.nz/coasts-and-oceans/marine-identification-guides-and-fact-sheets/sea-slugs"
                                    }
                                    rel={"noopener noreferrer"}
                                >
                                    Toxic sea slugs
                                </a>
                            </li>
                        </ul>
                    </AccordionItemWrapper>
                    <AccordionItemWrapper
                        index={2}
                        title={"Should I gather shellfish from urban beaches?"}
                    >
                        <p>
                            We advise against gathering shellfish for
                            consumption from beaches and harbours next to urban
                            areas. You could get sick if you eat shellfish
                            gathered from these areas because there is a high
                            risk of contamination from road runoff, industrial
                            discharges, illegally dumped rubbish, and human and
                            animal waste. For more information{" "}
                            <a
                                href={
                                    "https://www.arphs.health.nz/public-health-topics/healthy-environments/shellfish/"
                                }
                                rel={"noopener noreferrer"}
                            >
                                click here
                            </a>
                            .
                        </p>
                    </AccordionItemWrapper>
                </Accordion>
            </div>
        </Drawer>
    );
}
