import {
    PropsWithChildren,
    TouchEvent,
    useEffect,
    useRef,
    useState
} from "react";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import SafeswimLocation, {
    Forecast as ForecastType
} from "../../../types/SafeswimLocation";
import { DrawerActionType, useDrawerContext } from "../../../context/drawer";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import WeatherForecast from "../../../types/WeatherForecast";
import LocationDetails from "./components/LocationDetails";
import Drawer from "../Drawer";
import Header from "./components/header/Header";
import {
    containsTag,
    getCurrentWaterQualityLevel,
    isCurrentlyPatrolled,
    WaterQualityLevel
} from "../../../utils/location";
import styles from "./LocationDrawer.module.scss";
import classNames from "classnames";
import WaterQualityCard from "../../cards/waterQualityCard/WaterQualityCard";
import LocationLoading from "../../loading/LocationLoading";
import WaterSafetyAlertCard from "../../cards/waterSafetyAlertCard/WaterSafetyAlertCard";
import PatrolCard from "../../cards/patrolCard/PatrolCard";
import TideCard from "../../cards/tideCard/TideCard";
import WeatherCard from "../../cards/weatherCard/WeatherCard";
import { BackButtonDrawer } from "../BackButtonDrawer/BackButtonDrawer";
import { useMobileState } from "../../../hooks/useMobileState";

type Props = {
    location?: SafeswimLocation;
    weatherForecast?: WeatherForecast;
    forecast?: ForecastType;
};

export default function LocationDrawer(props: Props) {
    const { location, forecast, weatherForecast } = props;
    const [loading, setLoading] = useState<"loading" | "loaded" | "closing">(
        "closing"
    );
    const [isSigningUp, setIsSigningUp] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const { locationDrawerOpen, hide, show } = useDrawerContext();
    const { isMobile } = useMobileState();

    useEffect(() => {
        if (location) {
            setSelectedTab(0);
            setLoading("loading");
            hide(DrawerActionType.WATER_SAFETY_DRAWER);
            hide(DrawerActionType.WATER_QUALITY_DRAWER);
            hide(DrawerActionType.HAZARD_DRAWER);
        }
    }, [location]);

    const toggleTab = (index: number) => {
        setSelectedTab(index);
    };

    const orderCards = () => {
        if (!location) {
            return [];
        }

        const waterQualityLevel = getCurrentWaterQualityLevel(location);
        const slsPatrolled = containsTag(location, "sls_patrolled");
        const currentlyPatrolled = isCurrentlyPatrolled(location);
        const hasHazardAlerts =
            location.alerts && location.alerts.hazards.length > 0;
        const freshWaterHazard = containsTag(location, "fresh_water_hazard");

        const cards = [
            <WaterQualityCard
                key={"water-quality-card"}
                location={location}
                forecast={forecast}
                onLearnMoreClick={() =>
                    show(DrawerActionType.WATER_QUALITY_DRAWER)
                }
            />
        ];

        if (hasHazardAlerts || freshWaterHazard) {
            if (
                waterQualityLevel === WaterQualityLevel.GOOD ||
                waterQualityLevel === WaterQualityLevel.UNAVAILABLE
            ) {
                cards.unshift(
                    <WaterSafetyAlertCard
                        location={location}
                        key={"water-safety-card"}
                    />
                );
            } else {
                cards.push(
                    <WaterSafetyAlertCard
                        location={location}
                        key={"water-safety-card"}
                    />
                );
            }
        }

        if (slsPatrolled) {
            if (
                currentlyPatrolled &&
                (waterQualityLevel === WaterQualityLevel.GOOD ||
                    waterQualityLevel === WaterQualityLevel.UNAVAILABLE)
            ) {
                if (hasHazardAlerts) {
                    cards.splice(
                        1,
                        0,
                        <PatrolCard
                            key={"patrol-card"}
                            location={location}
                            onClickCalendar={() =>
                                show(DrawerActionType.SLS_DRAWER)
                            }
                        />
                    );
                } else {
                    cards.unshift(
                        <PatrolCard
                            key={"patrol-card"}
                            location={location}
                            onClickCalendar={() =>
                                show(DrawerActionType.SLS_DRAWER)
                            }
                        />
                    );
                }
            } else {
                cards.push(
                    <PatrolCard
                        key={"patrol-card"}
                        location={location}
                        onClickCalendar={() =>
                            show(DrawerActionType.SLS_DRAWER)
                        }
                    />
                );
            }
        }

        cards.push(
            <WeatherCard
                key={"weather-card"}
                location={location}
                forecast={forecast}
                weatherForecast={weatherForecast}
            />,
            <TideCard
                key={"tide-card"}
                forecast={forecast}
                location={location}
            />
        );

        if (!slsPatrolled) {
            cards.push(
                <PatrolCard
                    key={"patrol-card"}
                    location={location}
                    onClickCalendar={() => show(DrawerActionType.SLS_DRAWER)}
                />
            );
        }

        return cards;
    };

    const isLoading = loading || !location || !forecast;
    const freshWaterHazard = containsTag(location, "fresh_water_hazard");
    const freshWater = containsTag(location, "fresh_water");

    function Pullable(
        props: PropsWithChildren<{
            locationName: SafeswimLocation["name"];
        }>
    ) {
        const { children } = props;
        const { isMobile } = useMobileState();
        const startY = useRef<number>(0);
        const endY = useRef<number>(0);
        const onStart = (event: TouchEvent<HTMLDivElement>) => {
            if (!isMobile) {
                return;
            }
            startY.current = event.touches[0].clientY;
        };

        const onEnd = () => {
            if (!isMobile) {
                return;
            }
            if (startY.current === 0 || endY.current === 0) {
                //ignore if init values not touched
                return;
            }
            const d = startY.current - endY.current;
            if (d > 0) {
                return;
            }
            if (d < 0) {
                setLoading("closing");
                hide(DrawerActionType.LOCATION_DRAWER);
            }

            document.documentElement.style.overflow = "";
            document.body.style.overflow = "";
            startY.current = 0;
            endY.current = 0;
        };

        const handleTouch = (event: TouchEvent<HTMLDivElement>) => {
            if (!isMobile) {
                return;
            }
            const current = event.touches[0]?.clientY;

            if (current) {
                endY.current = current;
            }
            if (startY.current - current < 0) {
                //avoid refresh page on safari“
                document.documentElement.style.overflow = "hidden";
                document.body.style.overflow = "hidden";
            }
        };

        // if (!isMobile) {
        //     return <div>{children}</div>;
        // }

        return (
            <div
                className={classNames({ [styles.pullable_header]: isMobile })}
                onTouchStart={onStart}
                onTouchMove={handleTouch}
                onTouchEnd={onEnd}
            >
                {children}
            </div>
        );
    }

    return (
        <>
            <Drawer
                id={DrawerActionType.LOCATION_DRAWER}
                isOpen={!!locationDrawerOpen}
                position={!isMobile ? "left" : "location"}
            >
                <MotionConfig
                    transition={{ ease: "easeOut", duration: 0.25 }}
                    reducedMotion={"user"}
                >
                    <AnimatePresence>
                        {isLoading === "loading" && (
                            <motion.div
                                key="motion-wrapper"
                                animate={{ y: 0 }}
                                initial={{ y: !isMobile ? 0 : "100vh" }}
                                exit={{ opacity: 0 }}
                            >
                                <LocationLoading
                                    location={location}
                                    loading={isLoading === "loading"}
                                    setLoading={setLoading}
                                />
                            </motion.div>
                        )}
                        {isLoading === "loaded" && location && (
                            <>
                                <motion.div
                                    id={`motion-div-${location.name}`}
                                    key="motion-wrapper-content"
                                    transition={{ opacity: 5 }}
                                    initial={{
                                        y: isMobile ? 0 : "100vh",
                                        opacity: 0
                                    }}
                                    exit={{ y: isMobile ? "100vh" : 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    className={styles.wrapper}
                                >
                                    <Pullable locationName={location.name}>
                                        <div
                                            className={styles.close}
                                            onClick={() => {
                                                setLoading("closing");
                                                hide(
                                                    DrawerActionType.LOCATION_DRAWER
                                                );
                                            }}
                                        />
                                        <Header
                                            location={location}
                                            onClick={() => {
                                                setLoading("closing");
                                                hide(
                                                    DrawerActionType.LOCATION_DRAWER
                                                );
                                            }}
                                            isSigningUp={isSigningUp}
                                            setIsSigningUp={setIsSigningUp}
                                        />
                                    </Pullable>
                                    <Tabs
                                        className={"tabs"}
                                        selectedIndex={selectedTab}
                                        onSelect={(index) => toggleTab(index)}
                                    >
                                        <TabList className={"tab_list"}>
                                            <Tab selectedClassName={"active"}>
                                                <span>
                                                    <span
                                                        className={
                                                            styles.hidden_sm_mobile
                                                        }
                                                    >
                                                        {freshWater ||
                                                        freshWaterHazard
                                                            ? "Location "
                                                            : "Beach "}{" "}
                                                    </span>
                                                    <span
                                                        className={
                                                            styles.capitalize_sm_mobile
                                                        }
                                                    >
                                                        status
                                                    </span>
                                                </span>
                                            </Tab>
                                            <Tab selectedClassName={"active"}>
                                                <span>
                                                    <span
                                                        className={
                                                            styles.hidden_sm_mobile
                                                        }
                                                    >
                                                        {freshWater ||
                                                        freshWaterHazard
                                                            ? "Location "
                                                            : "Beach "}
                                                    </span>
                                                    <span
                                                        className={
                                                            styles.capitalize_sm_mobile
                                                        }
                                                    >
                                                        info
                                                    </span>
                                                </span>
                                            </Tab>
                                            {/* {!freshWaterHazard && ( */}
                                            {/* <Tab selectedClassName={"active"}>
                                                Sampling Data
                                            </Tab> */}
                                            {/* )} */}
                                        </TabList>
                                        <TabPanel
                                            className={classNames("tab_panel", {
                                                [styles.background_gradient_container]:
                                                    selectedTab === 0
                                            })}
                                        >
                                            <div className={styles.cards}>
                                                {orderCards()}
                                            </div>
                                        </TabPanel>
                                        <TabPanel className={"tab_panel"}>
                                            <LocationDetails
                                                location={location}
                                            />
                                        </TabPanel>
                                        {/* {!freshWaterHazard && ( */}
                                        {/* <TabPanel className={"tab_panel"}>
                                            {location && <SamplingData />}
                                        </TabPanel> */}
                                        {/* )} */}
                                    </Tabs>
                                    <div className={styles.mobile}>
                                        <BackButtonDrawer
                                            noMarginTop
                                            noParentPadding
                                            onClick={() => {
                                                hide(
                                                    DrawerActionType.LOCATION_DRAWER
                                                );
                                                setLoading("closing");
                                            }}
                                        />
                                    </div>
                                </motion.div>
                                <div className={styles.transition_background} />
                            </>
                        )}
                    </AnimatePresence>
                </MotionConfig>
            </Drawer>
        </>
    );
}
