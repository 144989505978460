import { FC, useEffect, useState } from "react";
import SpecialEvent from "../../../types/SpecialEvent";

interface MarkerProps extends google.maps.MarkerOptions {
    zoom: number;
    specialEvent: SpecialEvent;
    onClick?: (s: SpecialEvent) => void;
}

function createInfoWindow(
    map: google.maps.Map,
    marker: google.maps.Marker,
    specialEvent: SpecialEvent,
    onClick?: (s: SpecialEvent) => void
) {
    const infoWindow = new google.maps.InfoWindow({
        content: `<span>${specialEvent.name}</span>`,
        position: marker.getPosition()
    });

    marker.addListener("mouseover", () => {
        infoWindow.open({ anchor: marker, map, shouldFocus: true });
    });

    marker.addListener("mouseout", () => {
        infoWindow.close();
    });

    marker.addListener("click", () => {
        onClick?.(specialEvent);
        const position = marker.getPosition();
        if (position) {
            map.panTo(position);
        }
    });
}

export const SpecialEventMarker: FC<MarkerProps> = (options) => {
    const { specialEvent, map, onClick } = options;
    const [marker, setMarker] = useState<google.maps.Marker>();

    useEffect(() => {
        if (!marker) {
            const _marker = new google.maps.Marker();
            setMarker(_marker);
            createInfoWindow(
                map as google.maps.Map,
                _marker,
                specialEvent,
                onClick
            );
        }

        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [options]);

    return null;
};
