import * as Sentry from "@sentry/react";
export * as Sentry from "@sentry/react";

export const initSentry = () => {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENV,
        tracesSampleRate: 1.0 //  Capture 100% of the transactions
    });
};

export const logSentry = (
    ...args: Parameters<typeof Sentry["captureMessage"]>
) => {
    console.debug(args[0]);

    if (process.env.REACT_APP_SENTRY_LEVEL === "log") {
        Sentry.captureMessage(...args);
    }
};
