import { FirebaseApp, initializeApp } from "firebase/app";
import {
    Auth,
    getAuth,
    onAuthStateChanged,
    sendPasswordResetEmail as fbSendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut as fbSignOut
} from "firebase/auth";
import React, {
    createContext,
    PropsWithChildren,
    ReactElement,
    useContext,
    useEffect,
    useState
} from "react";
import { me, signupUser, updateUser } from "../api/users";
import SafeswimUser, { SignupUserRequest } from "../types/SafeswimUser";
import { useTokenListener } from "../utils/token-listener/token-listener";
import { useEmitAuthedToWebview } from "../utils/token-listener/emit-authed";

interface Context {
    authenticating: boolean;
    signIn: (
        email: string,
        password: string
    ) => Promise<SafeswimUser | undefined>;
    signOut: () => Promise<void>;
    sendPasswordResetEmail: (
        email: string,
        continueUrl?: string
    ) => Promise<void>;
    safeswimUser?: SafeswimUser | undefined;
    signUp: (request: SignupUserRequest) => Promise<SafeswimUser | undefined>;
    updateMe: (
        userId: string,
        request: SignupUserRequest
    ) => Promise<SafeswimUser | undefined>;
}

export const FirebaseContext = createContext<Context>({
    authenticating: true,
    signIn: () => Promise.resolve(undefined),
    signOut: () => Promise.resolve(),
    sendPasswordResetEmail: () => Promise.resolve(),
    signUp: () => Promise.resolve(undefined),
    updateMe: () => Promise.resolve(undefined)
});

export const FirebaseProvider = (
    props: PropsWithChildren<unknown>
): ReactElement => {
    const [app, setApp] = useState<FirebaseApp | undefined>();
    const [auth, setAuth] = useState<Auth | undefined>();
    const [authenticating, setAuthenticating] = useState<boolean>(true);
    const [user, setUser] = useState<SafeswimUser | undefined>();
    const hasUser = Boolean(user);

    useTokenListener(hasUser);
    useEmitAuthedToWebview(hasUser);

    useEffect(() => {
        if (!app) {
            const fbapp = initializeApp(
                JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string)
            );
            setApp(fbapp);

            const auth = getAuth();
            setAuth(auth);

            onAuthStateChanged(auth, async (fbuser) => {
                if (fbuser) {
                    const safeswimUser = await me();
                    setUser(safeswimUser);
                    setAuthenticating(false);
                } else {
                    setUser(undefined);
                    setAuthenticating(false);
                }
            });
        }
    }, [app]);

    const signIn = async (email: string, password: string) => {
        if (!auth) {
            throw new Error("firebase application is not initialised");
        }

        try {
            setAuthenticating(true);
            await signInWithEmailAndPassword(auth, email, password);
            return undefined;
        } finally {
            setAuthenticating(false);
        }
    };

    const signOut = async () => {
        if (!auth) {
            throw new Error("firebase application is not initialised");
        }
        return fbSignOut(auth);
    };

    const sendPasswordResetEmail = async (email: string) => {
        if (!auth) {
            throw new Error("firebase application is not initialised");
        }
        return fbSendPasswordResetEmail(auth, email);
    };

    const signUp = async (request: SignupUserRequest) => {
        if (!auth) {
            throw new Error("firebase application is not initialised");
        }

        try {
            setAuthenticating(true);
            const user = await signupUser(request);
            await signInWithEmailAndPassword(
                auth,
                request.email,
                request.password
            );
            setUser(user);
            return user;
        } catch (err) {
            setAuthenticating(false);
            console.log(err);
            throw err;
        }
    };

    const updateMe = async (userId: string, request: SignupUserRequest) => {
        try {
            setAuthenticating(true);
            const user = await updateUser(userId, request);
            setUser(user);
            return user;
        } finally {
            setAuthenticating(false);
        }
    };

    return (
        <FirebaseContext.Provider
            value={{
                authenticating,
                safeswimUser: user,
                signIn,
                signOut,
                sendPasswordResetEmail,
                signUp,
                updateMe
            }}
            {...props}
        />
    );
};

export const useFirebase = (): Context => useContext(FirebaseContext);
