import styles from "./MenuDrawer.module.scss";
import { DrawerActionType, useDrawerContext } from "../../../context/drawer";
import { useFirebase } from "../../../context/firebase";
import Drawer from "../Drawer";
import Button from "../../buttons/button/Button";
// import { useNavigate } from "react-router-dom";
import { CloseButton } from "../../buttons/drawerButton/DrawerButton";
import PartnerLogos from "../../partners/PartnerLogos";
import { useMobileState } from "../../../hooks/useMobileState";

export default function MenuDrawer() {
    const { safeswimUser, signOut } = useFirebase();
    const {
        menuDrawerOpen,
        aboutDrawerOpen,
        faqDrawerOpen,
        facilitySearchDrawerOpen,
        loginDrawerOpen,
        signupDrawerOpen,
        waterSafety101DrawerRightOpen,
        forgotPasswordDrawerOpen,
        favouriteBeachDrawerOpen,
        contactDrawerOpen,
        notificationsDrawerOpen,
        profileDrawerOpen,
        LatestVideosDrawerOpen,
        policyDrawerOpen,
        hide,
        show
        // hideAll
    } = useDrawerContext();
    const { isMobile } = useMobileState();
    // const navigate = useNavigate();

    // const onLatestNewsAndVideosClick = () => {
    //     window.open("https://education.safeswim.org.nz");
    // };

    const onLogoutClick = async () => {
        await signOut();
    };

    const closeMenuDrawer = () => {
        if (
            loginDrawerOpen ||
            signupDrawerOpen ||
            forgotPasswordDrawerOpen ||
            favouriteBeachDrawerOpen ||
            aboutDrawerOpen ||
            faqDrawerOpen ||
            facilitySearchDrawerOpen ||
            contactDrawerOpen ||
            notificationsDrawerOpen ||
            profileDrawerOpen ||
            LatestVideosDrawerOpen ||
            waterSafety101DrawerRightOpen ||
            policyDrawerOpen
        ) {
            hide(DrawerActionType.LOGIN_DRAWER);
            hide(DrawerActionType.SIGNUP_DRAWER);
            hide(DrawerActionType.FORGOT_PASSWORD_DRAWER);
            hide(DrawerActionType.FAVOURITE_BEACH_DRAWER);
            hide(DrawerActionType.ABOUT_DRAWER);
            hide(DrawerActionType.FAQ_DRAWER);
            hide(DrawerActionType.FACILITY_SEARCH_DRAWER);
            hide(DrawerActionType.NOTIFICATION_DRAWER);
            hide(DrawerActionType.CONTACT_DRAWER);
            hide(DrawerActionType.PROFILE_DRAWER);
            hide(DrawerActionType.VIDEOS_DRAWER);
            hide(DrawerActionType.WATER_SAFETY_101_DRAWER_RIGHT);
            hide(DrawerActionType.POLICY_DRAWER);
        } else {
            hide(DrawerActionType.MENU_DRAWER);
        }
    };

    // const onHomeClick = (href: string) => {
    //     hideAll();
    //     show(DrawerActionType.WELCOME_DRAWER);
    //     navigate(href);
    // };

    const onMenuItemClick = (drawer: DrawerActionType) => {
        show(drawer);
        if (isMobile) {
            hide(DrawerActionType.MENU_DRAWER);
        }
    };

    // const showPage = (href: string) => {
    //     hideAll();
    //     hide(DrawerActionType.WELCOME_DRAWER);
    //     navigate(href);
    // };

    return (
        <Drawer
            id={DrawerActionType.MENU_DRAWER}
            position={"right"}
            isOpen={!!menuDrawerOpen}
            className={styles.menuDrawerBody}
        >
            <div className={styles.container}>
                <CloseButton onClick={closeMenuDrawer} />

                <h5
                    className={styles.item}
                    onClick={() => hide(DrawerActionType.MENU_DRAWER)}
                >
                    Map
                </h5>
                <h5
                    className={styles.item}
                    onClick={() =>
                        onMenuItemClick(DrawerActionType.ABOUT_DRAWER)
                    }
                >
                    About Safeswim
                </h5>
                <h5
                    className={styles.item}
                    onClick={() =>
                        onMenuItemClick(
                            DrawerActionType.WATER_SAFETY_101_DRAWER_RIGHT
                        )
                    }
                >
                    Beach safety
                </h5>
                <h5
                    className={styles.item}
                    onClick={() =>
                        onMenuItemClick(DrawerActionType.VIDEOS_DRAWER)
                    }
                >
                    The Science behind Safeswim
                </h5>
                <h5
                    className={styles.item}
                    onClick={() => onMenuItemClick(DrawerActionType.FAQ_DRAWER)}
                >
                    FAQs
                </h5>
                <h5
                    className={styles.item}
                    onClick={() =>
                        onMenuItemClick(DrawerActionType.FACILITY_SEARCH_DRAWER)
                    }
                >
                    Find a facility
                </h5>
                <h5
                    className={styles.item}
                    onClick={() =>
                        onMenuItemClick(DrawerActionType.FAVOURITE_BEACH_DRAWER)
                    }
                >
                    Favourites
                </h5>

                <h5
                    className={styles.item}
                    onClick={() =>
                        onMenuItemClick(DrawerActionType.CONTACT_DRAWER)
                    }
                >
                    Contact Safeswim and partners
                </h5>
                {safeswimUser && (
                    <h5
                        className={styles.item}
                        onClick={() =>
                            onMenuItemClick(DrawerActionType.PROFILE_DRAWER)
                        }
                    >
                        My profile
                    </h5>
                )}
                <h5
                    className={styles.item}
                    onClick={() =>
                        onMenuItemClick(DrawerActionType.POLICY_DRAWER)
                    }
                >
                    Privacy and Terms
                </h5>
                <div className={styles.button_group}>
                    {safeswimUser ? (
                        <div className={styles.button}>
                            <Button
                                variant={"filled"}
                                size="lg"
                                onClick={onLogoutClick}
                                className={styles.button}
                            >
                                Log out
                            </Button>
                        </div>
                    ) : (
                        <div className={styles.button}>
                            <Button
                                color={"primary"}
                                size="lg"
                                onClick={() =>
                                    show(DrawerActionType.LOGIN_DRAWER)
                                }
                            >
                                Login
                            </Button>
                            <Button
                                variant={"outline"}
                                size="lg"
                                onClick={() =>
                                    show(DrawerActionType.SIGNUP_DRAWER)
                                }
                            >
                                Sign up
                            </Button>
                        </div>
                    )}
                </div>

                <div className={styles.position_absolute}>
                    <PartnerLogos />
                </div>
            </div>
        </Drawer>
    );
}
