import classNames from "classnames";
import styles from "./WaterSafetyAlertCard.module.scss";
import SafeswimLocation from "../../../types/SafeswimLocation";
import generic from "../../../resources/images/hazards/hazard-symbols_unspecified-hazard.png";
import red_flag from "../../../resources/images/random/red_flag.svg";
import ChevronRight from "../../images/chevrons/ChevronRight";
import { DrawerActionType, useDrawerContext } from "../../../context/drawer";
import { containsTag } from "../../../utils/location";

type WaterSafetyAlertCardProps = {
    location?: SafeswimLocation;
};

export default function WaterSafetyAlertCard({
    location
}: WaterSafetyAlertCardProps) {
    const { show } = useDrawerContext();

    if (containsTag(location, "fresh_water_hazard")) {
        return (
            <div
                className={styles.overflow_wrapper}
                onClick={() => show(DrawerActionType.WATER_SAFETY_DRAWER)}
            >
                <div
                    className={classNames(
                        styles.water_safety_alert_card,
                        styles.margin
                    )}
                >
                    <div className={styles.alert_item}>
                        <div className={styles.header}>
                            <img src={generic} alt={""} />
                            <div className={styles.title}>
                                <div className={styles.strapline}>
                                    Safety Warning
                                </div>
                                <h4 className={styles.subtitle}>Do not swim</h4>
                            </div>
                            <div className={styles.chevron_right}>
                                <ChevronRight />
                            </div>
                        </div>
                        <div>
                            {location?.name === "Waitipu"
                                ? "Access prohibited - do not swim."
                                : "Do not swim."}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!location || !location.alerts || !location.alerts.hazards.length) {
        return null;
    }

    const waterSafetyAlerts = location.alerts.hazards;
    return (
        <div className={styles.overflow_wrapper}>
            <div
                className={classNames(styles.water_safety_alerts, {
                    [styles.slider]: waterSafetyAlerts.length > 1
                })}
            >
                {waterSafetyAlerts.map((a, i) => {
                    const isRedFlagAlert = a.value === "dangerous_conditions";
                    return (
                        <div
                            onClick={() =>
                                show(DrawerActionType.WATER_SAFETY_DRAWER)
                            }
                            key={`${a.id}-${a.value}`}
                            className={classNames(
                                styles.water_safety_alert_card,
                                {
                                    [styles.red]: isRedFlagAlert,
                                    [styles.margin]:
                                        waterSafetyAlerts.length < 2
                                }
                            )}
                        >
                            <div key={a.id} className={styles.alert_item}>
                                <div className={styles.header}>
                                    <img
                                        src={
                                            isRedFlagAlert
                                                ? red_flag
                                                : a.imageUrl || generic
                                        }
                                        className={classNames({
                                            [styles.imagePadding]:
                                                !isRedFlagAlert
                                        })}
                                        alt={""}
                                    />
                                    <div className={styles.title}>
                                        <div className={styles.strapline}>
                                            Safety Warning
                                        </div>
                                        <h4 className={styles.subtitle}>
                                            {a.title}
                                        </h4>
                                    </div>
                                    {/* <div className={styles.chevron_right}>
                                        <ChevronRight />
                                    </div> */}
                                </div>
                                <div className={styles.description}>
                                    {a.description}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
