import Drawer from "../../Drawer";
import { DrawerActionType, useDrawerContext } from "../../../../context/drawer";
import styles from "./LatestVideos.module.scss";
import { HomeButton } from "../../../buttons/drawerButton/DrawerButton";
import { BackButtonDrawer } from "../../BackButtonDrawer/BackButtonDrawer";
import safeNetwork from "../../../../resources/images/science/safe-networks.png";
import reduceOverflows from "../../../../resources/images/science/reduce-overflows.png";
import targetedRate from "../../../../resources/images/science/targeted-rate.png";

export default function LatestVideos() {
    const { LatestVideosDrawerOpen, hideAll } = useDrawerContext();

    return (
        <Drawer
            id={DrawerActionType.VIDEOS_DRAWER}
            isOpen={!!LatestVideosDrawerOpen}
            position={"right"}
        >
            <div className={styles.container}>
                <HomeButton onClick={() => hideAll()} />
                <h2>The science behind Safeswim</h2>
                <p className={styles.subheading}>
                    Dive into our video series to learn more about the Safeswim
                    programme.
                </p>
                <ol className={styles.list}>
                    <li>
                        <iframe
                            title={"Safeswim’s water quality programme"}
                            className={styles.iframe}
                            src="https://www.youtube.com/embed/PdJOaCSJLes?si=F4F15Is0QK3TfqfH"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <h5>Safeswim's water quality programme </h5>
                        <p>
                            Professor Gillian Lewis from the School of
                            Biological Sciences at Auckland University gives us
                            an overview of the Safeswim water quality programme,
                            explains how the Safeswim water quality samples and
                            models work together and describes what it means
                            when Safeswim issues a ‘red’ alert at a beach.
                        </p>
                    </li>
                    <li>
                        <iframe
                            title={"How the Safeswim models work"}
                            className={styles.iframe}
                            src="https://www.youtube.com/embed/4tg9bV8ZvkY?si=taIfRMdRz7X1up2P"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <h5>How the Safeswim models work</h5>
                        <p>
                            Principal Coastal Scientist Ben Tuckey from DHI
                            explains how the Safeswim models work, what the
                            Safeswim models allow, how they are built and
                            improved with sampling data, and how we know their
                            predictions are accurate.
                        </p>
                    </li>
                    <li>
                        <iframe
                            title={"How contaminants move in the ocean"}
                            className={styles.iframe}
                            src="https://www.youtube.com/embed/2eJkWRDa2E8"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <h5>How contaminants move in the ocean</h5>
                        <p>
                            Principal Coastal Scientist Ben Tuckey from DHI uses
                            two animations to show how environmental conditions
                            affect the movement of contaminant plumes in the
                            ocean, demonstrating why a beach might be a ‘red
                            alert’ or ‘green alert’ under different
                            circumstances.
                        </p>
                    </li>
                    <li>
                        <iframe
                            title={"Avoiding stormwater outlets"}
                            className={styles.iframe}
                            src="https://www.youtube.com/embed/fkh89Ezi_RY?si=aTzDiXByS_w9hjGO"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <h5>Avoiding stormwater outlets</h5>
                        <p>
                            Strategy Manager Andrew Chin from Healthy Waters,
                            Auckland Council explains why Auckland Council
                            recommends against swimming in the water that flows
                            out of pipes onto Auckland’s beaches.
                        </p>
                    </li>
                    <li>
                        <iframe
                            title={"Stormwater and the ‘first flush’"}
                            className={styles.iframe}
                            src="https://www.youtube.com/embed/F7LJtzpsWiw?si=_qurwB6-RS50Vhg5"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <h5>Stormwater and the ‘first flush’</h5>
                        <p>
                            Strategy Manager Andrew Chin from Healthy Waters,
                            Auckland Council explains how stormwater moves
                            through urban environments and what influence it has
                            on water quality at Auckland’s beaches.
                        </p>
                    </li>
                    <li>
                        <img src={safeNetwork} />
                        <h5>Safe Networks Programme</h5>
                        <p>
                            <a
                                href="https://aucklandcouncil.maps.arcgis.com/apps/dashboards/index.html#/a9e2fd486bbb4c3d93a330f021cfd319"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Find out how
                            </a>{" "}
                            Safe Networks (a joint initiative between Auckland
                            Council’s Healthy Waters department and Watercare)
                            conducts monitoring and investigations.
                        </p>
                    </li>
                    <li>
                        <img src={reduceOverflows} />
                        <h5>Working to reduce overflows</h5>
                        <p>
                            <a
                                href="https://www.watercare.co.nz/Faults-outages/Plumbing-and-wastewater/Wastewater-overflows/Working-to-reduce-overflows"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Find out what
                            </a>{" "}
                            Watercare are doing to reduce wet-weather waste
                            water overflows
                        </p>
                    </li>
                    <li>
                        <img src={targetedRate} />
                        <h5>Water Quality Targeted Rate</h5>
                        <p>
                            <a
                                href="https://www.aucklandcouncil.govt.nz/environment/looking-after-aucklands-water/water-quality-targeted-rate/Pages/default.aspx"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Find out more
                            </a>{" "}
                            about how Auckland Council is investing the water
                            quality target rate to achieve cleaner harbours,
                            beaches and streams.
                        </p>
                    </li>
                </ol>
                <BackButtonDrawer paddingBottom onClick={() => hideAll()} />
            </div>
        </Drawer>
    );
}

{
    /* Old layout till client amends are done
    /* <li>
<iframe
    title={"How contaminants move in the ocean"}
    className={styles.iframe}
    src="https://www.youtube.com/embed/2eJkWRDa2E8"
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
/>
<h5>How contaminants move in the ocean</h5>
<p>
    Andrew explains why Auckland Council recommends
    against swimming in the water that flows out of
    pipes onto Auckland’s beaches.
</p>
</li>
<li>
<iframe
    title={"3Rs rip survival plan"}
    className={styles.iframe}
    src="https://www.youtube.com/embed/5jKLXN9svdQ"
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
/>
<h5>What to do if you are caught in a rip</h5>
<p>
    The team at Surf Life Saving Northern Region explain
    what to do if you are caught in a rip.
</p>
</li>
<li>
<iframe
    title={
        "How to avoid swimming near stormwater outlets"
    }
    className={styles.iframe}
    src="https://www.youtube.com/embed/fkh89Ezi_RY"
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
/>
<h5>How to avoid swimming near stormwater outlets</h5>
<p>
    Andrew explains why Auckland Council recommends
    against swimming in the water that flows out of
    pipes onto Auckland’s beaches.
</p>
</li>
<li>
<iframe
    title={
        "Always keep a very close eye on young children in or near the water."
    }
    className={styles.iframe}
    src="https://www.youtube.com/embed/CFB6RQyeutY"
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
/>
<h5>Why you should swim between the flags</h5>
<p>
    If you are heading out to the beach, choose a
    lifeguarded one and remember the red and yellow
    flags show the safest area to swim.
</p>
</li> */
}
