import { Form, Formik } from "formik";
import { useState } from "react";
import { DrawerActionType, useDrawerContext } from "../../../../context/drawer";
import Button from "../../../buttons/button/Button";
import Loading from "../../../loading/Loading";
import Drawer from "../../Drawer";
import styles from "./Contact.module.scss";
import * as Yup from "yup";
import FormikInput from "../../../formik/FormikInput";
import { contact } from "../../../../api/contact";
import { ContactRequest } from "../../../../types/Contact";
import { Modal } from "../../../modal/Modal";
import { HomeButton } from "../../../buttons/drawerButton/DrawerButton";
import ChevronRight from "../../../images/chevrons/ChevronRight";
import FormikTextArea from "../../../formik/FormikTextArea";
import FormikSelect from "../../../formik/FormikSelect";

const updateSchema = Yup.object({
    beach: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    message: Yup.string().required("Required"),
    contact: Yup.string().required("Required")
});

export default function Contact() {
    const { contactDrawerOpen, hideAll, show } = useDrawerContext();
    const [loading, setLoading] = useState<boolean>(false);
    const [initValue, setInitValue] = useState<ContactRequest>({
        name: "",
        beach: "",
        email: "",
        message: "",
        contact: "watercare"
    });
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleSubmit = async (request: ContactRequest) => {
        if (!request.name) {
            request.name = request.email.split("@")[0];
        }
        setInitValue(request);
        setLoading(true);
        try {
            await contact(request);
        } finally {
            setShowModal(true);
            setLoading(false);
        }
    };

    const onBack = () => {
        setShowModal(false);
        hideAll();
    };

    const closeContactDrawer = () => hideAll();

    return (
        <Drawer
            id={DrawerActionType.CONTACT_DRAWER}
            isOpen={!!contactDrawerOpen}
            position={"right"}
        >
            {loading ? (
                <Loading />
            ) : (
                <div className={styles.container}>
                    <HomeButton onClick={closeContactDrawer} />
                    <div className={styles.content}>
                        <h2>Contact Safeswim</h2>
                        <p className={styles.large}>
                            Have you spotted an issue at your beach?
                        </p>
                        <p>
                            In an emergency{" "}
                            <span className={styles.red}>dial 111</span>
                        </p>
                        <p className={styles.bolden}>In the Auckland Region:</p>
                        <ul>
                            <li>
                                To report pollution call the Auckland Council
                                Pollution hotline seven days a week on{" "}
                                <a href="">09 377-3107</a>
                            </li>
                            <li>
                                If you suspect an overflow from the wastewater
                                network in Auckland report a fault{" "}
                                <a href="https://www.watercare.co.nz/faults-outages/report-a-fault">
                                    online
                                </a>{" "}
                                so that the Watercare team can investigate.
                            </li>
                        </ul>
                        <p className={styles.bolden}>
                            In the Northland region:
                        </p>
                        <ul>
                            <li>
                                Call the 24/7 Environmental Hotline 0800 504 639
                                to report all pollution and environmental
                                incidents, including oil or chemical spills,
                                water pollution, dangerous boating, and
                                navigation hazards and breakdowns of navigation
                                lights.
                            </li>
                        </ul>
                        <h5>Would you like to know more about Safeswim?</h5>
                        <p>
                            Find your answer in our{" "}
                            <a
                                onClick={() =>
                                    show(DrawerActionType.FAQ_DRAWER)
                                }
                            >
                                FAQs
                            </a>
                            , or fill out the contact form below.
                        </p>
                        <Formik<ContactRequest>
                            initialValues={initValue}
                            validationSchema={updateSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isValid, isSubmitting }) => {
                                return (
                                    <Form className={styles.form}>
                                        <FormikInput
                                            name={"name"}
                                            label={"Name"}
                                        />
                                        <FormikInput
                                            name={"email"}
                                            label={"Email"}
                                        />
                                        <FormikInput
                                            name={"beach"}
                                            label={"Beach"}
                                            placeholder={"e.g: Piha"}
                                        />
                                        <FormikSelect
                                            name={"contact"}
                                            options={[
                                                {
                                                    value: "watercare",
                                                    label: "Watercare"
                                                },
                                                {
                                                    value: "auckland_council",
                                                    label: "Auckland Council"
                                                },
                                                {
                                                    value: "northland_council",
                                                    label: "Northland Regional Council"
                                                },
                                                {
                                                    value: "sls",
                                                    label: "Surf lifesaving"
                                                }
                                            ]}
                                            label={
                                                "Who are you wanting to contact?"
                                            }
                                        />
                                        <FormikTextArea
                                            name={"message"}
                                            label={"Message"}
                                        />
                                        <Button
                                            className={styles.button}
                                            icon={<ChevronRight />}
                                            type="submit"
                                            color="primary"
                                            size="lg"
                                            disabled={
                                                isSubmitting ||
                                                !isValid ||
                                                loading
                                            }
                                        >
                                            Send message
                                        </Button>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            )}
            <Modal isOpen={showModal} onClose={onBack}>
                <div className={styles.modal}>
                    <p>Thank you for contacting the Safeswim Programme.</p>
                    <p>
                        This inbox is monitored during business hours
                        Monday-Friday. We will endeavour to respond to you
                        within 5 working days of receipt.
                    </p>
                    <p>
                        Would you like to know more about Safeswim? Find your
                        answer in our <a href={"/faq"}>FAQs</a>
                        {/* <a
                            href={
                                "https://education.safeswim.org.nz/recent-updates"
                            }
                            rel={"noopener noreferrer"}
                            target={"_blank"}
                        >
                            click here
                        </a>{" "} */}
                        to learn more about the programme or find out the latest
                        news.
                    </p>
                    <Button color={"primary"} onClick={onBack}>
                        Okay
                    </Button>
                </div>
            </Modal>
        </Drawer>
    );
}
