// import classNames from "classnames";
// import ChevronRight from "../../images/chevrons/ChevronRight";
// import Footer from "../../footer/Footer";
import { useDrawerContext } from "../../../context/drawer";
// import SearchIcon from "../../images/icons/SearchIcon";
import Drawer from "../Drawer";
// import { useModalContext } from "../../../context/modal";
import styles from "./WelcomeDrawer.module.scss";
// import Button from "../../buttons/button/Button";
import SafeswimLogo from "../../images/logos/SafeswimLogo";
import { EnterButton } from "../BackButtonDrawer/BackButtonDrawer";
// import PartnerLogos from "../../partners/PartnerLogos";
// import Disclaimer from "../locationDrawer/components/Disclaimer";

export default function WelcomeDrawer() {
    const { welcomeDrawerOpen, hideAll } = useDrawerContext();

    // const onLearnMoreClick = () => {
    //     show(DrawerActionType.VIDEOS_DRAWER);
    // };

    return (
        <Drawer
            id={"welcome-drawer"}
            isOpen={!!welcomeDrawerOpen}
            position={"left"}
            noOverflow
        >
            <div
                id={"top"}
                className={styles.welcome_drawer_container}
                onClick={hideAll}
            >
                <div className={styles.banner}>
                    <div className={styles.banner_content}>
                        <div className={styles.banner_header}>
                            <SafeswimLogo
                                style={{ color: "#1C3250" }}
                                className={styles.logo}
                            />
                        </div>
                        <div className={styles.title_container}>
                            <div
                                className={styles.title}
                                role="heading"
                                aria-level={1}
                            >
                                <div className={styles.title_one}>
                                    Decide with
                                </div>
                                <div className={styles.title_two}>Safeswim</div>
                                <p>
                                    Safeswim gives you up to the minute
                                    information on water quality and swimming
                                    conditions at your favourite swimming spots.
                                </p>
                            </div>
                        </div>

                        {/* Mobile only buttons */}
                        <div className={styles.banner_footer}>
                            <img src="/creative-images/safeswim_menu_image.svg" />
                            <div className={styles.search_block}>
                                <EnterButton onClick={hideAll} />
                                {/* <Button
                                    className={styles.button}
                                    icon={<ChevronRight />}
                                    onClick={hideAll}
                                    variant="filled"
                                    size="md"
                                    color="primary"
                                >
                                    Browse the map
                                </Button>
                                <Button
                                    className={styles.search_button}
                                    icon={<SearchIcon />}
                                    onClick={() => {
                                        hideAll();
                                        showModal(ModalActionType.SEARCH_MODAL);
                                    }}
                                    variant="link"
                                    size="lg"
                                >
                                    Find a beach
                                </Button> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={styles.safeswim_blurb}>
                    Safeswim gives you up to the minute information on water
                    quality and swimming conditions at your favourite spots.
                </div>
                <div className={styles.content_bottom}>
                    <div
                        className={classNames(styles.section, styles.dial_111)}
                    >
                        <div className={styles.grey_block}>
                            <span className={styles.strapline}>
                                If someone in the water is in trouble:
                            </span>
                            <div>
                                <a
                                    href="tel:111"
                                    className={styles.red_highlight}
                                >
                                    Dial 111
                                </a>{" "}
                                and ask for the <span>Police</span>.
                            </div>
                        </div>
                    </div>
                    <div
                        className={classNames(
                            styles.section,
                            styles.safety_information
                        )}
                    >
                        <h5 aria-level={2}>Find out more</h5>
                        <p>
                            Click here for more information on how to stay safe
                            in the water and the science behind Safeswim.
                        </p>
                        <Button
                            className={styles.button}
                            icon={<ChevronRight />}
                            onClick={onLearnMoreClick}
                            variant="filled"
                            size="sm"
                        >
                            Safeswim 101
                        </Button>
                    </div>

                    <div
                        className={classNames(
                            styles.section,
                            styles.our_partners
                        )}
                    >
                        <h5 aria-level={2}>Our Partners</h5>
                        <p>
                            Safeswim is a joint initiative between the following
                            partners
                        </p>
                        <PartnerLogos />
                    </div>
                </div>
                <div className={classNames(styles.section, styles.footer)}>
                    <Footer />
                    <div className={styles.disclaimer}>
                        <Disclaimer />
                    </div>
                </div> */}
            </div>
        </Drawer>
    );
}
