import { UAParser } from "ua-parser-js";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export default function Download() {
    const navigate = useNavigate();

    useEffect(() => {
        let parser = new UAParser(window.navigator.userAgent);
        let parserResults = parser.getDevice();
        const { vendor, model, type } = parserResults;
        if (type === "mobile") {
            if (vendor === "Apple") {
                window.location.replace(
                    "https://apps.apple.com/nz/app/safeswim/id6473572354"
                );
            } else {
                window.location.replace(
                    "https://play.google.com/store/apps/details?id=nz.daylightgroup.safeswim&pcampaignid=web_share"
                );
            }
        } else {
            console.log("Not a mobile");
            window.location.replace("/");
        }
    });

    return <div>Download the app now</div>;
}
