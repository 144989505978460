import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import classNames from "classnames";
import Drawer from "../Drawer";
import { DrawerActionType, useDrawerContext } from "../../../context/drawer";
import SafeswimLocation from "../../../types/SafeswimLocation";
import {
    containsTag,
    getCurrentWaterQualityLevel,
    getWaterQualityTextByLevel,
    WaterQualityLevel
} from "../../../utils/location";
import styles from "./WaterQualityDrawer.module.scss";
import { getWording } from "../../cards/waterQualityCard/WaterQualityCard";
import Header from "../locationDrawer/components/header/Header";
import WaterQualityIcon from "../../images/icons/WaterQuality";
import watercare from "../../../resources/images/logos/watercare_small.png";
import ShareButton from "../../buttons/shareButton/ShareButton";
import SafeswimAlert from "../../../types/SafeswimAlert";
import { useEffect, useRef } from "react";

type Props = {
    location?: SafeswimLocation;
};

const getSubtitle = (
    location: SafeswimLocation,
    isUnavailable: boolean,
    currentWaterQuality: WaterQualityLevel
) => {
    if (isUnavailable) {
        return null;
    }

    let currentRecordedDate = new Date();
    const nowHour = currentRecordedDate.getHours();

    if (
        location.forecast &&
        location.forecast.today &&
        location.forecast.today.water
    ) {
        const todaysWaterForecast = location.forecast.today.water;
        if (todaysWaterForecast[nowHour]) {
            currentRecordedDate = new Date(
                todaysWaterForecast[nowHour].recordedAt
            );
        } else if (todaysWaterForecast[nowHour - 1]) {
            currentRecordedDate = new Date(
                todaysWaterForecast[nowHour - 1].recordedAt
            );
        }
    }

    if (location.alerts && !location.alerts.waterQuality.length) {
        if (currentWaterQuality === WaterQualityLevel.UNAVAILABLE) {
            return (
                <span>
                    {format(
                        utcToZonedTime(currentRecordedDate, "Pacific/Auckland"),
                        "EEE dd MMM yyyy, hh:mm a"
                    )}
                </span>
            );
        }

        return (
            <span>
                Model data reading:{" "}
                {format(
                    utcToZonedTime(currentRecordedDate, "Pacific/Auckland"),
                    "EEE dd MMM yyyy, hh:mm a"
                )}
            </span>
        );
    }

    if (location.alerts && location.alerts.waterQuality.length > 0) {
        const alert = location.alerts.waterQuality[0];

        if (alert.description.toLowerCase()?.includes("overflow")) {
            return (
                <div className={styles.subtitle}>
                    <div className={styles.system_status_reading}>
                        Overflow sensor reading:{" "}
                        {format(
                            utcToZonedTime(alert.createdAt, "Pacific/Auckland"),
                            "EEE dd MMM yyyy, hh:mm a"
                        )}
                    </div>
                    <div className={styles.status_explanation}>
                        <p>
                            A wastewater overflow has been detected in the area.
                        </p>
                        {alert.textOverride && <p>{alert.textOverride}</p>}
                        <AlertFeed alert={alert} />
                        <p>
                            Overflows are more common in wet weather, but they
                            can happen in dry weather if pipes are blocked or
                            damaged.
                        </p>
                        <p>
                            Wastewater overflows occur when wastewater (sewage)
                            spills out from gully traps, manholes, engineered
                            overflow points or pump stations. It then flows into
                            back yards or waterways and the sea.
                        </p>
                        <ShareButton location={location} />
                    </div>
                    {location.regionBased?.includes("Auckland") && (
                        <div className={styles.overflow}>
                            <img src={watercare} alt={""} />
                            <div className={styles.title}>
                                If you suspect an overflow:
                            </div>
                            <p>
                                Contact Watercare Services, 24 hours a day, 7
                                days a week on{" "}
                                <a href={"tel:+6494422222"}>09 442-2222</a>{" "}
                                (press 1), or{" "}
                                <a
                                    href={
                                        "https://www.watercare.co.nz/faults-outages/report-a-fault"
                                    }
                                >
                                    report a fault online
                                </a>
                                .
                            </p>
                        </div>
                    )}
                </div>
            );
        }
        return (
            <span className={"overflow-span"}>
                {format(
                    utcToZonedTime(alert.createdAt, "Pacific/Auckland"),
                    "EEE dd MMM yyyy, hh:mm a"
                )}
            </span>
        );
    }

    return null;
};

const shouldISwimToday = (
    location: SafeswimLocation,
    isFreshWaterHazard: boolean,
    noWaterQuality: boolean,
    currentWaterQualityLevel: WaterQualityLevel
) => {
    if (
        !location.regionBased ||
        location.isPermanent ||
        isFreshWaterHazard ||
        noWaterQuality ||
        currentWaterQualityLevel === WaterQualityLevel.UNAVAILABLE ||
        (location.alerts && location.alerts.waterQuality.length > 0)
    ) {
        return null;
    }

    const isAucklandBased = location.regionBased?.includes("Auckland");

    return (
        <div className={styles.should_i_swim_today}>
            <h4>Should I swim today?</h4>
            <p className={styles.large}>
                Safeswim's water quality forecasts take account of rainfall,
                wind, tide, sunlight and beach type. They are built using
                high-frequency targeted sampling on top of historical monitoring
                results, and are underpinned by the best available
                meteorological data, including readings from rain radar and an
                extensive network of rain gauges. Safeswim's forecasts are
                automatically overridden if sensors detect unpredicted events –
                like a wastewater overflow in dry weather – that are likely to
                cause a public health risk.
            </p>
            <h6>Video: How contaminants move in the ocean</h6>
            <iframe
                title={"About Safeswim"}
                className={styles.iframe}
                src="https://www.youtube.com/embed/EuacqRMPwB0"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
            <h4>How the Safeswim quality assurance models work</h4>
            <p>
                Safeswim is overseen by an independent panel of public health
                and water quality experts and is required to meet performance
                standards adopted from the United States Geological Survey
                (USGS).
            </p>
            <p>
                For a water quality predictive model to be brought onto the
                Safeswim platform, it must meet benchmarks for overall accuracy,
                sensitivity (ability to predict exceedances) and specificity
                (ability to predict compliances). Models are in a constant state
                of review and are refined regularly in response to
                <span>
                    {isAucklandBased ? (
                        " Auckland Council’s "
                    ) : (
                        <>
                            {" "}
                            <a
                                href={
                                    "https://www.nrc.govt.nz/living-in-northland/at-the-beach/swimming-water-quality/"
                                }
                                target={"_blank"}
                                rel={"noopener noreferrer"}
                            >
                                Northland Regional Council’s
                            </a>{" "}
                        </>
                    )}
                </span>
                ongoing beach sampling programme.
            </p>
        </div>
    );
};

const AlertFeed = ({ alert }: { alert: SafeswimAlert | null }) => {
    const progressBar = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (progressBar && progressBar.current && alert) {
            switch (alert.state) {
                case "OVERFLOW_REPORTED":
                    progressBar.current.style.setProperty("--fill", "14%");
                    break;
                case "INVESTIGATING":
                    progressBar.current.style.setProperty("--fill", "39%");
                    break;
                case "WORK_UNDERWAY":
                    progressBar.current.style.setProperty("--fill", "64%");
                    break;
                case "WORK_COMPLETE":
                    progressBar.current.style.setProperty("--fill", "100%");
                    break;
                default:
                    break;
            }
        }
    }, [progressBar]);

    if (!alert || !alert.state) {
        return null;
    }

    return (
        <div className={styles.updates}>
            <h4>Updates</h4>
            <div className={styles.overflow_state}>
                <div>
                    <div
                        ref={progressBar}
                        className={classNames(styles.progress_bar)}
                    >
                        <div
                            className={classNames(styles.step, {
                                [styles.active]:
                                    alert.state === "OVERFLOW_REPORTED"
                            })}
                        />
                        <div
                            className={classNames(styles.step, {
                                [styles.active]: alert.state === "INVESTIGATING"
                            })}
                        />
                        <div
                            className={classNames(styles.step, {
                                [styles.active]: alert.state === "WORK_UNDERWAY"
                            })}
                        />
                        <div
                            className={classNames(styles.step, {
                                [styles.active]: alert.state === "WORK_COMPLETE"
                            })}
                        />
                    </div>
                    <div className={styles.progress_states}>
                        <div
                            className={classNames({
                                [styles.active]:
                                    alert.state === "OVERFLOW_REPORTED"
                            })}
                        >
                            Overflow event reported
                        </div>
                        <div
                            className={classNames({
                                [styles.active]: alert.state === "INVESTIGATING"
                            })}
                        >
                            Investigating
                        </div>
                        <div
                            className={classNames({
                                [styles.active]: alert.state === "WORK_UNDERWAY"
                            })}
                        >
                            Work underway
                        </div>
                        <div
                            className={classNames({
                                [styles.active]: alert.state === "WORK_COMPLETE"
                            })}
                        >
                            Work completed
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.feed}>
                {alert.updateFeed?.length &&
                    alert.updateFeed.map((feed, index) => (
                        <div
                            key={`alert-feed-${alert?.id}-${index}`}
                            className={styles.feed_item}
                        >
                            <h6>
                                {format(
                                    new Date(feed.createdAt),
                                    "dd/MM/yyyy, hh:mmaaa"
                                )}
                            </h6>
                            {feed.description && <p>{feed.description}</p>}
                            {feed.images &&
                                feed.images.length > 0 &&
                                feed.images.map((img, index) => (
                                    <img
                                        key={`alert-feed-images-${index}`}
                                        src={img}
                                        alt={""}
                                    />
                                ))}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default function WaterQualityDrawer(props: Props) {
    const { location } = props;
    const { waterQualityDrawerOpen, show } = useDrawerContext();
    const isSLSOnly = containsTag(location, "sls_only");
    const isFreshWaterHazard = containsTag(location, "fresh_water_hazard");
    const noWaterQuality = containsTag(location, "no_water_quality");
    const currentWaterQualityLevel = getCurrentWaterQualityLevel(location);
    const isUnavailable =
        isSLSOnly ||
        isFreshWaterHazard ||
        noWaterQuality ||
        currentWaterQualityLevel === WaterQualityLevel.UNAVAILABLE;
    if (!location) {
        return null;
    }

    const subtitle = getSubtitle(
        location,
        isUnavailable,
        currentWaterQualityLevel
    );

    const getDescription = () => {
        if (location.unavailable) {
            return (
                <p>
                    Safeswim is currently experiencing a temporary technical
                    problem at this location. We are working on a solution and
                    will get the water quality predictions back up and running
                    at this location as soon as we can. We apologise for the
                    interruption in service.
                </p>
            );
        }

        if (isSLSOnly) {
            return (
                <p>
                    Safeswim does not currently have enough information at this
                    beach to provide reliable water quality advice. Samples are
                    being collected to increase our understanding of water
                    quality at this beach and this information will be added to
                    the website in the future. For advice on water quality at
                    this location please contact{" "}
                    <button
                        className={styles.inline_button}
                        onClick={() => show(DrawerActionType.CONTACT_DRAWER)}
                    >
                        Safeswim
                    </button>
                    .
                </p>
            );
        }

        if (isFreshWaterHazard) {
            return (
                <p>
                    Safeswim does not monitor water quality here because of the
                    hazards at this location.
                </p>
            );
        }

        if (noWaterQuality) {
            if (location.regionBased?.includes("Auckland")) {
                return (
                    <p>
                        Please{" "}
                        <button
                            className={styles.inline_button}
                            onClick={() =>
                                show(DrawerActionType.CONTACT_DRAWER)
                            }
                        >
                            contact Safeswim
                        </button>{" "}
                        for the latest water quality sampling results in your
                        area.
                    </p>
                );
            } else if (location.regionBased?.includes("Northland")) {
                return (
                    <>
                        <p>
                            Northland Regional Council intends to extend its
                            predictive water quality modelling programme in
                            2022/23. Real time information will be made
                            available at sites around Northland when models and
                            automatic sensors on the wastewater network are in
                            place and have met international guidelines for
                            accuracy.
                        </p>
                        <p>
                            In the meantime visit the{" "}
                            <a
                                href={
                                    "https://www.nrc.govt.nz/for-schools/school-information-packs/water-quality"
                                }
                                target={"_blank"}
                                rel={"noopener noreferrer"}
                            >
                                Northland Regional Council
                            </a>{" "}
                            website for the latest water quality sampling
                            results in your area.
                        </p>
                    </>
                );
            }

            return (
                <p>
                    Please visit your Regional Council website for the latest
                    water quality sampling results in your area.
                </p>
            );
        }

        if (location.alerts && location.alerts.waterQuality.length > 0) {
            const alert = location.alerts.waterQuality[0];
            if (alert.description.includes("overflow")) {
                return null;
            }
            if (alert.textOverride) {
                return (
                    <>
                        <p>{alert.description}</p>
                        <p>{alert.textOverride}</p>
                    </>
                );
            }
            return <p>{alert.description}</p>;
        }

        if (location.isPermanent) {
            if (currentWaterQualityLevel === WaterQualityLevel.HIGH_RISK) {
                return (
                    <p>
                        Results of water quality samples taken at this location
                        consistently fail to meet national guidelines. Swimming
                        is not advised.
                    </p>
                );
            }
            return (
                <p>
                    Results of water quality samples taken at this location
                    consistently meet national guidelines.
                </p>
            );
        }

        if (currentWaterQualityLevel === WaterQualityLevel.GOOD) {
            return (
                <p>
                    Safeswim water quality models predict that levels of Faecal
                    Indicator Bacteria meet national guidelines for swimming,
                    based on guidance published by the Ministry of Health and
                    Ministry for the Environment.
                </p>
            );
        } else if (currentWaterQualityLevel === WaterQualityLevel.HIGH_RISK) {
            return (
                <p>
                    Safeswim water quality models predict that levels of Faecal
                    Indicator Bacteria breach national guidelines for swimming,
                    based on guidance published by the Ministry of Health and
                    Ministry for the Environment.
                </p>
            );
        }
    };

    const title = getWording(
        isFreshWaterHazard ? undefined : currentWaterQualityLevel
    ).name;

    return (
        <Drawer
            id={DrawerActionType.WATER_QUALITY_DRAWER}
            isOpen={!!waterQualityDrawerOpen}
            position={"left"}
            secondary
        >
            <Header
                location={location}
                submenuDrawer={DrawerActionType.WATER_QUALITY_DRAWER}
            />
            <div className={styles.container}>
                <h2>{title}</h2>
                {currentWaterQualityLevel && (
                    <div className={styles.status_container}>
                        <div
                            className={classNames(styles.level_icon, {
                                [styles.full_opacity]:
                                    currentWaterQualityLevel === "green"
                            })}
                        >
                            <WaterQualityIcon
                                level="green"
                                className={styles.green}
                            />
                            <p>{getWaterQualityTextByLevel("green")?.short}</p>
                        </div>
                        <div
                            className={classNames(styles.level_icon, {
                                [styles.full_opacity]:
                                    currentWaterQualityLevel === "red"
                            })}
                        >
                            <WaterQualityIcon
                                level="red"
                                className={styles.red}
                            />
                            <p>{getWaterQualityTextByLevel("red")?.short}</p>
                        </div>
                        <div
                            className={classNames(styles.level_icon, {
                                [styles.full_opacity]:
                                    currentWaterQualityLevel === "black"
                            })}
                        >
                            <WaterQualityIcon
                                level="black"
                                className={styles.black}
                            />
                            <p>{getWaterQualityTextByLevel("black")?.short}</p>
                        </div>
                    </div>
                )}

                <div>{subtitle}</div>
                <div className={styles.description}>{getDescription()}</div>
                {shouldISwimToday(
                    location,
                    isFreshWaterHazard,
                    noWaterQuality,
                    currentWaterQualityLevel
                )}
            </div>
        </Drawer>
    );
}
