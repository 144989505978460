import { Modal } from "../Modal";
import { ModalActionType, useModalContext } from "../../../context/modal";
import { useMapContext } from "../../../context/map";
import { useRef, useState } from "react";
import SearchBox, { SearchBoxRef } from "../../searchBox/SearchBox";
import SafeswimLocation from "../../../types/SafeswimLocation";
import { fireTagEvent } from "../../../utils/tagmanager";
import styles from "./SearchModal.module.scss";
import PulseLoader from "react-spinners/PulseLoader";

type Props = {
    show: boolean;
    onLocationClick: (location: SafeswimLocation) => void;
};

export default function AlertsModal(props: Props) {
    const { show, onLocationClick } = props;
    const { hideModal } = useModalContext();
    const { map } = useMapContext();
    const searchRef = useRef<SearchBoxRef>(null);
    const [searchResults, setSearchResults] = useState<SafeswimLocation[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const onResultClick = (location: SafeswimLocation) => {
        hideModal(ModalActionType.SEARCH_MODAL);
        fireTagEvent("search-click", {
            beachSearch: location.name,
            beachName: location.name
        });
        if (map) {
            map.panTo({
                lat: location.lat,
                lng: location.lng
            });
        }
        onLocationClick(location);
    };

    const onClear = () => {
        searchRef.current?.clearSearch();
    };

    const renderSearchResults = () => {
        if (searchResults.length) {
            return searchResults.map((result) => (
                <div
                    key={result.id}
                    className={styles.result}
                    onClick={() => onResultClick(result)}
                >
                    {result.name}{" "}
                    {result.maoriName && <span>({result.maoriName})</span>}
                </div>
            ));
        }

        return (
            <div className={styles.no_results}>
                Sorry, we don't currently have a location listed that matches
                your search. Try another name or check out{" "}
                <span onClick={onClear}>this list of locations</span>
            </div>
        );
    };

    return (
        <Modal
            isOpen={show}
            onClose={() => hideModal(ModalActionType.SEARCH_MODAL)}
        >
            <div className={styles.content}>
                <h2>Find a beach</h2>
                <SearchBox
                    ref={searchRef}
                    usesDropdown={false}
                    results={searchResults}
                    setResultsCallback={setSearchResults}
                    loadingCallback={setLoading}
                />
                <div className={styles.results_wrapper}>
                    {loading ? (
                        <div className={styles.loader_wrapper}>
                            <PulseLoader size={10} color={"#ccc"} />
                        </div>
                    ) : (
                        renderSearchResults()
                    )}
                </div>
            </div>
        </Modal>
    );
}
