import black from "../../../resources/images/pins/black.png";
import black_Hazard from "../../../resources/images/pins/black_hazard.png";
import black_HazardRedFlag from "../../../resources/images/pins/black_hazard_redflag.png";
import black_HazardSLS from "../../../resources/images/pins/black_hazard_sls.png";
import black_HazardSLSOff from "../../../resources/images/pins/black_hazard_sls_off.png";
import black_RedFlag from "../../../resources/images/pins/black_redflag.png";
import black_SLS from "../../../resources/images/pins/black_sls.png";
import black_SLSOff from "../../../resources/images/pins/black_sls_off.png";
import green from "../../../resources/images/pins/green.png";
import green_Hazard from "../../../resources/images/pins/green_hazard.png";
import green_HazardRedFlag from "../../../resources/images/pins/green_hazard_redflag.png";
import green_HazardSLS from "../../../resources/images/pins/green_hazard_sls.png";
import green_HazardSLSOff from "../../../resources/images/pins/green_hazard_sls_off.png";
import green_RedFlag from "../../../resources/images/pins/green_redflag.png";
import green_SLS from "../../../resources/images/pins/green_sls.png";
import green_SLSOff from "../../../resources/images/pins/green_sls_off.png";
import red from "../../../resources/images/pins/red.png";
import red_Hazard from "../../../resources/images/pins/red_hazard.png";
import red_HazardRedFlag from "../../../resources/images/pins/red_hazard_redflag.png";
import red_HazardSLS from "../../../resources/images/pins/red_hazard_sls.png";
import red_HazardSLSOff from "../../../resources/images/pins/red_hazard_sls_off.png";
import red_RedFlag from "../../../resources/images/pins/red_redflag.png";
import red_SLS from "../../../resources/images/pins/red_sls.png";
import red_SLSOff from "../../../resources/images/pins/red_sls_off.png";
import grey from "../../../resources/images/pins/grey.png";
import grey_Hazard from "../../../resources/images/pins/grey_hazard.png";
import grey_HazardRedFlag from "../../../resources/images/pins/grey_hazard_redflag.png";
import grey_HazardSLS from "../../../resources/images/pins/grey_hazard_sls.png";
import grey_HazardSLSOff from "../../../resources/images/pins/grey_hazard_sls_off.png";
import grey_RedFlag from "../../../resources/images/pins/grey_redflag.png";
import grey_SLS from "../../../resources/images/pins/grey_sls.png";
import grey_SLSOff from "../../../resources/images/pins/grey_sls_off.png";
import freshWaterHazard from "../../../resources/images/pins/hazard_pin.png";
import longTermAlert from "../../../resources/images/pins/permanent.png";

const WaterQuality = {
    black,
    black_Hazard,
    black_HazardRedFlag,
    black_HazardSLS,
    black_HazardSLSOff,
    black_RedFlag,
    black_SLS,
    black_SLSOff,
    green,
    green_Hazard,
    green_HazardRedFlag,
    green_HazardSLS,
    green_HazardSLSOff,
    green_RedFlag,
    green_SLS,
    green_SLSOff,
    red,
    red_Hazard,
    red_HazardRedFlag,
    red_HazardSLS,
    red_HazardSLSOff,
    red_RedFlag,
    red_SLS,
    red_SLSOff,
    grey,
    grey_Hazard,
    grey_HazardRedFlag,
    grey_HazardSLS,
    grey_HazardSLSOff,
    grey_RedFlag,
    grey_SLS,
    grey_SLSOff,
    freshWaterHazard,
    longTermAlert
};

export default WaterQuality;
