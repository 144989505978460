import React from "react";
import { createRoot } from "react-dom/client";
import "./styles/globals.scss";
import App from "./App";
import { Wrapper } from "@googlemaps/react-wrapper";
import { FirebaseProvider } from "./context/firebase";
import { NavigatorProvider } from "./context/navigator";
import { BrowserRouter } from "react-router-dom";
import { MapProvider } from "./context/map";
import { DrawerStateProvider } from "./context/drawer";
import { ModalStateProvider } from "./context/modal";
import { FavouriteBeachProvider } from "./context/favouriteBeaches";
import { ApiProvider } from "./context/api";

declare global {
    interface Window {
        google: typeof google;
        // eslint-disable-next-line
        dataLayer: any[];
    }
}
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <ApiProvider>
            <FirebaseProvider>
                <NavigatorProvider>
                    <MapProvider>
                        <BrowserRouter>
                            <DrawerStateProvider>
                                <ModalStateProvider>
                                    <FavouriteBeachProvider>
                                        <Wrapper
                                            apiKey={
                                                process.env
                                                    .REACT_APP_GOOGLE_MAPS_KEY as string
                                            }
                                            render={(status) => (
                                                <App status={status} />
                                            )}
                                        />
                                        <div id="modal-portal" />
                                    </FavouriteBeachProvider>
                                </ModalStateProvider>
                            </DrawerStateProvider>
                        </BrowserRouter>
                    </MapProvider>
                </NavigatorProvider>
            </FirebaseProvider>
        </ApiProvider>
    </React.StrictMode>
);
