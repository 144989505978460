import React, { PropsWithChildren, ReactElement } from "react";

export default function SearchItem(
    props: PropsWithChildren<Record<string, unknown>>
): ReactElement {
    delete props.activeKey;
    delete props.extraClass;

    return (
        <li {...props} className="search-item">
            {props.children}
        </li>
    );
}
