import styles from "./PatrolCalendar.module.scss";
import InfiniteScroll from "react-infinite-scroller";
import { useEffect, useMemo, useState } from "react";
import Patrol from "../../../../../types/Patrol";
import { getPatrolsByLocation } from "../../../../../api/locations";
import SafeswimLocation from "../../../../../types/SafeswimLocation";
import { format, compareAsc, addDays, isAfter, parseISO } from "date-fns";
import classNames from "classnames";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

type Props = {
    location: SafeswimLocation;
};
const page_size = 365;

export default function PatrolCalendar(props: Props) {
    const { location } = props;
    const [patrols, setPatrols] = useState<Patrol[]>([]);
    const [cursor, setCursor] = useState<string>();

    useEffect(() => {
        (async () => {
            const result = await getPatrolsByLocation(location.id, page_size);
            const { items, cursor } = result;
            setPatrols(items);
            setCursor(cursor);
        })();
    }, []);

    const getMore = async () => {
        const result = await getPatrolsByLocation(
            location.id,
            page_size,
            cursor
        );
        const { items, cursor: nextCursor } = result;
        setCursor(nextCursor);
        setPatrols(patrols.concat(items));
    };

    const locPatrols = useMemo((): { dstr: string; pstr: string }[] => {
        const _locPatrols: { dstr: string; pstr: string }[] = [];

        const sorted = patrols.sort((p1, p2) =>
            compareAsc(new Date(p1.date), new Date(p2.date))
        );
        const today = new Date();
        const last = sorted[sorted.length - 1];
        const lastDay = !last
            ? new Date(today.getFullYear(), 10, 1)
            : new Date(last.date);
        if (isAfter(today, lastDay)) {
            return _locPatrols;
        }
        let day = today;
        while (!isAfter(day, lastDay)) {
            const dstr =
                format(today, "yyyy-MM-dd") === format(day, "yyyy-MM-dd")
                    ? `Today, ${format(day, "EEE d MMM")}`
                    : format(day, "EEE, d MMM");
            const date = format(day, "yyyy-MM-dd");
            const patrolled = patrols.find(
                (p) => format(new Date(p.date), "yyyy-MM-dd") === date
            );
            if (patrolled) {
                const { from, to } = patrolled;
                const dfrom = zonedTimeToUtc(
                    parseISO(`${date}T${from}`),
                    "Pacific/Auckland"
                );
                const dto = zonedTimeToUtc(
                    parseISO(`${date}T${to}`),
                    "Pacific/Auckland"
                );
                const pstr = `On-duty, ${format(
                    utcToZonedTime(dfrom, "Pacific/Auckland"),
                    "haaa"
                )} - ${format(
                    utcToZonedTime(dto, "Pacific/Auckland"),
                    "haaa"
                )}`;
                _locPatrols.push({ dstr, pstr });
            } else {
                const pstr = "Not lifeguarded";
                _locPatrols.push({ dstr, pstr });
            }
            day = addDays(day, 1);
        }
        return _locPatrols;
    }, [patrols]);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h4 className={styles.title}>Lifeguard patrol calendar</h4>
                <div className={styles.thead}>
                    <span>Date</span>
                    <span>Lifeguard patrol schedule</span>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={getMore}
                    hasMore={!!cursor}
                >
                    {locPatrols.map((p, i) => (
                        <div
                            className={
                                i === 0
                                    ? classNames([styles.list, styles.first])
                                    : styles.list
                            }
                            key={`pat_${i}`}
                        >
                            <span>{p.dstr}</span>
                            <span>{p.pstr}</span>
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
        </div>
    );
}
