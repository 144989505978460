export default function RedFlag(props: {
    background?: boolean;
    backgroundColour?: string;
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="48"
            viewBox="0 0 49 48"
            fill="none"
        >
            {props.background && (
                <rect
                    width="49"
                    height="48"
                    rx="6"
                    fill={props.backgroundColour || "#23221F"}
                    fillOpacity="0.3"
                />
            )}
            <path
                d="M13.8451 40.3475C13.2032 41.1087 12.1445 41.1242 11.4823 40.3862C11.4723 40.3746 11.4622 40.363 11.4487 40.3475V38.5895H13.8451V40.3475Z"
                fill="#0B253B"
            />
            <path
                d="M13.8451 39.0724C13.2805 39.7525 11.8655 39.6404 11.4487 39.0724V9H13.8451V39.0724Z"
                fill="white"
            />
            <path
                d="M11.4489 18.3805H36.552V26.5476C36.552 27.2198 36.0075 27.761 35.3387 27.761H11.4489V18.3805Z"
                fill="#E03128"
            />
            <path
                d="M11.4492 9H35.339C36.0112 9 36.5523 9.54448 36.5523 10.2133V18.3805H11.4492V9Z"
                fill="#E03128"
            />
            <path
                opacity="0.07"
                d="M19.2362 9H13.8452V27.7609H19.2362V9Z"
                fill="url(#paint0_linear_6039_234794)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_6039_234794"
                    x1="13.8612"
                    y1="16.3901"
                    x2="19.2522"
                    y2="16.3901"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset="1" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}
