import { format, isAfter, isBefore, parseISO, set } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import isEmpty from "lodash/isEmpty";
import Patrol from "../types/Patrol";
import SafeswimAlert from "../types/SafeswimAlert";
import SafeswimLocation, { Forecast, TideDTO } from "../types/SafeswimLocation";

export enum WaterQualityLevel {
    UNAVAILABLE = "grey",
    GOOD = "green",
    HIGH_RISK = "red",
    VERY_HIGH_RISK = "black"
}

export function getCurrentPatrolHours(
    location: SafeswimLocation
): Patrol | null | undefined {
    if (!containsTag(location, "sls_patrolled") || !location.patrolHours) {
        return null;
    }

    const now = utcToZonedTime(new Date().toISOString(), "Pacific/Auckland");
    const formattedNow = format(now, "yyyy-MM-dd");

    return location.patrolHours[formattedNow];
}

export function isCurrentlyPatrolled(location: SafeswimLocation): boolean {
    if (!location.patrolHours) {
        return false;
    }

    const now = new Date();
    const formattedNow = format(now, "yyyy-MM-dd");

    const patrol = location.patrolHours[formattedNow];

    if (!patrol) {
        return false;
    }

    // const fromHourSplit = patrol.from.split(":");
    // const from = set(
    //     utcToZonedTime(parseISO(patrol.date), "Pacific/Auckland"),
    //     {
    //         hours: Number(fromHourSplit[0]),
    //         minutes: Number(fromHourSplit[1])
    //     }
    // );

    const toHourSplit = patrol.to.split(":");
    const to = set(utcToZonedTime(parseISO(patrol.date), "Pacific/Auckland"), {
        hours: Number(toHourSplit[0]),
        minutes: Number(toHourSplit[1])
    });

    const noLaterThanPatrolHours = !isAfter(now, to);

    return !!patrol.flagOn && noLaterThanPatrolHours;
}

export function containsTag(
    location: SafeswimLocation | undefined,
    tag: string
): boolean {
    if (!location || !location.tags || !location.tags.length) {
        return false;
    }

    return location.tags.includes(tag);
}

export function getWaterQualityLevelIgnoreAlerts(
    location: SafeswimLocation,
    value: number
): WaterQualityLevel {
    if (
        location.isPermanent &&
        location.permanentWaterQuality !== null &&
        location.permanentWaterQuality !== undefined
    ) {
        value = location.permanentWaterQuality;
    }

    return getWaterQualityLevel(location, value);
}

export function getCurrentWaterQualityLevel(
    location: SafeswimLocation | undefined
): WaterQualityLevel {
    if (!location) {
        return WaterQualityLevel.UNAVAILABLE;
    }

    let level = location.currentWaterQuality;
    const nowPacific = utcToZonedTime(new Date(), "Pacific/Auckland");

    if (location.alerts && !isEmpty(location.alerts)) {
        const veryHighRiskAlerts = location.alerts.waterQuality
            .filter((a) => {
                return (
                    a.value === "3" &&
                    a.locations.filter((al) => Number(al) === location.id)
                        .length > 0
                );
            })
            .filter(
                (a) =>
                    isAfter(
                        nowPacific,
                        utcToZonedTime(a.from, "Pacific/Auckland")
                    ) &&
                    isBefore(
                        nowPacific,
                        utcToZonedTime(a.to, "Pacific/Auckland")
                    )
            );

        if (veryHighRiskAlerts.length > 0) {
            return WaterQualityLevel.VERY_HIGH_RISK;
        }

        const highRiskAlerts = location.alerts.waterQuality
            .filter((a) => {
                return (
                    a.value === "2" &&
                    a.locations.filter((al) => Number(al) === location.id)
                        .length > 0
                );
            })
            .filter(
                (a) =>
                    isAfter(
                        nowPacific,
                        utcToZonedTime(a.from, "Pacific/Auckland")
                    ) &&
                    isBefore(
                        nowPacific,
                        utcToZonedTime(a.to, "Pacific/Auckland")
                    )
            );

        if (highRiskAlerts.length > 0) {
            return WaterQualityLevel.HIGH_RISK;
        }
    }

    if (
        location.isPermanent &&
        location.permanentWaterQuality !== null &&
        location.permanentWaterQuality !== undefined
    ) {
        level = location.permanentWaterQuality;
    }

    return getWaterQualityLevel(location, level);
}

function getWaterQualityLevel(
    location: SafeswimLocation,
    value: number | null | undefined
): WaterQualityLevel {
    const isCriteria = containsTag(location, "criteria_model");
    const isFreshWater = containsTag(location, "fresh_water");

    if (value === null || value === undefined) {
        return WaterQualityLevel.UNAVAILABLE;
    }

    if (!isCriteria && !isFreshWater) {
        if (value >= 0 && value <= 280) {
            return WaterQualityLevel.GOOD;
        } else if (value > 280) {
            return WaterQualityLevel.HIGH_RISK;
        }
    }

    if (isCriteria) {
        if (value >= 0 && value <= 16) {
            return WaterQualityLevel.GOOD;
        } else if (value > 16) {
            return WaterQualityLevel.HIGH_RISK;
        }
    }

    if (isFreshWater) {
        if (value >= 0 && value <= 550) {
            return WaterQualityLevel.GOOD;
        } else if (value > 550) {
            return WaterQualityLevel.HIGH_RISK;
        }
    }

    return WaterQualityLevel.UNAVAILABLE;
}

export function getWaterQualityTextByLevel(waterQualityLevel: string): {
    short: string;
    long: string;
} {
    switch (waterQualityLevel) {
        case "black":
            return {
                short: "Very high risk",
                long: "Very high risk of illness from swimming"
            };
        case "red":
            return {
                short: "High risk",
                long: "High risk of illness from swimming"
            };
        case "green":
            return {
                short: "Low risk",
                long: "Low risk of illness from swimming"
            };
        case "grey":
        default:
            return {
                short: "Unavailable",
                long: "Unavailable"
            };
    }
}
export function getFavoriteWaterQualityTextByLevel(
    waterQualityLevel: string
): string {
    switch (waterQualityLevel) {
        case "black":
            return "Do not swim (Wastewater Overflow)";
        case "red":
            return "Swimming not advised (High risk)";
        case "green":
            return "Good water quality";
        case "grey":
        default:
            return "Unavailable";
    }
}

export function getFavoriteWaterQualityText(
    location: SafeswimLocation,
    waterQualityLevel: string
): string {
    const noWaterQuality = containsTag(location, "no_water_quality");
    const isFreshWaterHazard = containsTag(location, "fresh_water_hazard");

    if (noWaterQuality) {
        return "No real-time water quality information available at this location";
    }

    if (isFreshWaterHazard) {
        return "This location does not have water quality testing";
    }

    return getFavoriteWaterQualityTextByLevel(waterQualityLevel);
}

export function getWaterQualityText(location: SafeswimLocation): {
    short: string;
    long: string;
} {
    const noWaterQuality = containsTag(location, "no_water_quality");
    const waterQualityLevel = getCurrentWaterQualityLevel(location);
    const isFreshWaterHazard = containsTag(location, "fresh_water_hazard");

    if (noWaterQuality) {
        return {
            short: "Unavailable",
            long: "No real-time water quality information available at this location"
        };
    }

    if (isFreshWaterHazard) {
        return {
            short: "Unavailable",
            long: "This location does not have water quality testing"
        };
    }

    return getWaterQualityTextByLevel(waterQualityLevel);
}

export const getNextTides = (
    forecast?: Forecast | null
): { high: string; low: string } => {
    const tidesToday: TideDTO[] = forecast?.today.tides || [];
    const tidesTomorrow: TideDTO[] = forecast?.tomorrow.tides || [];
    const nowPacific = utcToZonedTime(new Date(), "Pacific/Auckland");

    const high = tidesToday
        .filter(
            (t) =>
                t.level === "HIGH" &&
                isBefore(new Date(), utcToZonedTime(t.date, "Pacific/Auckland"))
        )
        .map((t) => {
            return utcToZonedTime(t.date, "Pacific/Auckland");
        })
        .concat(
            tidesTomorrow
                .filter((t) => t.level === "HIGH")
                .map((t) => {
                    return utcToZonedTime(t.date, "Pacific/Auckland");
                })
        );

    const low = tidesToday
        .filter(
            (t) =>
                t.level === "LOW" &&
                isBefore(nowPacific, utcToZonedTime(t.date, "Pacific/Auckland"))
        )
        .map((t) => {
            return utcToZonedTime(t.date, "Pacific/Auckland");
        })
        .concat(
            tidesTomorrow
                .filter((t) => t.level === "LOW")
                .map((t) => {
                    return utcToZonedTime(t.date, "Pacific/Auckland");
                })
        );
    if (!high.length || !low.length) {
        return {
            high: "Unavailable",
            low: "Unavailable"
        };
    }
    return {
        high: format(high[0], "h:mm a"),
        low: format(low[0], "h:mm a")
    };
};

export function getAlertForecast(
    alert: SafeswimAlert,
    forecastDate: Date,
    hourOfDay: number,
    currentLevel?: WaterQualityLevel
) {
    let level = currentLevel || WaterQualityLevel.UNAVAILABLE;

    const alertStart = utcToZonedTime(
        set(new Date(alert.from), {
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        }),
        "Pacific/Auckland"
    );
    const alertEnd = utcToZonedTime(
        set(new Date(alert.to), {
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        }),
        "Pacific/Auckland"
    );
    const forecastNow = set(utcToZonedTime(forecastDate, "Pacific/Auckland"), {
        hours: hourOfDay,
        minutes: 0,
        seconds: 0,
        milliseconds: 0
    });

    if (
        forecastNow.getTime() >= alertStart.getTime() &&
        forecastNow.getTime() <= alertEnd.getTime()
    ) {
        level =
            Number(alert.value) === 2
                ? WaterQualityLevel.HIGH_RISK
                : WaterQualityLevel.VERY_HIGH_RISK;
    }

    return level;
}
