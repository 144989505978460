import React from "react";
import Slider from "react-slick";
import SafeswimLocation from "../../../../types/SafeswimLocation";
import Features from "./features/Features";
import styles from "./LocationDetails.module.scss";
import HazardCard from "../../../cards/hazardCard/HazardCard";
import { containsTag } from "../../../../utils/location";

const IMAGE_QUALITY_MOBILE = "=s600";

const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    swipeToSlide: true,
    className: styles.image_container
};

type Props = {
    location?: SafeswimLocation;
    isMobile?: boolean;
};

export default function LocationDetails(props: Props) {
    const { location, isMobile } = props;
    const images = [...(location?.images || [])];
    const freshWaterHazard = containsTag(location, "fresh_water_hazard");
    const freshWater = containsTag(location, "fresh_water");

    if (location?.featuredImage) {
        images.unshift(location.featuredImage);
    }

    return (
        <div className={styles.location_details_container}>
            <div className={styles.content}>
                <h4>
                    {freshWater || freshWaterHazard ? "Location" : "Beach"} Info
                </h4>
                {location?.name === "Piha Beach" && (
                    <>
                        <p className={styles.video_header}>
                            Get to know the hazards
                        </p>
                        <p className={styles.video_subhead}>
                            Piha is one of Aotearoa’s most popular beaches, but
                            can also be one of the most dangerous. Before you
                            head out, take a quick look at our safety video to
                            help you stay safe at the beach.
                        </p>
                        <ol className={styles.list}>
                            <li>
                                <iframe
                                    title={"Spotting the Signs"}
                                    className={styles.iframe}
                                    src="https://www.youtube.com/embed/NpB_-Wq4OvE?si=ISuhfbWHl7HrUTWA"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </li>
                        </ol>
                    </>
                )}
                {location?.description && (
                    <p
                        className={styles.large}
                        dangerouslySetInnerHTML={{
                            __html: location.description
                        }}
                    />
                )}
            </div>
            {images.length > 0 && (
                <div className={styles.slider_container}>
                    <h6 className={styles.title}>Images</h6>
                    <div className={styles.divider} />
                    <Slider {...sliderSettings}>
                        {images.map((image, i) => {
                            if (isMobile) {
                                return (
                                    <img
                                        key={i}
                                        src={image + IMAGE_QUALITY_MOBILE}
                                        alt="slider"
                                        className={styles.slider_image}
                                    />
                                );
                            }
                            return (
                                <img
                                    key={i}
                                    src={image}
                                    alt="slider"
                                    className={styles.slider_image}
                                />
                            );
                        })}
                    </Slider>
                </div>
            )}
            <HazardCard location={location} dark />

            <div className={styles.content}>
                <Features features={location?.features} />
            </div>
        </div>
    );
}
