import React from "react";

export default function Close(props: React.HTMLAttributes<SVGElement>) {
    return (
        <svg
            {...props}
            data-name="Icon / 16px / close"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M18.75 5.25L5.25 18.75"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.75 18.75L5.25 5.25"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
