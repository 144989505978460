import styles from "./AboutDrawer.module.scss";
import { DrawerActionType, useDrawerContext } from "../../../../context/drawer";
import Drawer from "../../Drawer";
import { HomeButton } from "../../../buttons/drawerButton/DrawerButton";
import classNames from "classnames";
import Button from "../../../buttons/button/Button";
import ChevronRight from "../../../images/chevrons/ChevronRight";
import PartnerLogos from "../../../partners/PartnerLogos";
import Footer from "../../../footer/Footer";
import { BackButtonDrawer } from "../../BackButtonDrawer/BackButtonDrawer";

export default function AboutDrawer() {
    const { hideAll, hide, show, aboutDrawerOpen } = useDrawerContext();

    const closeAboutDrawer = () => hideAll();

    const onLearnMoreClick = (
        drawer:
            | DrawerActionType.VIDEOS_DRAWER
            | DrawerActionType.WATER_SAFETY_101_DRAWER_RIGHT
    ) => {
        show(drawer);
        hide(DrawerActionType.ABOUT_DRAWER);
    };
    return (
        <Drawer
            id={DrawerActionType.ABOUT_DRAWER}
            isOpen={!!aboutDrawerOpen}
            position={"right"}
        >
            <div className={styles.container}>
                <HomeButton onClick={closeAboutDrawer} />
                <h2>About Safeswim</h2>
                <p className={styles.large}>
                    Safeswim provides real-time advice on the level of risk
                    associated with swimming at specific locations. Safeswim
                    allows beach users to make informed decisions on when and
                    where to swim.
                </p>
                <div className={styles.videoFrame}>
                    <iframe
                        title={"About Safeswim"}
                        className={styles.iframe}
                        src="https://www.youtube.com/embed/EuacqRMPwB0"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
                <p className={styles.p_heading}>A deeper dive into Safeswim</p>
                {/* <p className={styles.p_subheading}>
                    The driving force of the Safeswim programme is its team of
                    full-time water quality samplers. The team travel to 15-20
                    locations a day to collect water samples at popular swimming
                    destinations and transport them to the laboratory.
                </p> */}
                <div className={styles.divider} />
                <p>
                    Safeswim is a collaboration between Auckland Council, Surf
                    Life Saving New Zealand, Watercare, Northland Regional
                    Council, Auckland Regional Public Health Service and
                    Drowning Prevention Auckland.
                </p>
                <p>
                    Safeswim combines real-time monitoring of the wastewater and
                    stormwater networks with predictive models, to provide
                    forecasts of water quality at swimming sites.
                </p>
                <p>
                    Safeswim’s water quality predictions consider rainfall,
                    tide, wind and sunlight. Real-time data from rain gauges are
                    fed into the system to ensure the current prediction
                    reflects actual rainfall.
                </p>
                <p>
                    Water quality predictions are overridden if sensors detect
                    discharges from the wastewater network, or operational staff
                    report overflows that are likely to cause a public health
                    risk.
                </p>
                <p>
                    Surf Life Saving New Zealand (SLSNZ) is the beach and
                    coastal safety, drowning prevention and rescue authority in
                    Aotearoa, New Zealand. SLSNZ uses Safeswim to provide advice
                    on beach, coastal and other safety hazards. All SLSNZ
                    lifeguarded beaches are shown on Safeswim, along with the
                    dates and times surf lifeguards are on patrol. This is why
                    you should always check Safeswim to find a lifeguarded beach
                    and swim between the red and yellow flags. While on patrol,
                    surf lifeguards can upload public safety warnings, alerting
                    the public to hazards such as dangerous wave or wind
                    conditions, rip currents, jellyfish or shark sightings. When
                    conditions are too dangerous to enter the water, surf
                    lifeguards will raise a red flag on the beach. At the same
                    time, the red and yellow flag on Safeswim will change to
                    red. Under an agreement with NEMA, SLSNZ can also upload
                    tsunami warnings to alert the public through Safeswim.
                </p>
                <p>
                    Safeswim is overseen by an independent panel of national and
                    international public health and water quality experts.
                </p>
                <p>
                    Safeswim follows international and national guidelines
                    designed to provide water quality advice for swimmers close
                    to shore. Activities where people are unlikely to put their
                    head under water – like stand-up paddle boarding, boating
                    and kayaking – or swimming in deep water offshore are less
                    likely to lead to illness.
                </p>
                <div className={styles.full_divider} />
                <div
                    className={classNames(
                        styles.section,
                        styles.safety_information
                    )}
                >
                    <h5 aria-level={2}>Find out more</h5>
                    <p>
                        Click here for more information on the science behind
                        Safeswim.
                    </p>
                    <Button
                        className={styles.button}
                        icon={<ChevronRight />}
                        onClick={() =>
                            onLearnMoreClick(DrawerActionType.VIDEOS_DRAWER)
                        }
                        variant="filled"
                        size="sm"
                    >
                        The science behind Safeswim
                    </Button>
                    <p className={styles.click_para}>
                        Click here for water safety advice and information
                    </p>
                    <Button
                        className={styles.button}
                        icon={<ChevronRight />}
                        onClick={() =>
                            onLearnMoreClick(
                                DrawerActionType.WATER_SAFETY_101_DRAWER_RIGHT
                            )
                        }
                        variant="filled"
                        size="sm"
                    >
                        Beach safety
                    </Button>
                </div>

                <div
                    className={classNames(styles.section, styles.our_partners)}
                >
                    <h5 aria-level={2}>Our Partners</h5>
                    <p>
                        Safeswim is a collaboration between the following
                        organisations
                    </p>
                    <PartnerLogos />
                </div>
            </div>
            <div className={classNames(styles.section, styles.footer)}>
                <Footer />
            </div>
            <div className={styles.disclaimer}>
                <h3 className={styles.strapline}>Disclaimer</h3>
                <p>
                    The information provided on Safeswim comes from a variety of
                    sources. While Auckland Council and the Safewim Partners
                    endeavour to ensure accuracy and timeliness, inaccuracies or
                    delays in data updates may occasionally occur due to errors
                    in source data, technical issues, or unforeseen
                    circumstances. Auckland Council and the Safeswim Partners
                    cannot guarantee that the information presented on Safeswim
                    is accurate, complete, or current. Users are encouraged to
                    employ their judgement.
                </p>
            </div>
            <BackButtonDrawer
                noLine
                noMarginTop
                onClick={() => {
                    hide(DrawerActionType.MENU_DRAWER);
                    hide(DrawerActionType.ABOUT_DRAWER);
                }}
            />
            {/* <img
                src="/creative-images/GENERIC%20CENTRAL.svg"
                className={styles.bottom_img}
            /> */}
        </Drawer>
    );
}
