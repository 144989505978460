import { useRef, useEffect, PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import useMountTransition from "../../hooks/useMountTransition";
import styles from "./Drawer.module.scss";
import Loading from "../loading/Loading";
import { DrawerActionType } from "../../context/drawer";
import { AnimatePresence, motion } from "framer-motion";

function createPortalRoot(id: string) {
    const drawerRoot = document.createElement("div");
    drawerRoot.setAttribute("id", id);

    return drawerRoot;
}

type Props = {
    id: string;
    position: "left" | "right" | "location";
    isOpen: boolean;
    className?: string;
    secondary?: boolean;
    loading?: boolean;
    bodyClassName?: string;
    noOverflow?: boolean;
};

const Drawer = ({
    isOpen,
    children,
    className,
    bodyClassName,
    position,
    secondary,
    id,
    noOverflow,
    loading
}: PropsWithChildren<Props>) => {
    const bodyRef = useRef(document.querySelector("body"));
    const portalRootRef = useRef(
        document.getElementById(id) || createPortalRoot(id)
    );
    const isTransitioning = useMountTransition(isOpen, 300);
    const isMenuDrawer = id === DrawerActionType.MENU_DRAWER;
    const isMenuSubdrawer =
        id === DrawerActionType.CONTACT_DRAWER ||
        id === DrawerActionType.ABOUT_DRAWER ||
        id === DrawerActionType.FAQ_DRAWER ||
        id === DrawerActionType.FAVOURITE_BEACH_DRAWER ||
        id === DrawerActionType.FACILITY_SEARCH_DRAWER ||
        id === DrawerActionType.PROFILE_DRAWER ||
        id === DrawerActionType.SIGNUP_DRAWER ||
        id === DrawerActionType.FORGOT_PASSWORD_DRAWER ||
        id === DrawerActionType.LOGIN_DRAWER ||
        id === DrawerActionType.VIDEOS_DRAWER ||
        id === DrawerActionType.WATER_SAFETY_101_DRAWER_RIGHT ||
        id === DrawerActionType.POLICY_DRAWER;

    useEffect(() => {
        bodyRef.current?.appendChild(portalRootRef.current);
        return () => {
            portalRootRef.current.remove();
        };
    }, []);

    if (!isTransitioning && !isOpen) {
        return null;
    }

    return createPortal(
        <div
            aria-hidden={!isOpen}
            className={classNames(
                styles.drawer_container,
                {
                    [styles.open]: isOpen,
                    [styles.in]: isTransitioning
                },
                className
            )}
        >
            {position === "location" && (
                <AnimatePresence>
                    <motion.div
                        transition={{
                            ease: "easeIn",
                            duration: 0.25
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.25 }}
                        exit={{ opacity: 0 }}
                        className={styles.map_cover}
                    />
                </AnimatePresence>
            )}
            <div
                className={classNames(
                    styles.drawer,
                    {
                        [styles.menu_drawer]: isMenuDrawer,
                        [styles.menu_subdrawer]: isMenuSubdrawer,
                        [styles.secondary]: secondary,
                        [styles.left]: position === "left",
                        [styles.location_info]: position === "location",
                        [styles.right]: position === "right",
                        [styles.overflow]: noOverflow
                    },
                    bodyClassName
                )}
                role="dialog"
            >
                {loading ? (
                    <div className={styles.loader}>
                        <Loading />
                    </div>
                ) : (
                    children
                )}
            </div>
        </div>,
        portalRootRef.current
    );
};

export default Drawer;
