import styles from "./HazardCard.module.scss";
import SafeswimLocation, { Hazard } from "../../../types/SafeswimLocation";
import React, { useEffect, useState } from "react";
import generic from "../../../resources/images/hazards/generic_hazard.svg";
import { getLocationHazards } from "../../../api/locations";
import classNames from "classnames";

type HazardCardProps = {
    location?: SafeswimLocation;
    dark?: boolean;
};

export default function HazardCard({ location, dark }: HazardCardProps) {
    const [loading, setLoading] = useState(true);
    const [hazards, setHazards] = useState<Hazard[]>([]);

    useEffect(() => {
        if (!location) {
            setLoading(false);
            return;
        }
        (async () => {
            setLoading(true);
            const hazards = await getLocationHazards(location.id);
            setHazards(hazards);
            setLoading(false);
        })();
    }, []);

    if (
        !location ||
        !location.hazards ||
        !location.hazards.length ||
        !hazards ||
        loading
    ) {
        return null;
    }

    return (
        <div
            className={classNames(styles.hazard_card, { [styles.dark]: dark })}
        >
            <div className={styles.title}>Hazards</div>
            <div className={styles.divider} />
            <div className={styles.hazard_list}>
                {hazards.map((h) => {
                    const hazardDetails = h;

                    if (!h.icon) {
                        h.icon = generic;
                    }

                    return (
                        <div key={h.id} className={styles.hazard}>
                            <img
                                src={hazardDetails.icon || ""}
                                alt={hazardDetails.title}
                            />
                            <span>{hazardDetails.title}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
