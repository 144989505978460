import styles from "./SearchButton.module.scss";
import SearchIcon from "../../images/icons/SearchIcon";
import classNames from "classnames";
import { ModalActionType, useModalContext } from "../../../context/modal";

type Props = {
    className?: string;
};

export default function SearchButton(props: Props) {
    const { showModal } = useModalContext();

    return (
        <div
            className={classNames(styles.container, props.className)}
            onClick={() => showModal(ModalActionType.SEARCH_MODAL)}
        >
            <span>Find a beach</span>
            <SearchIcon />
        </div>
    );
}
