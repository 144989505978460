import React from "react";
import styles from "./Footer.module.scss";
import { format } from "date-fns";

export default function Footer({ hidePolicy }: { hidePolicy?: boolean }) {
    return (
        <footer className={styles.footer_container}>
            <div className={styles.legal_links}>
                <a
                    className={styles.border_link}
                    href="http://www.aucklandcouncil.govt.nz/EN/Pages/termsandconditions.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Terms and conditions
                </a>
                {!hidePolicy && (
                    <a
                        className={styles.border_link}
                        href="http://www.aucklandcouncil.govt.nz/EN/Pages/websiteprivacypolicy.aspx"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy policy
                    </a>
                )}
            </div>

            <div className={styles.copyright}>
                &copy; Safeswim {format(new Date(), "yyyy")}
            </div>

            <div className={styles.credit}>
                <a
                    className={styles.border_link}
                    href="https://daylightgroup.nz"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Powered by Daylight
                </a>
            </div>
        </footer>
    );
}
