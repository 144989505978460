export const TideOut = () => (
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="48" height="48" rx="5.33333" fill="#0073BD" />
        <path
            d="M24 21.5288V14.7294"
            stroke="white"
            strokeWidth="1.1868"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.2188 17.511L24.0003 14.7294L26.7819 17.511"
            stroke="white"
            strokeWidth="1.1868"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M25.7236 27.3087C26.3269 28.0541 27.0882 28.9944 28.5917 28.9944H28.6097C30.1131 28.9944 30.8744 28.0541 31.4777 27.3087C31.7802 26.935 32.0242 26.6484 32.2886 26.4542C32.5504 26.2619 32.833 26.1599 33.2145 26.1599C33.61 26.1599 33.9398 25.8406 33.9398 25.4523C33.9398 25.064 33.61 24.7447 33.2145 24.7447C31.7021 24.7447 30.9408 25.685 30.3375 26.4304C30.035 26.8041 29.791 27.0908 29.5266 27.2849C29.2647 27.4773 28.9821 27.5792 28.6007 27.5792C28.2192 27.5792 27.9366 27.4773 27.6747 27.2849C27.4103 27.0908 27.1663 26.8041 26.8639 26.4304C26.2604 25.6849 25.4902 24.7447 23.9868 24.7447C22.4835 24.7447 21.7222 25.6849 21.1189 26.4303C20.8118 26.8041 20.5678 27.0908 20.3045 27.285C20.0438 27.4773 19.7634 27.5792 19.382 27.5792C19.0006 27.5792 18.7203 27.4773 18.4607 27.2851C18.1986 27.0909 17.9568 26.8042 17.6542 26.4304C17.0509 25.685 16.2897 24.7447 14.7862 24.7447C14.3907 24.7447 14.0609 25.064 14.0609 25.4523C14.0609 25.8406 14.3907 26.1599 14.7862 26.1599C15.1676 26.1599 15.4479 26.2618 15.7075 26.4541C15.9697 26.6482 16.2114 26.9349 16.514 27.3087C17.1173 28.0541 17.8786 28.9944 19.382 28.9944C20.8854 28.9944 21.6467 28.0542 22.2499 27.3089C22.557 26.935 22.801 26.6484 23.0644 26.4541C23.3251 26.2618 23.6054 26.1599 23.9868 26.1599C24.3683 26.1599 24.6509 26.2619 24.9128 26.4542C25.1772 26.6484 25.4212 26.935 25.7236 27.3087Z"
            fill="white"
            stroke="white"
            strokeWidth="0.0989"
        />
        <path
            d="M25.7236 33.1796C26.3269 33.9249 27.0882 34.8653 28.5917 34.8653H28.6097C30.1131 34.8653 30.8744 33.9249 31.4777 33.1796C31.7802 32.8059 32.0242 32.5192 32.2886 32.325C32.5504 32.1327 32.833 32.0308 33.2145 32.0308C33.61 32.0308 33.9398 31.7115 33.9398 31.3232C33.9398 30.9348 33.61 30.6155 33.2145 30.6155C31.7021 30.6155 30.9408 31.5558 30.3375 32.3012C30.035 32.6749 29.791 32.9616 29.5266 33.1558C29.2647 33.3481 28.9821 33.4501 28.6007 33.4501C28.2192 33.4501 27.9366 33.3481 27.6747 33.1558C27.4103 32.9616 27.1663 32.6749 26.8639 32.3012C26.2604 31.5557 25.4902 30.6155 23.9868 30.6155C22.4835 30.6155 21.7222 31.5558 21.1189 32.3011C20.8118 32.675 20.5678 32.9616 20.3045 33.1559C20.0438 33.3481 19.7634 33.4501 19.382 33.4501C19.0006 33.4501 18.7203 33.3481 18.4607 33.1559C18.1986 32.9617 17.9568 32.6751 17.6542 32.3012C17.0509 31.5559 16.2897 30.6155 14.7862 30.6155C14.3907 30.6155 14.0609 30.9348 14.0609 31.3232C14.0609 31.7115 14.3907 32.0308 14.7862 32.0308C15.1676 32.0308 15.4479 32.1327 15.7075 32.3249C15.9697 32.5191 16.2114 32.8057 16.514 33.1796C17.1173 33.9249 17.8786 34.8653 19.382 34.8653C20.8854 34.8653 21.6467 33.925 22.2499 33.1797C22.557 32.8059 22.801 32.5192 23.0644 32.325C23.3251 32.1327 23.6054 32.0308 23.9868 32.0308C24.3683 32.0308 24.6509 32.1327 24.9128 32.325C25.1772 32.5192 25.4212 32.8059 25.7236 33.1796Z"
            fill="white"
            stroke="white"
            strokeWidth="0.0989"
        />
    </svg>
);

export const TideIn = () => (
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="48" height="48" rx="5.33333" fill="#0073BD" />
        <path
            d="M24 14.7294L24 21.5288"
            stroke="white"
            strokeWidth="1.1868"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M26.7816 18.7473L24 21.5288L21.2184 18.7473"
            stroke="white"
            strokeWidth="1.1868"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M25.7233 27.3087C26.3266 28.0541 27.0878 28.9944 28.5913 28.9944H28.6093C30.1128 28.9944 30.874 28.0541 31.4773 27.3087C31.7798 26.935 32.0238 26.6484 32.2882 26.4542C32.5501 26.2619 32.8327 26.1599 33.2141 26.1599C33.6097 26.1599 33.9394 25.8406 33.9394 25.4523C33.9394 25.064 33.6097 24.7447 33.2141 24.7447C31.7017 24.7447 30.9405 25.685 30.3371 26.4304C30.0346 26.8041 29.7906 27.0908 29.5262 27.2849C29.2644 27.4773 28.9818 27.5792 28.6003 27.5792C28.2189 27.5792 27.9362 27.4773 27.6744 27.2849C27.41 27.0908 27.166 26.8041 26.8635 26.4304C26.2601 25.6849 25.4898 24.7447 23.9865 24.7447C22.4831 24.7447 21.7218 25.6849 21.1186 26.4303C20.8115 26.8041 20.5675 27.0908 20.3041 27.285C20.0434 27.4773 19.7631 27.5792 19.3817 27.5792C19.0002 27.5792 18.7199 27.4773 18.4604 27.2851C18.1982 27.0909 17.9565 26.8042 17.6539 26.4304C17.0506 25.685 16.2893 24.7447 14.7859 24.7447C14.3903 24.7447 14.0605 25.064 14.0605 25.4523C14.0605 25.8406 14.3903 26.1599 14.7859 26.1599C15.1673 26.1599 15.4476 26.2618 15.7071 26.4541C15.9693 26.6482 16.2111 26.9349 16.5136 27.3087C17.1169 28.0541 17.8782 28.9944 19.3817 28.9944C20.885 28.9944 21.6463 28.0542 22.2496 27.3089C22.5567 26.935 22.8006 26.6484 23.064 26.4541C23.3247 26.2618 23.6051 26.1599 23.9865 26.1599C24.3679 26.1599 24.6506 26.2619 24.9124 26.4542C25.1768 26.6484 25.4208 26.935 25.7233 27.3087Z"
            fill="white"
            stroke="white"
            strokeWidth="0.0989"
        />
        <path
            d="M25.7233 33.1796C26.3266 33.925 27.0878 34.8653 28.5913 34.8653H28.6093C30.1128 34.8653 30.874 33.925 31.4773 33.1796C31.7798 32.8059 32.0238 32.5192 32.2882 32.3251C32.5501 32.1327 32.8327 32.0308 33.2141 32.0308C33.6097 32.0308 33.9394 31.7115 33.9394 31.3232C33.9394 30.9348 33.6097 30.6156 33.2141 30.6156C31.7017 30.6156 30.9405 31.5558 30.3371 32.3013C30.0346 32.675 29.7906 32.9616 29.5262 33.1558C29.2644 33.3481 28.9818 33.4501 28.6003 33.4501C28.2189 33.4501 27.9362 33.3481 27.6744 33.1558C27.41 32.9616 27.166 32.675 26.8635 32.3013C26.2601 31.5557 25.4898 30.6156 23.9865 30.6156C22.4831 30.6156 21.7218 31.5558 21.1186 32.3011C20.8115 32.675 20.5675 32.9616 20.3041 33.1559C20.0434 33.3482 19.7631 33.4501 19.3817 33.4501C19.0002 33.4501 18.7199 33.3482 18.4604 33.1559C18.1982 32.9618 17.9565 32.6751 17.6539 32.3013C17.0506 31.5559 16.2893 30.6156 14.7859 30.6156C14.3903 30.6156 14.0605 30.9348 14.0605 31.3232C14.0605 31.7115 14.3903 32.0308 14.7859 32.0308C15.1673 32.0308 15.4476 32.1327 15.7071 32.3249C15.9693 32.5191 16.2111 32.8058 16.5136 33.1796C17.1169 33.925 17.8782 34.8653 19.3817 34.8653C20.885 34.8653 21.6463 33.9251 22.2496 33.1797C22.5567 32.8059 22.8006 32.5192 23.064 32.325C23.3247 32.1327 23.6051 32.0308 23.9865 32.0308C24.3679 32.0308 24.6506 32.1327 24.9124 32.3251C25.1768 32.5192 25.4208 32.8059 25.7233 33.1796Z"
            fill="white"
            stroke="white"
            strokeWidth="0.0989"
        />
    </svg>
);

export const TideIcon = (props: { level: "HIGH" | "LOW"; dark?: boolean }) => (
    <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.5"
            width="32"
            height="32"
            rx="5.33333"
            fill={props.dark ? "#1B324F" : "#0073BD"}
            fillOpacity={props.dark ? "1" : "0.7"}
        />
        <path
            opacity={props.level === "HIGH" ? "1" : "0.5"}
            d="M17.9953 11.4324C18.402 11.9348 18.9151 12.5687 19.9286 12.5687H19.9407C20.9542 12.5687 21.4673 11.9348 21.874 11.4324C22.0779 11.1805 22.2424 10.9872 22.4206 10.8563C22.5971 10.7267 22.7876 10.658 23.0447 10.658C23.3114 10.658 23.5337 10.4427 23.5337 10.181C23.5337 9.91919 23.3114 9.70397 23.0447 9.70397C22.0253 9.70397 21.5121 10.3378 21.1054 10.8403C20.9015 11.0922 20.737 11.2854 20.5588 11.4163C20.3823 11.546 20.1918 11.6147 19.9346 11.6147C19.6775 11.6147 19.487 11.546 19.3105 11.4163C19.1323 11.2854 18.9678 11.0922 18.7639 10.8403C18.3571 10.3377 17.8379 9.70397 16.8245 9.70397C15.8112 9.70397 15.298 10.3378 14.8914 10.8402C14.6843 11.0922 14.5199 11.2854 14.3423 11.4164C14.1666 11.546 13.9776 11.6147 13.7205 11.6147C13.4634 11.6147 13.2745 11.546 13.0995 11.4164C12.9228 11.2855 12.7598 11.0923 12.5558 10.8403C12.1492 10.3379 11.636 9.70397 10.6226 9.70397C10.356 9.70397 10.1337 9.91919 10.1337 10.181C10.1337 10.4427 10.356 10.658 10.6226 10.658C10.8797 10.658 11.0686 10.7267 11.2436 10.8562C11.4203 10.9871 11.5833 11.1804 11.7872 11.4324C12.1939 11.9348 12.7071 12.5687 13.7205 12.5687C14.7339 12.5687 15.2471 11.9349 15.6537 11.4325C15.8607 11.1805 16.0252 10.9872 16.2027 10.8563C16.3785 10.7267 16.5675 10.658 16.8245 10.658C17.0817 10.658 17.2722 10.7267 17.4487 10.8563C17.6269 10.9872 17.7914 11.1805 17.9953 11.4324Z"
            fill="white"
            stroke="white"
            strokeWidth="0.0666667"
        />
        <path
            opacity={props.level === "HIGH" ? "1" : "0.5"}
            d="M17.9953 14.8971C18.402 15.3995 18.9151 16.0334 19.9286 16.0334H19.9407C20.9542 16.0334 21.4673 15.3995 21.874 14.8971C22.0779 14.6452 22.2424 14.452 22.4206 14.3211C22.5971 14.1914 22.7876 14.1227 23.0447 14.1227C23.3114 14.1227 23.5337 13.9075 23.5337 13.6457C23.5337 13.384 23.3114 13.1687 23.0447 13.1687C22.0253 13.1687 21.5121 13.8026 21.1054 14.305C20.9015 14.5569 20.737 14.7502 20.5588 14.8811C20.3823 15.0107 20.1918 15.0794 19.9346 15.0794C19.6775 15.0794 19.487 15.0107 19.3105 14.8811C19.1323 14.7502 18.9678 14.5569 18.7639 14.305C18.3571 13.8025 17.8379 13.1687 16.8245 13.1687C15.8112 13.1687 15.298 13.8025 14.8914 14.3049C14.6843 14.5569 14.5199 14.7502 14.3423 14.8811C14.1666 15.0107 13.9776 15.0794 13.7205 15.0794C13.4634 15.0794 13.2745 15.0107 13.0995 14.8812C12.9228 14.7503 12.7598 14.557 12.5558 14.305C12.1492 13.8026 11.636 13.1687 10.6226 13.1687C10.356 13.1687 10.1337 13.384 10.1337 13.6457C10.1337 13.9075 10.356 14.1227 10.6226 14.1227C10.8797 14.1227 11.0686 14.1914 11.2436 14.321C11.4203 14.4519 11.5833 14.6451 11.7872 14.8971C12.1939 15.3995 12.7071 16.0334 13.7205 16.0334C14.7339 16.0334 15.2471 15.3996 15.6537 14.8972C15.8607 14.6452 16.0252 14.452 16.2027 14.321C16.3785 14.1914 16.5675 14.1227 16.8245 14.1227C17.0817 14.1227 17.2722 14.1914 17.4487 14.3211C17.6269 14.452 17.7914 14.6452 17.9953 14.8971Z"
            fill="white"
            stroke="white"
            strokeWidth="0.0666667"
        />
        <path
            opacity={props.level === "HIGH" ? "1" : "0.5"}
            d="M17.9953 18.3618C18.402 18.8642 18.9151 19.4981 19.9286 19.4981H19.9407C20.9542 19.4981 21.4673 18.8642 21.874 18.3618C22.0779 18.1099 22.2424 17.9166 22.4206 17.7858C22.5971 17.6561 22.7876 17.5874 23.0447 17.5874C23.3114 17.5874 23.5337 17.3722 23.5337 17.1104C23.5337 16.8486 23.3114 16.6334 23.0447 16.6334C22.0253 16.6334 21.5121 17.2672 21.1054 17.7697C20.9015 18.0216 20.737 18.2149 20.5588 18.3458C20.3823 18.4754 20.1918 18.5441 19.9346 18.5441C19.6775 18.5441 19.487 18.4754 19.3105 18.3458C19.1323 18.2149 18.9678 18.0216 18.7639 17.7697C18.3571 17.2672 17.8379 16.6334 16.8245 16.6334C15.8112 16.6334 15.298 17.2672 14.8914 17.7696C14.6843 18.0216 14.5199 18.2149 14.3423 18.3458C14.1666 18.4754 13.9776 18.5441 13.7205 18.5441C13.4634 18.5441 13.2745 18.4754 13.0995 18.3458C12.9228 18.215 12.7598 18.0217 12.5558 17.7697C12.1492 17.2673 11.636 16.6334 10.6226 16.6334C10.356 16.6334 10.1337 16.8486 10.1337 17.1104C10.1337 17.3722 10.356 17.5874 10.6226 17.5874C10.8797 17.5874 11.0686 17.6561 11.2436 17.7857C11.4203 17.9166 11.5833 18.1098 11.7872 18.3618C12.1939 18.8642 12.7071 19.4981 13.7205 19.4981C14.7339 19.4981 15.2471 18.8643 15.6537 18.3619C15.8607 18.1099 16.0252 17.9167 16.2027 17.7857C16.3785 17.6561 16.5675 17.5874 16.8245 17.5874C17.0817 17.5874 17.2722 17.6561 17.4487 17.7858C17.6269 17.9166 17.7914 18.1099 17.9953 18.3618Z"
            fill="white"
            stroke="white"
            strokeWidth="0.0666667"
        />
        <path
            d="M17.9953 21.8265C18.402 22.3289 18.9151 22.9628 19.9286 22.9628H19.9407C20.9542 22.9628 21.4673 22.3289 21.874 21.8265C22.0779 21.5746 22.2424 21.3813 22.4206 21.2504C22.5971 21.1208 22.7876 21.0521 23.0447 21.0521C23.3114 21.0521 23.5337 20.8369 23.5337 20.5751C23.5337 20.3133 23.3114 20.0981 23.0447 20.0981C22.0253 20.0981 21.5121 20.7319 21.1054 21.2344C20.9015 21.4863 20.737 21.6795 20.5588 21.8104C20.3823 21.9401 20.1918 22.0088 19.9346 22.0088C19.6775 22.0088 19.487 21.9401 19.3105 21.8104C19.1323 21.6795 18.9678 21.4863 18.7639 21.2344C18.3571 20.7318 17.8379 20.0981 16.8245 20.0981C15.8112 20.0981 15.298 20.7319 14.8914 21.2343C14.6843 21.4863 14.5199 21.6795 14.3423 21.8105C14.1666 21.9401 13.9776 22.0088 13.7205 22.0088C13.4634 22.0088 13.2745 21.9401 13.0995 21.8105C12.9228 21.6796 12.7598 21.4864 12.5558 21.2344C12.1492 20.732 11.636 20.0981 10.6226 20.0981C10.356 20.0981 10.1337 20.3133 10.1337 20.5751C10.1337 20.8369 10.356 21.0521 10.6226 21.0521C10.8797 21.0521 11.0686 21.1208 11.2436 21.2504C11.4203 21.3812 11.5833 21.5745 11.7872 21.8265C12.1939 22.3289 12.7071 22.9628 13.7205 22.9628C14.7339 22.9628 15.2471 22.329 15.6537 21.8266C15.8607 21.5746 16.0252 21.3813 16.2027 21.2504C16.3785 21.1208 16.5675 21.0521 16.8245 21.0521C17.0817 21.0521 17.2722 21.1208 17.4487 21.2504C17.6269 21.3813 17.7914 21.5746 17.9953 21.8265Z"
            fill="white"
            stroke="white"
            strokeWidth="0.0666667"
        />
    </svg>
);
