import { Form, Formik } from "formik";
import { useState } from "react";
import { DrawerActionType, useDrawerContext } from "../../../../context/drawer";
import Button from "../../../buttons/button/Button";
import Loading from "../../../loading/Loading";
import Drawer from "../../Drawer";
import styles from "./AccountDrawers.module.scss";
import * as Yup from "yup";
import FormikInput from "../../../formik/FormikInput";
import { HomeButton } from "../../../buttons/drawerButton/DrawerButton";
import { useFirebase } from "../../../../context/firebase";
import { SignupUserRequest } from "../../../../types/SafeswimUser";
import EyeHide from "../../../../resources/images/random/eye_hide.svg";
import EyeShow from "../../../../resources/images/random/eye_show.svg";
import IconButton from "../../../buttons/iconButton/IconButton";

type FormValue = {
    fullname: string;
    email: string;
    password: string;
};

const signupSchema = Yup.object({
    fullname: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Required")
});

export default function SignUp() {
    const { signUp, authenticating: loading } = useFirebase();
    const { signupDrawerOpen, loginDrawerOpen, hide, hideAll, show } =
        useDrawerContext();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [initValue, setInitValue] = useState<FormValue>({
        fullname: "",
        email: "",
        password: ""
    });

    const handleSubmit = async (values: FormValue) => {
        const { fullname, email, password } = values;
        setInitValue(values);
        const request: SignupUserRequest = {
            email,
            password
        } as SignupUserRequest;
        const names = fullname.split(/ (.*)/);
        if (names.length > 1) {
            request.firstName = names[0];
            request.lastName = names[1];
        } else {
            request.firstName = fullname;
        }
        try {
            await signUp(request);
            hide(DrawerActionType.SIGNUP_DRAWER);
        } catch (error: any) {
            const message = error.response?.data?.message;
            setError(
                `Failed to sign up: ${message ?? "Please contact support"}`
            );
        }
    };

    const closeSignupDrawer = () => hideAll();
    const handleLoginClick = () => {
        if (loginDrawerOpen) {
            closeSignupDrawer();
        } else {
            show(DrawerActionType.LOGIN_DRAWER);
        }
    };

    const handleShowPasswordClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        event.stopPropagation();
        setShowPassword(!showPassword);
    };

    return (
        <Drawer
            id={DrawerActionType.SIGNUP_DRAWER}
            isOpen={!!signupDrawerOpen}
            position={"right"}
        >
            {loading ? (
                <Loading />
            ) : (
                <div className={styles.container}>
                    <HomeButton onClick={closeSignupDrawer} />
                    <div className={styles.content}>
                        <h2>Sign up</h2>
                        <Formik<FormValue>
                            initialValues={initValue}
                            validationSchema={signupSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isValid, isSubmitting }) => {
                                return (
                                    <Form className={styles.form}>
                                        <FormikInput
                                            name={"fullname"}
                                            label={"Name"}
                                            placeholder={"e.g: John Smith"}
                                        />
                                        <FormikInput
                                            name={"email"}
                                            label={"Email"}
                                            placeholder={"e.g: john@gmail.com"}
                                        />
                                        <div className={styles.password}>
                                            <label
                                                className={styles.label}
                                                htmlFor="password"
                                            >
                                                Password
                                            </label>
                                            <IconButton
                                                className={styles.button_group}
                                                icon={
                                                    showPassword
                                                        ? EyeHide
                                                        : EyeShow
                                                }
                                                onClick={
                                                    handleShowPasswordClick
                                                }
                                                text=""
                                                ariaLabel={
                                                    showPassword
                                                        ? "Hide password"
                                                        : "Show password"
                                                }
                                            />
                                        </div>

                                        <FormikInput
                                            name={"password"}
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            placeholder={"********"}
                                        />
                                        {error && (
                                            <div className={styles.error}>
                                                {error}
                                            </div>
                                        )}
                                        <div className={styles.button_centre}>
                                            <Button
                                                className={styles.button}
                                                color={"primary"}
                                                type={"submit"}
                                                size="lg"
                                                disabled={
                                                    isSubmitting ||
                                                    !isValid ||
                                                    loading
                                                }
                                            >
                                                Sign up
                                            </Button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                        <div className={styles.footer_links}>
                            <p>Already have an account?</p>
                            <Button
                                className={styles.button}
                                variant="link"
                                size="md"
                                onClick={handleLoginClick}
                            >
                                Log in
                            </Button>
                        </div>
                        <p className={styles.legal_lines}>
                            By clicking Sign Up, you are agreeing to our{" "}
                            <a
                                className={styles.terms_conditions}
                                href={
                                    "https://www.aucklandcouncil.govt.nz/Pages/terms-and-conditions.aspx"
                                }
                                target={"_blank"}
                                rel={"noopener noreferrer"}
                            >
                                Term and Conditions
                            </a>
                        </p>
                    </div>
                </div>
            )}
        </Drawer>
    );
}
