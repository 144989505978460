import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { getAuth } from "firebase/auth";
import qs from "qs";

const instance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SAFESWIM_API_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    },
    paramsSerializer: (p) => {
        return qs.stringify(p, { arrayFormat: "comma" });
    }
});

instance.interceptors.request.use(async (request) => {
    const { currentUser } = getAuth();
    if (currentUser) {
        const token = await currentUser.getIdToken();
        if (!request.headers) {
            request.headers = {};
        }

        request.headers["Authorization"] = `Bearer ${token}`;
    }
    return request;
});

export default instance;

export async function get<T>(
    url: string,
    params?: Record<string, unknown>,
    config?: AxiosRequestConfig
): Promise<T> {
    const req = await instance.get(url, { ...config, params });
    return req.data;
}

export async function post<T>(
    url: string,
    data?: Record<string, unknown> | string,
    config?: AxiosRequestConfig
): Promise<T> {
    const req = await instance.post(url, data, config);
    return req.data;
}

export async function put<T>(
    url: string,
    data?: Record<string, unknown>,
    config?: AxiosRequestConfig
): Promise<T> {
    const req = await instance.put(url, data, config);
    return req.data;
}

export async function del<T>(
    url: string,
    config?: AxiosRequestConfig
): Promise<T> {
    const req = await instance.delete(url, config);
    return req.data;
}
