export default function WaterTempIcon() {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1041_70230)">
                <mask
                    id="mask0_1041_70230"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="9"
                    y="7"
                    width="18"
                    height="20"
                >
                    <path
                        d="M9.92317 20.6924L9.11548 25.5522L15.577 26.6291H22.577L26.3462 24.4753L26.8847 18.5522L26.3462 8.32142L11.8078 7.78296V9.39834V14.2445L12.3462 15.3214L13.4232 16.3983L13.6924 18.1611L12.3462 20.3013L9.92317 20.6924Z"
                        fill="#D9D9D9"
                    />
                </mask>
                <g mask="url(#mask0_1041_70230)">
                    <path
                        d="M19.2508 12.7219C19.6887 13.263 20.2414 13.9456 21.3328 13.9456H21.3459C22.4373 13.9456 22.9899 13.263 23.4279 12.7219C23.6474 12.4507 23.8246 12.2425 24.0165 12.1016C24.2066 11.962 24.4117 11.888 24.6887 11.888C24.9758 11.888 25.2152 11.6562 25.2152 11.3743C25.2152 11.0924 24.9758 10.8606 24.6887 10.8606C23.5908 10.8606 23.0381 11.5432 22.6001 12.0843C22.3806 12.3556 22.2034 12.5637 22.0115 12.7047C21.8214 12.8443 21.6162 12.9183 21.3393 12.9183C21.0624 12.9183 20.8572 12.8443 20.6672 12.7047C20.4752 12.5637 20.2981 12.3556 20.0785 12.0843C19.6405 11.5431 19.0813 10.8606 17.99 10.8606C16.8987 10.8606 16.346 11.5431 15.9081 12.0842C15.6851 12.3556 15.508 12.5637 15.3168 12.7047C15.1276 12.8443 14.9241 12.9183 14.6472 12.9183C14.3703 12.9183 14.1668 12.8443 13.9784 12.7047C13.7881 12.5638 13.6126 12.3557 13.3929 12.0843C12.955 11.5432 12.4023 10.8606 11.3109 10.8606C11.0238 10.8606 10.7844 11.0924 10.7844 11.3743C10.7844 11.6562 11.0238 11.888 11.3109 11.888C11.5878 11.888 11.7913 11.9619 11.9797 12.1015C12.17 12.2424 12.3455 12.4505 12.5652 12.7219C13.0032 13.263 13.5558 13.9456 14.6472 13.9456C15.7385 13.9456 16.2912 13.2631 16.7291 12.722C16.952 12.4506 17.1291 12.2425 17.3203 12.1015C17.5096 11.9619 17.7131 11.888 17.99 11.888C18.2669 11.888 18.4721 11.962 18.6622 12.1016C18.8541 12.2425 19.0312 12.4507 19.2508 12.7219Z"
                        fill="#1B324F"
                        stroke="#1B324F"
                        strokeWidth="0.0717949"
                    />
                    <path
                        d="M19.2508 16.4531C19.6887 16.9942 20.2414 17.6768 21.3328 17.6768H21.3459C22.4373 17.6768 22.9899 16.9942 23.4279 16.4531C23.6474 16.1819 23.8246 15.9737 24.0165 15.8328C24.2066 15.6932 24.4117 15.6192 24.6887 15.6192C24.9758 15.6192 25.2152 15.3874 25.2152 15.1055C25.2152 14.8236 24.9758 14.5918 24.6887 14.5918C23.5908 14.5918 23.0381 15.2744 22.6001 15.8155C22.3806 16.0868 22.2034 16.2949 22.0115 16.4359C21.8214 16.5755 21.6162 16.6495 21.3393 16.6495C21.0624 16.6495 20.8572 16.5755 20.6672 16.4359C20.4752 16.2949 20.2981 16.0868 20.0785 15.8155C19.6405 15.2743 19.0813 14.5918 17.99 14.5918C16.8987 14.5918 16.346 15.2743 15.9081 15.8154C15.6851 16.0868 15.508 16.2949 15.3168 16.4359C15.1276 16.5755 14.9241 16.6495 14.6472 16.6495C14.3703 16.6495 14.1668 16.5755 13.9784 16.4359C13.7881 16.295 13.6126 16.0869 13.3929 15.8155C12.955 15.2744 12.4023 14.5918 11.3109 14.5918C11.0238 14.5918 10.7844 14.8236 10.7844 15.1055C10.7844 15.3874 11.0238 15.6192 11.3109 15.6192C11.5878 15.6192 11.7913 15.6931 11.9797 15.8327C12.17 15.9736 12.3455 16.1817 12.5652 16.4531C13.0032 16.9942 13.5558 17.6768 14.6472 17.6768C15.7385 17.6768 16.2912 16.9943 16.7291 16.4532C16.952 16.1818 17.1291 15.9737 17.3203 15.8327C17.5096 15.6931 17.7131 15.6192 17.99 15.6192C18.2669 15.6192 18.4721 15.6932 18.6622 15.8328C18.8541 15.9737 19.0312 16.1819 19.2508 16.4531Z"
                        fill="#1B324F"
                        stroke="#1B324F"
                        strokeWidth="0.0717949"
                    />
                    <path
                        d="M19.2508 20.1843C19.6887 20.7254 20.2414 21.408 21.3328 21.408H21.3459C22.4373 21.408 22.9899 20.7254 23.4279 20.1843C23.6474 19.9131 23.8246 19.7049 24.0165 19.564C24.2066 19.4244 24.4117 19.3504 24.6887 19.3504C24.9758 19.3504 25.2152 19.1186 25.2152 18.8367C25.2152 18.5548 24.9758 18.323 24.6887 18.323C23.5908 18.323 23.0381 19.0056 22.6001 19.5467C22.3806 19.818 22.2034 20.0261 22.0115 20.1671C21.8214 20.3067 21.6162 20.3807 21.3393 20.3807C21.0624 20.3807 20.8572 20.3067 20.6672 20.1671C20.4752 20.0261 20.2981 19.818 20.0785 19.5467C19.6405 19.0055 19.0813 18.323 17.99 18.323C16.8987 18.323 16.346 19.0055 15.9081 19.5466C15.6851 19.818 15.508 20.0261 15.3168 20.1671C15.1276 20.3067 14.9241 20.3807 14.6472 20.3807C14.3703 20.3807 14.1668 20.3067 13.9784 20.1671C13.7881 20.0262 13.6126 19.8181 13.3929 19.5467C12.955 19.0056 12.4023 18.323 11.3109 18.323C11.0238 18.323 10.7844 18.5548 10.7844 18.8367C10.7844 19.1186 11.0238 19.3504 11.3109 19.3504C11.5878 19.3504 11.7913 19.4243 11.9797 19.5639C12.17 19.7049 12.3455 19.9129 12.5652 20.1843C13.0032 20.7254 13.5558 21.408 14.6472 21.408C15.7385 21.408 16.2912 20.7255 16.7291 20.1844C16.952 19.913 17.1291 19.7049 17.3203 19.5639C17.5096 19.4243 17.7131 19.3504 17.99 19.3504C18.2669 19.3504 18.4721 19.4244 18.6622 19.564C18.8541 19.7049 19.0312 19.9131 19.2508 20.1843Z"
                        fill="#1B324F"
                        stroke="#1B324F"
                        strokeWidth="0.0717949"
                    />
                    <path
                        d="M19.2508 23.9155C19.6887 24.4566 20.2414 25.1392 21.3328 25.1392H21.3459C22.4373 25.1392 22.9899 24.4566 23.4279 23.9155C23.6474 23.6443 23.8246 23.4361 24.0165 23.2952C24.2066 23.1556 24.4117 23.0816 24.6887 23.0816C24.9758 23.0816 25.2152 22.8498 25.2152 22.5679C25.2152 22.286 24.9758 22.0542 24.6887 22.0542C23.5908 22.0542 23.0381 22.7368 22.6001 23.2779C22.3806 23.5492 22.2034 23.7573 22.0115 23.8983C21.8214 24.0379 21.6162 24.1119 21.3393 24.1119C21.0624 24.1119 20.8572 24.0379 20.6672 23.8983C20.4752 23.7573 20.2981 23.5492 20.0785 23.2779C19.6405 22.7367 19.0813 22.0542 17.99 22.0542C16.8987 22.0542 16.346 22.7367 15.9081 23.2778C15.6851 23.5492 15.508 23.7573 15.3168 23.8983C15.1276 24.0379 14.9241 24.1119 14.6472 24.1119C14.3703 24.1119 14.1668 24.0379 13.9784 23.8983C13.7881 23.7574 13.6126 23.5493 13.3929 23.2779C12.955 22.7368 12.4023 22.0542 11.3109 22.0542C11.0238 22.0542 10.7844 22.286 10.7844 22.5679C10.7844 22.8498 11.0238 23.0816 11.3109 23.0816C11.5878 23.0816 11.7913 23.1555 11.9797 23.2951C12.17 23.4361 12.3455 23.6441 12.5652 23.9155C13.0032 24.4566 13.5558 25.1392 14.6472 25.1392C15.7385 25.1392 16.2912 24.4567 16.7291 23.9156C16.952 23.6442 17.1291 23.4361 17.3203 23.2951C17.5096 23.1555 17.7131 23.0816 17.99 23.0816C18.2669 23.0816 18.4721 23.1556 18.6622 23.2952C18.8541 23.4361 19.0312 23.6443 19.2508 23.9155Z"
                        fill="#1B324F"
                        stroke="#1B324F"
                        strokeWidth="0.0717949"
                    />
                </g>
                <path
                    d="M9.75737 21.1626C12.0727 21.5987 14.0774 19.8821 14.0774 17.698C14.0774 16.5549 13.4885 15.5101 12.593 14.8698V7.16275C12.593 6.01966 11.6613 5.0769 10.4512 5.0769C9.24106 5.0769 8.30928 6.01966 8.30928 7.16275V14.8698C7.23632 15.6436 6.61514 16.9556 6.92573 18.4365C7.20405 19.7839 8.34155 20.8916 9.7614 21.1626H9.75737ZM8.75702 15.5101L9.06761 15.2744V7.1981C9.06761 6.45961 9.6888 5.8861 10.4149 5.8861C11.1409 5.8861 11.7984 6.49104 11.7984 7.1981V15.2744L12.109 15.5101C12.8351 16.0168 13.2505 16.8574 13.2505 17.7295C13.2505 18.4679 12.9399 19.175 12.4196 19.6817C11.867 20.1885 11.1772 20.4909 10.4149 20.4909C8.99904 20.4909 7.89381 19.5168 7.65179 18.303C7.47834 17.1913 7.89381 16.115 8.75702 15.5101Z"
                    fill="#1B324F"
                />
                <path
                    d="M10.4511 19.6821C11.0037 19.6821 11.524 19.4818 11.9032 19.1086C12.2824 18.7394 12.4921 18.2327 12.4921 17.6945C12.4921 16.3471 11.3183 15.9111 11.0077 15.6401V9.95605H9.86619V15.644C9.62417 15.915 8.10347 16.4532 8.45037 18.0991C8.62382 18.9751 9.41845 19.6821 10.4551 19.6821H10.4511Z"
                    fill="#96B313"
                />
            </g>
            <defs>
                <clipPath id="clip0_1041_70230">
                    <rect
                        width="26"
                        height="24"
                        fill="white"
                        transform="translate(3 4)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
