import { Form, Formik } from "formik";
import { useState } from "react";
import { DrawerActionType, useDrawerContext } from "../../../../context/drawer";
import Button from "../../../buttons/button/Button";
import Loading from "../../../loading/Loading";
import Drawer from "../../Drawer";
import styles from "./AccountDrawers.module.scss";
import * as Yup from "yup";
import FormikInput from "../../../formik/FormikInput";
import { HomeButton } from "../../../buttons/drawerButton/DrawerButton";
import { useFirebase } from "../../../../context/firebase";

type FormValue = {
    email: string;
};

const passwordSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Required")
});

export default function ForgotPassword() {
    const { forgotPasswordDrawerOpen, hide } = useDrawerContext();
    const { sendPasswordResetEmail } = useFirebase();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const handleSubmit = async ({ email }: FormValue) => {
        try {
            setLoading(true);
            await sendPasswordResetEmail(email);
            hide(DrawerActionType.FORGOT_PASSWORD_DRAWER);
        } catch (error) {
            if (
                /user.*not.*found/i.test(
                    (error as { code?: string }).code ?? ""
                )
            ) {
                setError("User with this email is not registered yet");
            } else {
                setError(
                    "Failed to send reset password email, please contact support"
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const closeForgotPasswordDrawer = () =>
        hide(DrawerActionType.FORGOT_PASSWORD_DRAWER);

    return (
        <Drawer
            id={DrawerActionType.FORGOT_PASSWORD_DRAWER}
            isOpen={!!forgotPasswordDrawerOpen}
            position={"right"}
        >
            {loading ? (
                <Loading />
            ) : (
                <div className={styles.container}>
                    <HomeButton onClick={closeForgotPasswordDrawer} />
                    <div className={styles.content}>
                        <h2>Reset your password</h2>
                        <Formik<FormValue>
                            initialValues={{ email: "" }}
                            validationSchema={passwordSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isValid, isSubmitting }) => {
                                return (
                                    <Form className={styles.form}>
                                        <FormikInput
                                            name={"email"}
                                            label={"Email"}
                                            placeholder={"Email"}
                                        />
                                        {!!error && (
                                            <div className={styles.error}>
                                                {error}
                                            </div>
                                        )}
                                        <div className={styles.button_centre}>
                                            <Button
                                                className={styles.button}
                                                color={"primary"}
                                                type={"submit"}
                                                size="lg"
                                                disabled={
                                                    isSubmitting ||
                                                    !isValid ||
                                                    loading
                                                }
                                            >
                                                Reset password
                                            </Button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            )}
        </Drawer>
    );
}
