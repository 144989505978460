import Contact from "./contactDrawer/Contact";
import Login from "./accountDrawers/Login";
import MenuDrawer from "./MenuDrawer";
import UserProfileDrawer from "./accountDrawers/UserProfileDrawer";
import SignUp from "./accountDrawers/Signup";
import ForgotPassword from "./accountDrawers/ForgotPassword";
import FavouritesDrawer from "./favouritesDrawer/FavouritesDrawer";
import SafeswimLocation from "../../../types/SafeswimLocation";
import FAQDrawer from "./faqDrawer/FAQDrawer";
import AboutDrawer from "./aboutDrawer/AboutDrawer";
import FacilitySearchDrawer from "./facilitySearchDrawer/FacilitySearchDrawer";
import LatestVideos from "./latestVideosDrawer/LatestVideos";
import WaterSafety101Drawer from "../waterSafety101/WaterSafety101Drawer";
import PrivacyPolicyDrawer from "./privacyPolicy/privacyPolicy";

type Props = {
    onLocationClick: (location: SafeswimLocation) => void;
};

export default function MenuDrawerController(props: Props) {
    return (
        <>
            <MenuDrawer />
            <UserProfileDrawer />
            <Contact />
            <LatestVideos />
            <AboutDrawer />
            <FAQDrawer />
            <FavouritesDrawer onViewLocationClick={props.onLocationClick} />
            <FacilitySearchDrawer onLocationClick={props.onLocationClick} />
            <Login />
            <SignUp />
            <ForgotPassword />
            <WaterSafety101Drawer />
            <PrivacyPolicyDrawer />
        </>
    );
}
