import React from "react";
import SearchIcon from "../images/icons/SearchIcon";
import styles from "./SearchInput.module.scss";

type InputProps = {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSearchClick: () => void;
};

export default function SearchInput(props: InputProps) {
    return (
        <div className={styles.container}>
            <input
                autoFocus
                className={styles.input}
                placeholder={"Find a beach"}
                value={props.value}
                onChange={props.onChange}
                type="search"
            />
            <button
                className={styles.search_button}
                onClick={props.onSearchClick}
            >
                <SearchIcon />
            </button>
        </div>
    );
}
