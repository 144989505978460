import React, { useState } from "react";
import shareIcon from "../../../resources/images/random/share_icon.svg";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import {
    getCurrentWaterQualityLevel,
    WaterQualityLevel
} from "../../../utils/location";
import SafeswimLocation from "../../../types/SafeswimLocation";
import SafeswimAlert from "../../../types/SafeswimAlert";
import { Modal } from "../../modal/Modal";
import Button from "../button/Button";
import styles from "./ShareButton.module.scss";

type Props = {
    location?: SafeswimLocation;
    alert?: SafeswimAlert;
    className?: string;
};

function ShareButton(props: Props) {
    const [showModal, setShowModal] = useState(false);

    const getModalTitle = () => {
        if (props.location && props.alert) {
            return `Share safety alert for ${props.location.name}`;
        }

        if (props.location && !props.alert) {
            return `Share water quality information for ${props.location.name}`;
        }

        return "Share Safeswim";
    };

    const getShareUrl = () => {
        let url = "http://localhost:3000";

        if (process.env.REACT_APP_ENV === "test") {
            url = "https://test.safeswim.org.nz";
        } else if (process.env.REACT_APP_ENV === "production") {
            url = "https://safeswim.org.nz";
        }

        if (!!props.location && props.alert) {
            return `${url}/alert/${props.location.id}`;
        } else if (props.location && !props.alert) {
            return `${url}/water/${props.location.id}`;
        } else if (props.location) {
            return `${url}/${props.location.id}`;
        }

        return url;
    };

    const getDescription = () => {
        if (props.location && props.alert) {
            return `Surf Lifesaving has sent an alert about ${props.location.name}`;
        } else if (props.location && !props.alert) {
            const currentWaterQuality = getCurrentWaterQualityLevel(
                props.location
            );
            if (currentWaterQuality === WaterQualityLevel.GOOD) {
                return `Looks great for a swim at ${props.location.name}`;
            } else if (
                currentWaterQuality === WaterQualityLevel.HIGH_RISK ||
                currentWaterQuality === WaterQualityLevel.VERY_HIGH_RISK
            ) {
                return `${props.location.name} isn't looking so good...`;
            }
            return `Check out the conditions at ${props.location.name}`;
        } else if (props.location) {
            return `Check out the conditions at ${props.location.name}`;
        }

        return "Check swimming conditions at Safeswim!";
    };

    const getEmailBody = () => {
        if (props.location && props.alert) {
            return `${
                props.location.name
            } has been put on alert by local Surf Lifesavers. Check out the details at ${getShareUrl()}`;
        } else if (props.location && !props.alert) {
            const currentWaterQuality = getCurrentWaterQualityLevel(
                props.location
            );
            if (currentWaterQuality === WaterQualityLevel.GOOD) {
                return `Check out water quality and swimming conditions for ${
                    props.location.name
                } at ${getShareUrl()}`;
            } else if (
                currentWaterQuality === WaterQualityLevel.HIGH_RISK ||
                currentWaterQuality === WaterQualityLevel.VERY_HIGH_RISK
            ) {
                return `Water quality isn't great at ${
                    props.location.name
                } today. Find more details at ${getShareUrl()}`;
            }
            return `You can find details for water quality and swimming conditions at ${getShareUrl()}`;
        } else if (props.location) {
            return `You can find details for water quality and swimming conditions at ${
                props.location.name
            } ${getShareUrl()}`;
        }

        return (
            "Before you go for a swim this summer, check out water quality and swimming conditions in Auckland at " +
            getShareUrl()
        );
    };

    return (
        <div className={styles.container}>
            <Button
                onClick={() => setShowModal(true)}
                iconPosition="right"
                icon={shareIcon}
            >
                Share
            </Button>

            <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <div className={styles.wrapper}>
                    <h4>{getModalTitle()}</h4>
                    <div className={styles.button_group}>
                        <FacebookShareButton
                            quote={getDescription()}
                            url={getShareUrl()}
                        >
                            <div className={styles.button}>
                                <FacebookIcon size={30} round={true} />
                                Facebook
                            </div>
                        </FacebookShareButton>
                        <LinkedinShareButton
                            summary={getDescription()}
                            source={getShareUrl()}
                            url={getShareUrl()}
                        >
                            <div className={styles.button}>
                                <LinkedinIcon size={30} round={true} />
                                LinkedIn
                            </div>
                        </LinkedinShareButton>
                        <TwitterShareButton
                            title={getDescription()}
                            url={getShareUrl()}
                        >
                            <div className={styles.button}>
                                <TwitterIcon size={30} round={true} />
                                Twitter
                            </div>
                        </TwitterShareButton>
                        {!window.navigator.userAgent.includes("App/") && (
                            <EmailShareButton
                                subject={getDescription()}
                                body={getEmailBody()}
                                url={getShareUrl()}
                            >
                                <div className={styles.button}>
                                    <EmailIcon size={30} round={true} />
                                    Email
                                </div>
                            </EmailShareButton>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ShareButton;
