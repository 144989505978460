import React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
    fill?: boolean;
}
export default function Star(props: Props) {
    return (
        <svg
            {...props}
            data-name="Icon / 16px / star"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill={!!props.fill ? "currentColor" : "none"}
        >
            <path
                d="M8.27549 11.9187L11.4255 13.9187C11.8317 14.175 12.3317 13.7937 12.213 13.325L11.3005 9.73748C11.2758 9.63804 11.2797 9.53366 11.3118 9.43635C11.3439 9.33904 11.4028 9.25277 11.4817 9.18748L14.3067 6.83123C14.6755 6.52498 14.488 5.90623 14.0067 5.87498L10.3192 5.63748C10.2186 5.63163 10.1219 5.59659 10.0409 5.53666C9.95984 5.47672 9.89803 5.39448 9.86299 5.29999L8.48799 1.83748C8.45159 1.73742 8.38528 1.65099 8.29807 1.58991C8.21086 1.52883 8.10696 1.49606 8.00049 1.49606C7.89401 1.49606 7.79011 1.52883 7.7029 1.58991C7.61569 1.65099 7.54938 1.73742 7.51299 1.83748L6.13799 5.29999C6.10294 5.39448 6.04113 5.47672 5.9601 5.53666C5.87908 5.59659 5.78235 5.63163 5.68174 5.63748L1.99424 5.87498C1.51299 5.90623 1.32549 6.52498 1.69424 6.83123L4.51924 9.18748C4.5982 9.25277 4.6571 9.33904 4.68917 9.43635C4.72124 9.53366 4.72516 9.63804 4.70049 9.73748L3.85674 13.0625C3.71299 13.625 4.31299 14.0812 4.79424 13.775L7.72549 11.9187C7.80768 11.8665 7.90308 11.8387 8.00049 11.8387C8.09789 11.8387 8.19329 11.8665 8.27549 11.9187V11.9187Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
