import { useCallback, useEffect, useState } from "react";

export const useMobileState = () => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

    const onWindowResize = useCallback(() => {
        if (!isMobile && window.innerWidth < 768) {
            setIsMobile(true);
        } else if (isMobile && window.innerWidth >= 768) {
            setIsMobile(false);
        }
    }, [isMobile]);

    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, [onWindowResize]);

    return { isMobile };
};
