import styles from "./privacyPolicy.module.scss";
import { DrawerActionType, useDrawerContext } from "../../../../context/drawer";
import Drawer from "../../Drawer";
import { HomeButton } from "../../../buttons/drawerButton/DrawerButton";
import classNames from "classnames";
import Footer from "../../../footer/Footer";
import { BackButtonDrawer } from "../../BackButtonDrawer/BackButtonDrawer";

export default function PrivacyPolicyDrawer() {
    const { hideAll, hide, policyDrawerOpen } = useDrawerContext();

    const closeAboutDrawer = () => hideAll();

    return (
        <Drawer
            id={DrawerActionType.POLICY_DRAWER}
            isOpen={!!policyDrawerOpen}
            position={"right"}
        >
            <div className={styles.container}>
                <HomeButton onClick={closeAboutDrawer} />
                <h2>Privacy & Terms</h2>
                <p className={styles.heading}>Safeswim User Privacy Policy</p>
                <p>
                    Your privacy is important to Auckland Council (referred to
                    in this Privacy Policy as “Safeswim”, “we”, “our” or “us”).
                    This Privacy Policy governs our collection, use and
                    disclosure of your personal information as defined in the
                    Privacy Act 2020 (Privacy Act) and has been prepared in
                    accordance with our obligations and your rights set out in
                    the Privacy Act.
                </p>
                <p>
                    We may change this Privacy Policy from time to time. By
                    using the Safeswim website you agree to the following terms
                    and conditions:
                </p>
                <p className={styles.heading}>
                    1. What kind of personal information does Safeswim collect?
                </p>
                <p>
                    In order for you to receive notifications and save your
                    favourite beaches you require a Safeswim account. We use
                    your name and email address to create your Safeswim account.
                </p>
                <p>
                    The GPS functionality (location) of the Safeswim website
                    utilises third party sources, Google Cloud Platform in
                    Sydney, Australia. We use this location information to
                    locate nearest beaches to you within the Safeswim App and
                    Website.
                </p>
                <p className={styles.heading}>2. Collecting your information</p>
                <p>
                    We may collect personal information about you as set out
                    below:
                </p>
                <ol>
                    <li>
                        You are providing your name and email when creating your
                        account
                    </li>
                    <li>You are emailing us using our online form</li>
                    <li>
                        This site use cookies. A session-only cookie is used to
                        remember a previous login. No personal information is
                        stored in cookies
                    </li>
                    <li>
                        Our internet service providers (Google) may also make a
                        record of your visit and log information for statistical
                        purposes. This information is only analysed on a bulk
                        basis for broad demographic content. Individual use is
                        not analysed
                    </li>
                </ol>
                <p className={styles.heading}>3. Using your information</p>
                <p>
                    The personal information that we collect from you may be
                    used for any of the following purposes:
                </p>
                <ol>
                    <li>To provide you with Safeswim services</li>
                    <li>
                        To respond to correspondence or to provide you with
                        information that you have requested
                    </li>
                    <li>
                        For administrative and business purposes for example
                        providing universal user information and updates from
                        time to time
                    </li>
                </ol>
                <p className={styles.heading}>4. Sharing your information</p>
                <p>
                    We will not publish, disclose or use any information that
                    identifies you. 
                </p>
                <p className={styles.heading}>
                    5. What if you do not provide us with the personal
                    information requested?
                </p>
                <p>
                    We use your name and email address to create your Safeswim
                    account If you do not provide us with this information we
                    will not be able to send you notifications or save your
                    favourite beaches.
                </p>
                <p className={styles.heading}>6. Security and accuracy</p>
                <p>
                    Your information is protected within Auckland Council’s
                    Privacy Policy which governs the collection, use and
                    disclosure of your personal information.
                </p>
                <p>
                    Information that you provide is stored in Google Cloud
                    Platform.
                </p>
                <p>
                    We will securely retain your name and email until you delete
                    your account.
                </p>
                <p>
                    As per the Safeswim Terms and Conditions you undertake to
                    provide accurate information.
                </p>
                <p>The user is responsible for their own account.</p>
                <p>
                    We take reasonable steps to ensure your personal information
                    is protected against loss, damage, misuse and unauthorised
                    access. We restrict access to personal information to those
                    individuals who need access to this information in order to
                    assist us in performing our duties and obligations.
                </p>
                <p className={styles.heading}>
                    7. How long we hold personal information
                </p>
                <p>
                    Safeswim will securely retain your name and email until the
                    user deletes their account.
                </p>
                <p>
                    However, after two years of inactivity your account will
                    automatically be deleted.
                </p>
                <p className={styles.heading}>
                    8. Accessing and correcting your personal information
                </p>
                <p>
                    As required by Privacy Act 2020, you have the right to
                    access and correct any personal information we hold about
                    you.
                </p>
                <p>
                    You may request access to or deletion of your personal
                    information by emailing us at{" "}
                    <a href="mailto: safeswim@aucklandcouncil.govt.nz">
                        safeswim@aucklandcouncil.govt.nz
                    </a>
                </p>
                <p className={styles.heading}>
                    9. Who you can contact for further information{" "}
                </p>
                <p>
                    Auckland Council’s Customer Privacy Policy is available{" "}
                    <a
                        href="https://www.aucklandcouncil.govt.nz/Pages/privacy-policy.aspx"
                        target="_blank"
                    >
                        here
                    </a>
                    .
                </p>
                <p>
                    If you have any queries about this Privacy Policy or
                    personal data Safeswim has collected, please contact:{" "}
                    <a href="mailto: privacy@aucklandcouncil.govt.nz">
                        privacy@aucklandcouncil.govt.nz
                    </a>
                </p>
            </div>
            <div className={styles.divider}>
                <div className={classNames(styles.section, styles.footer)}>
                    <Footer hidePolicy />
                </div>
                <BackButtonDrawer
                    noLine
                    noMarginTop
                    onClick={() => {
                        hide(DrawerActionType.MENU_DRAWER);
                        hide(DrawerActionType.POLICY_DRAWER);
                    }}
                />
            </div>
        </Drawer>
    );
}
