import Patrol from "./Patrol";
import SafeswimAlert from "./SafeswimAlert";

interface BaseSafeswimLocation {
    id: number;
    lat: number;
    lng: number;
    name: string;
    isPermanent: boolean;
    permanentWaterQuality?: number;
    unavailable: boolean;
    currentWaterQuality: number;
    hazards: string[];
    tags: string[];
    features: string[];
    region: string;
    regionBased: string;
    forecast?: Forecast;
    maoriName?: string;
    description?: string;
    images?: string[];
    lifeguardClubCoordinates?: { lat: number; lng: number };
    featuredImage?: string;
    creativeImage?: string;
    creativeImageTheme?: Theme;
}

export enum Theme {
    DARK = "DARK",
    LIGHT = "LIGHT"
}

export interface Forecast {
    id: number;
    locationId: number;
    permanentWaterQuality: number;
    createdAt: Date;
    today: ForecastItem;
    tomorrow: ForecastItem;
    dayAfter: ForecastItem;
}

export interface ForecastItem {
    date: Date;
    water: { [hourOfDay: string]: ForecastDataDTO };
    wind: { [hourOfDay: string]: WindDTO };
    waterTemp: { [hourOfDay: string]: ForecastDataDTO };
    tides: TideDTO[];
}

export interface WindDTO {
    speedRecordedAt: string;
    createdAt: string;
    speed: number;
    direction: string;
}

export interface ForecastDataDTO {
    recordedAt: string;
    createdAt: Date;
    value: number;
}

export interface TideDTO {
    date: string;
    value: number;
    level: "LOW" | "HIGH";
}

export interface LocationMarkers {
    [locationId: number]: google.maps.Marker;
}

export interface Hazard {
    id: string;
    title: string;
    icon?: string | null;
}

export default interface SafeswimLocation extends BaseSafeswimLocation {
    alerts?: {
        waterQuality: SafeswimAlert[];
        hazards: SafeswimAlert[];
    };
    patrolHours?: { [date: string]: Patrol | null };
}
