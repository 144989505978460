export function fireTagEvent(
    event: string,
    params: Record<string, unknown> | undefined
): void {
    const usertype = "guest";
    const dataLayer = window.dataLayer || [];
    let eventdata = { event: event, usertype: usertype };
    if (params !== undefined) {
        eventdata = Object.assign(eventdata, params);
    }
    dataLayer.push(eventdata);
    // console.log("pushing event to gtm: " + event);
}
