import LocationDrawer from "./LocationDrawer";
import SafeswimLocation, { Forecast } from "../../../types/SafeswimLocation";
import WeatherForecast from "../../../types/WeatherForecast";
import WaterSafetyDrawer from "../waterSafetyDrawer/WaterSafetyDrawer";
import WaterQualityDrawer from "../waterQualityDrawer/WaterQualityDrawer";
import SLSDrawer from "../slsDrawer/SLSDrawer";
import WaterSafety101Drawer from "../waterSafety101/WaterSafety101Drawer";

type Props = {
    location?: SafeswimLocation;
    weatherForecast?: WeatherForecast;
    forecast?: Forecast;
};

export default function LocationDrawerController(props: Props) {
    const { location, weatherForecast, forecast } = props;

    return (
        <>
            <LocationDrawer
                location={location}
                weatherForecast={weatherForecast}
                forecast={forecast}
            />
            <WaterSafetyDrawer location={location} />
            <WaterQualityDrawer location={location} />
            <SLSDrawer location={location} />
            <WaterSafety101Drawer openLeft />
        </>
    );
}
