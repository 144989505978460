import { DrawerActionType, useDrawerContext } from "../../../context/drawer";
import SafeswimLocation from "../../../types/SafeswimLocation";
import Drawer from "../Drawer";
import Header from "../locationDrawer/components/header/Header";
import PatrolCalendar from "./components/patrolCalendar/PatrolCalendar";

type Props = {
    location?: SafeswimLocation;
    content?: "safety-101" | "calendar";
};

export default function SLSDrawer({ location }: Props) {
    const { slsDrawerOpen } = useDrawerContext();

    if (!location) {
        return null;
    }
    return (
        <Drawer
            id={DrawerActionType.SLS_DRAWER}
            isOpen={!!slsDrawerOpen}
            secondary
            position={"left"}
        >
            <Header
                location={location}
                submenuDrawer={DrawerActionType.SLS_DRAWER}
            />
            <PatrolCalendar location={location} />
        </Drawer>
    );
}
