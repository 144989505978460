import { Wind } from "../../../../images/forecast/Wind";
import {
    Cloudy,
    Fog,
    Rain,
    Showers,
    Snow,
    Storm,
    Sunny,
    WeatherUnavailable,
    Windy
} from "../../../../images/forecast/Weather";
import WaterQuality from "../../../../images/icons/WaterQuality";
import styles from "../Forecast.module.scss";

const sunnyKeyWords = [
    "sunny",
    "mostly sunny",
    "partly sunny",
    "clear",
    "mostly clear"
];
const cloudyKeyWords = [
    "intermittent clouds",
    "hazy sunshine",
    "mostly cloudy",
    "cloudy",
    "dreary (overcast)",
    "dreary",
    "overcast",
    "hazy moonlight",
    "partly cloudy"
];
const rainKeyWords = ["rain", "freezing rain"];
const showerKeyWords = [
    "mostly cloudy w/ showers",
    "partly sunny w/ showers",
    "showers",
    "partly cloudy w/ showers"
];
const snowKeyWords = [
    "snow",
    "flurries",
    "mostly cloudy w/ flurries",
    "partly sunny w/ flurries",
    "mostly cloudy w/ snow",
    "ice",
    "sleet",
    "rain and snow"
];
const stormKeyWords = [
    "t-storms",
    "mostly cloudy w/ t-storms",
    "partly sunny w/ t-storms",
    "partly cloudy w/ t-storms",
    "thunderstorms"
];

export function getWeatherIcon(
    phrase: string | undefined | null,
    className?: string
) {
    if (phrase) {
        const phraseLowerCase = phrase.toLowerCase();
        if (cloudyKeyWords.indexOf(phraseLowerCase) > -1) {
            return <Cloudy className={className} />;
        } else if (sunnyKeyWords.indexOf(phraseLowerCase) > -1) {
            return <Sunny className={className} />;
        } else if (rainKeyWords.indexOf(phraseLowerCase) > -1) {
            return <Rain className={className} />;
        } else if (stormKeyWords.indexOf(phraseLowerCase) > -1) {
            return <Storm className={className} />;
        } else if (showerKeyWords.indexOf(phraseLowerCase) > -1) {
            return <Showers className={className} />;
        } else if (snowKeyWords.indexOf(phraseLowerCase) > -1) {
            return <Snow className={className} />;
        } else if (phraseLowerCase === "fog") {
            return <Fog className={className} />;
        } else if (phraseLowerCase === "windy") {
            return <Windy className={className} />;
        }
    } else {
        return <WeatherUnavailable className={className} />;
    }
}

export function getIconPhrase(phrase: string | null | undefined) {
    if (phrase) {
        const phraseLowerCase = phrase.toLowerCase();
        if (sunnyKeyWords.indexOf(phraseLowerCase) > -1) {
            return "Sunny";
        } else if (cloudyKeyWords.indexOf(phraseLowerCase) > -1) {
            return "Cloudy";
        } else if (rainKeyWords.indexOf(phraseLowerCase) > -1) {
            return "Rain";
        } else if (showerKeyWords.indexOf(phraseLowerCase) > -1) {
            return "Showers";
        } else if (snowKeyWords.indexOf(phraseLowerCase) > -1) {
            return "Snow";
        } else if (stormKeyWords.indexOf(phraseLowerCase) > -1) {
            return "Storm";
        } else if (phraseLowerCase === "fog") {
            return "Fog";
        } else if (phraseLowerCase === "windy") {
            return "Windy";
        } else if (phraseLowerCase === "unavailable") {
            return "Unavailable";
        }
    } else {
        return "Unavailable";
    }
}

export function getWindIcon(
    speed: number | null | undefined,
    direction: string | null | undefined
) {
    return <Wind speed={speed} direction={direction} />;
}

export function renderUnavailableForecasts(forecastType: string) {
    const forecastArray = [];

    for (let i = 5; i < 23; i += 2) {
        if (forecastType === "water") {
            forecastArray.push(
                <td key={`water.unavailable.${i}`}>
                    <WaterQuality level="grey" className={styles.grey} />
                    Unavailable
                </td>
            );
        } else if (forecastType === "wind") {
            forecastArray.push(
                <td key={`wind.unavailable.${i}`}>
                    {getWindIcon(null, null)}
                    Unavailable
                </td>
            );
        } else if (forecastType === "waterTemp") {
            forecastArray.push(
                <td key={`waterTemp.unavailable.${i}`}>Unavailable</td>
            );
        } else if (forecastType === "weather") {
            forecastArray.push(
                <td key={`weather.unavailable.${i}`}>
                    <WeatherUnavailable />
                </td>
            );
        }
    }

    return forecastArray;
}
