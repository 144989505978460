import React from "react";
import feature_gas_bbq from "../../../../../resources/images/facilities/dark/bbq.png";
import feature_shops_nearby from "../../../../../resources/images/facilities/dark/shops_cafes.png";
import feature_toilet_block from "../../../../../resources/images/facilities/dark/toilet_block.png";
import feature_shower from "../../../../../resources/images/facilities/dark/shower.png";
import feature_boat_ramp from "../../../../../resources/images/facilities/dark/boat_ramp.png";
import feature_life_guards from "../../../../../resources/images/facilities/dark/lifeguards.png";
import feature_onsite_camping from "../../../../../resources/images/facilities/dark/onsite_camping.png";
import feature_playground from "../../../../../resources/images/facilities/dark/playground.png";
import feature_changing_facilities from "../../../../../resources/images/facilities/dark/changing_facilities.png";
import feature_first_aid from "../../../../../resources/images/facilities/dark/first_aid_station.png";
import feature_lifesaving_equipment from "../../../../../resources/images/facilities/dark/lifesaving_equipment.png";
import feature_parking from "../../../../../resources/images/facilities/dark/parking.png";
import feature_picnic from "../../../../../resources/images/facilities/dark/picnic_area.png";
import feature_public_transport from "../../../../../resources/images/facilities/dark/public_transport.png";
import feature_recycling from "../../../../../resources/images/facilities/dark/recycling_facilities.png";
import feature_swimming_pontoon from "../../../../../resources/images/facilities/dark/swimming_pontoon.png";
import feature_wheelchair_beach from "../../../../../resources/images/facilities/dark/wheelchair_accessible_beach.png";
import feature_wheelchair_toilets from "../../../../../resources/images/facilities/dark/wheelchair_accessible_toilets.png";
import { BEACH_FEATURES } from "../../../../../utils/constants";
import styles from "./Features.module.scss";

type Props = {
    features?: string[];
};

export default function Features(props: Props) {
    const { features } = props;

    if (!features || features.length === 0) {
        return null;
    }

    const featureViews = features.map((feature, i) => {
        let newFeature = feature;

        if (feature === "bbq") {
            newFeature = "gas_bbq";
        } else if (feature === "wheelchaid_accessible_toilets") {
            newFeature = "wheelchair_accessible_toilets";
        }

        const featureDetails = BEACH_FEATURES[newFeature];
        const featureIcon = (() => {
            switch (featureDetails.id) {
                case "bbq":
                    return feature_gas_bbq;
                case "shops_nearby":
                    return feature_shops_nearby;
                case "toilet_block":
                    return feature_toilet_block;
                case "shower":
                    return feature_shower;
                case "boat_ramp":
                    return feature_boat_ramp;
                case "life_guards":
                    return feature_life_guards;
                case "onsite_camping":
                    return feature_onsite_camping;
                case "playground":
                    return feature_playground;
                case "changing_facilities":
                    return feature_changing_facilities;
                case "first_aid_station":
                    return feature_first_aid;
                case "lifesaving_equipment":
                    return feature_lifesaving_equipment;
                case "parking":
                    return feature_parking;
                case "picnic_area":
                    return feature_picnic;
                case "public_transport":
                    return feature_public_transport;
                case "recycling_facilities":
                    return feature_recycling;
                case "swimming_pontoon":
                    return feature_swimming_pontoon;
                case "wheelchair_accessible_beach":
                    return feature_wheelchair_beach;
                case "wheelchaid_accessible_toilets":
                    return feature_wheelchair_toilets;
                default:
                    return null;
            }
        })();

        return (
            <li key={i} className={styles.list_item}>
                <img src={featureIcon || ""} alt="" />
                <div className={styles.label}>{featureDetails.label}</div>
            </li>
        );
    });

    return (
        <div className={styles.features}>
            <h6 className={styles.title}>Facilities</h6>
            <div className={styles.divider} />
            <ul className={styles.list}>{featureViews}</ul>
        </div>
    );
}
