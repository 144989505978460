import { DrawerActionType, useDrawerContext } from "./context/drawer";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ReactElement, useEffect, useState } from "react";
import SafeswimLocation, { Forecast } from "./types/SafeswimLocation";
import { getLocationForecast, getWeatherForecast } from "./api/locations";

import AlertsModal from "./components/modal/alerts/AlertsModal";
import Banner from "./components/banner/Banner";
import DeleteAccountModal from "./components/modal/deleteUser/DeleteAccountModal";
import Download from "./components/download/Download";
import EventDrawer from "./components/drawer/eventDrawer/EventDrawer";
import { FavouritesModal } from "./components/modal/favourites/FavouritesModal";
import { LifeguardModal } from "./components/modal/lifeguard/LifeguardModal";
import Loading from "./components/loading/Loading";
import LocationDrawerController from "./components/drawer/locationDrawer/LocationDrawerController";
import Map from "./components/map/Map";
import MapKeyDrawer from "./components/drawer/mapKeyDrawer/MapKeyDrawer";
import MenuDrawerController from "./components/drawer/menuDrawer/MenuDrawerController";
import NavBar from "./components/navbar/NavBar";
import OverlaySearchBar from "./components/navbar/OverlaySearchBar";
import SearchModal from "./components/modal/search/SearchModal";
import SpecialEvent from "./types/SpecialEvent";
import { Status } from "@googlemaps/react-wrapper";
import WeatherForecast from "./types/WeatherForecast";
import WelcomeDrawer from "./components/drawer/welcomeDrawer/WelcomeDrawer";
import classNames from "classnames";
import { initSentry } from "./utils/sentry";
import styles from "./App.module.scss";
import { useApiContext } from "./context/api";
import { useMobileState } from "./hooks/useMobileState";
import { useModalContext } from "./context/modal";

// import Banner from "./components/banner/Banner";

type Props = {
    status: Status;
};

initSentry();

export default function App({ status }: Props): ReactElement {
    const [selectedEvent, setSelectedEvent] = useState<
        SpecialEvent | undefined
    >(undefined);
    const [selectedLocation, setSelectedLocation] = useState<
        SafeswimLocation | undefined
    >(undefined);
    const [weatherForecast, setWeatherForecast] = useState<
        WeatherForecast | undefined
    >(undefined);
    const [locationForecast, setLocationForecast] = useState<
        Forecast | undefined
    >(undefined);
    const { isMobile } = useMobileState();
    const navigate = useNavigate();
    const {
        show,
        hide,
        hideAll,
        hideAllRightHandDrawers,
        menuDrawerOpen,
        waterSafety101DrawerRightOpen,
        welcomeDrawerOpen,
        locationDrawerOpen
    } = useDrawerContext();
    const {
        deleteAccountModalOpen,
        alertsModalOpen,
        searchModalOpen,
        lifeguardModalOpen,
        favouritesModalOpen
    } = useModalContext();
    const { locationObjects } = useApiContext();

    useEffect(() => {
        const { pathname, search } = window.location;

        const skipWelcome =
            new URLSearchParams(search).get("skipWelcome")?.toLowerCase() ===
            "true";

        if (pathname === "/about") {
            navigate("/", { replace: true });
            show(DrawerActionType.MENU_DRAWER);
            show(DrawerActionType.ABOUT_DRAWER);
        }

        // redirect FAQ page to FAQ drawer
        if (pathname === "/faq") {
            navigate("/", { replace: true });
            show(DrawerActionType.MENU_DRAWER);
            show(DrawerActionType.FAQ_DRAWER);
        }
        if (pathname === "/privacy") {
            navigate("/", { replace: true });
            show(DrawerActionType.MENU_DRAWER);
            show(DrawerActionType.POLICY_DRAWER);
        }

        if (skipWelcome) {
            hide(DrawerActionType.WELCOME_DRAWER);
        } else {
            show(DrawerActionType.WELCOME_DRAWER);
        }

        return () => {
            hide(DrawerActionType.WELCOME_DRAWER);
        };
    }, []);

    const onSpecialEventClick = (s: SpecialEvent) => {
        hideAll();
        setSelectedEvent(s);
        show(DrawerActionType.EVENT_DRAWER);
    };

    const onLocationClick = async (l: SafeswimLocation) => {
        hideAll();
        setSelectedLocation(undefined);
        show(DrawerActionType.LOCATION_DRAWER);
        try {
            const [forecast, weather]: [Forecast, WeatherForecast] =
                await Promise.all([
                    getLocationForecast(l.id),
                    getWeatherForecast(l.id)
                ]);
            setSelectedLocation(locationObjects[l.id]);
            setWeatherForecast(weather);
            setLocationForecast(forecast);
        } catch (e) {
            hide(DrawerActionType.LOCATION_DRAWER);
        }
    };

    const renderMap = () => {
        return (
            <div
                className={classNames(styles.map_container, {
                    [styles.map_offset]: welcomeDrawerOpen || locationDrawerOpen
                })}
            >
                {status === Status.SUCCESS && (
                    <Map
                        selectedLocation={selectedLocation}
                        onSpecialEventClick={onSpecialEventClick}
                        isMobile={isMobile}
                        onLocationClick={onLocationClick}
                    />
                )}
            </div>
        );
    };

    const renderDownload = () => {
        return <Download />;
    };

    return (
        <div className={styles.app_container} id="app-container">
            {!isMobile && (
                <NavBar mapReady={status === Status.SUCCESS} lightMode />
            )}
            <OverlaySearchBar />
            {/* <Banner isMobile={isMobile} /> */}
            {/* <ImageBanner /> */}

            {(menuDrawerOpen || waterSafety101DrawerRightOpen) && (
                <div
                    className={styles.overlay}
                    onClick={hideAllRightHandDrawers}
                />
            )}
            <WelcomeDrawer />
            <LocationDrawerController
                location={selectedLocation}
                weatherForecast={weatherForecast}
                forecast={locationForecast}
            />

            <MenuDrawerController onLocationClick={onLocationClick} />
            <EventDrawer event={selectedEvent} />
            <MapKeyDrawer />

            <AlertsModal show={!!alertsModalOpen} />
            <LifeguardModal show={!!lifeguardModalOpen} />
            <FavouritesModal show={!!favouritesModalOpen} />
            <SearchModal
                show={!!searchModalOpen}
                onLocationClick={onLocationClick}
            />
            <DeleteAccountModal show={!!deleteAccountModalOpen} />
            <Routes>
                <Route path={"/"} element={renderMap()} />
                <Route path={"/water/:locationId"} element={renderMap()} />
                <Route path={"/alert/:locationId"} element={renderMap()} />
                <Route path={"/bp/:locationId"} element={renderMap()} />
                <Route path={"/faq"} element={renderMap()} />
                <Route path={"/about"} element={renderMap()} />
                <Route path={"/privacy"} element={renderMap()} />
                <Route path={"/download"} element={renderDownload()} />
                <Route path={"*"} element={<Navigate to={"/"} replace />} />
            </Routes>
            {isMobile && <NavBar mapReady={status === Status.SUCCESS} />}
        </div>
    );
}
