import React, {
    ReactElement,
    Ref,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState
} from "react";
import classnames from "classnames";
import SearchItem from "./SearchItem";
import orderBy from "lodash/orderBy";
import debounce from "lodash/debounce";
import PulseLoader from "react-spinners/PulseLoader";
import SafeswimLocation from "../../types/SafeswimLocation";
import { fireTagEvent } from "../../utils/tagmanager";
import { searchLocations } from "../../api/locations";
import { useMapContext } from "../../context/map";
import SearchInput from "./SearchInput";
import styles from "./SearchBox.module.scss";

type SearchProps = {
    placeholder?: string;
    className?: string;
    usesDropdown?: boolean;
    results?: SafeswimLocation[];
    setResultsCallback?: React.Dispatch<
        React.SetStateAction<SafeswimLocation[]>
    >;
    loadingCallback?: React.Dispatch<React.SetStateAction<boolean>>;
    onResultClick?: (s: SafeswimLocation) => void;
};

export type SearchBoxRef = {
    clearSearch: () => void;
};

function SearchBox(props: SearchProps, ref: Ref<SearchBoxRef>): ReactElement {
    const { map } = useMapContext();
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<SafeswimLocation[]>([]);
    let menuClasses = classnames(styles.dropdownContainer, {
        open: search.length > 0
    });

    useEffect(() => {
        if (!props.usesDropdown) {
            debouncedChangeHandler("");
        }
    }, []);

    const debouncedChangeHandler = useMemo(
        () =>
            debounce(async (search: string) => {
                setLoading(true);
                props.loadingCallback?.(true);
                const results = await searchLocations(search);
                const sortedResults = orderBy(results, ["name"], ["asc"]);
                setSearchResults(sortedResults);
                props.setResultsCallback &&
                    props.setResultsCallback(sortedResults);
                setLoading(false);
                props.loadingCallback?.(false);
            }, 150),
        []
    );

    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        };
    }, [debouncedChangeHandler]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        debouncedChangeHandler(e.target.value.toLowerCase());
    };

    const onSearchButtonClick = () => {
        if (searchResults.length) {
            onSearchItemClick(searchResults[0]);
        } else {
            onEmptySearchClick();
        }
    };

    const onSearchItemClick = (location: SafeswimLocation) => {
        setSearch("");
        setSearchResults([]);
        fireTagEvent("search-click", {
            beachSearch: location.name,
            beachName: location.name
        });

        if (map && !props.onResultClick) {
            map.panTo({
                lat: location.lat,
                lng: location.lng
            });
        }
        if (props.onResultClick) {
            props.onResultClick(location);
        }
        menuClasses = classnames(styles.dropdown_container, { open: false });
    };

    const onEmptySearchClick = () => {
        setSearch("");
        setSearchResults([]);
        !props.usesDropdown && debouncedChangeHandler("");
        fireTagEvent("search-click", {
            beachSearch: "Redirect to beach list",
            beachName: "Redirect to beach list"
        });
        menuClasses = classnames(styles.dropdown_container, { open: false });
    };

    const renderSearchResults = () => {
        if (loading) {
            return (
                <div className={styles.search_loading}>
                    <PulseLoader size={10} color={"#cccccc"} />
                </div>
            );
        }

        if (searchResults.length === 0) {
            return (
                <SearchItem
                    key={"noresults"}
                    onClick={() => {
                        onEmptySearchClick();
                    }}
                >
                    This beach is not currently monitored by Safeswim. A list of
                    beaches currently monitored by Safeswim can be found here.
                </SearchItem>
            );
        }

        return searchResults.map((location, key) => {
            return (
                <SearchItem
                    key={key}
                    onClick={() => {
                        onSearchItemClick(location);
                    }}
                >
                    {location.name}
                    {location.maoriName && <span>({location.maoriName})</span>}
                </SearchItem>
            );
        });
    };

    useImperativeHandle(ref, () => ({
        clearSearch: onEmptySearchClick
    }));

    return (
        <div className={classnames(styles.search_container, props.className)}>
            <SearchInput
                value={search}
                onChange={onChange}
                onSearchClick={onSearchButtonClick}
            />
            {props.usesDropdown && (
                <>
                    <div className={menuClasses}>
                        <ul role="menu" className={styles.results}>
                            {renderSearchResults()}
                        </ul>
                    </div>
                    {search.length > 0 && (
                        <div
                            className={styles.empty}
                            onClick={() => {
                                setSearch("");
                                setSearchResults([]);
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default React.forwardRef(SearchBox);
