import branded from "../../../resources/images/pins/branded.png";
import brandedHazard from "../../../resources/images/pins/branded_hazard.png";
import brandedHazardRedFlag from "../../../resources/images/pins/branded_hazard_redflag.png";
import brandedHazardSLS from "../../../resources/images/pins/branded_hazard_sls.png";
import brandedHazardSLSOff from "../../../resources/images/pins/branded_hazard_sls_off.png";
import brandedRedFlag from "../../../resources/images/pins/branded_redflag.png";
import brandedSLS from "../../../resources/images/pins/branded_sls.png";
import brandedSLSOff from "../../../resources/images/pins/branded_sls_off.png";

const Branded = {
    branded,
    brandedHazard,
    brandedSLS,
    brandedSLSOff,
    brandedHazardSLS,
    brandedHazardSLSOff,
    brandedRedFlag,
    brandedHazardRedFlag
};

export default Branded;
