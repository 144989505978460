import { Modal } from "../Modal";
import { ModalActionType, useModalContext } from "../../../context/modal";
import Button from "../../buttons/button/Button";
import { useState } from "react";
import { deleteAccount } from "../../../api/users";
import { useFirebase } from "../../../context/firebase";
import { DrawerActionType, useDrawerContext } from "../../../context/drawer";

export default function DeleteAccountModal(props: { show: boolean }) {
    const { show } = props;
    const { hideModal } = useModalContext();
    const { hide } = useDrawerContext();
    const [loading, setLoading] = useState(false);
    const { signOut } = useFirebase();

    const onConfirmClick = async () => {
        setLoading(true);
        await deleteAccount();
        await signOut();
        hide(DrawerActionType.PROFILE_DRAWER);
        setLoading(false);
    };

    return (
        <Modal
            isOpen={show}
            onClose={() => hideModal(ModalActionType.DELETE_ACCOUNT_MODAL)}
        >
            <h3>Delete account</h3>
            <p>
                Are you sure you want to delete your account? This action cannot
                be reversed.
            </p>
            <Button
                onClick={onConfirmClick}
                color={"destructive"}
                disabled={loading}
            >
                Confirm
            </Button>
            <Button
                onClick={() => hideModal(ModalActionType.DELETE_ACCOUNT_MODAL)}
                disabled={loading}
                variant={"outline"}
            >
                Cancel
            </Button>
        </Modal>
    );
}
