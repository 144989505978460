import { ModalActionType, useModalContext } from "../../../context/modal";

import { Modal } from "../Modal";
import styles from "./FavouritesModal.module.scss";
interface LifeguardModalProps {
    show: boolean;
}

export const FavouritesModal = (props: LifeguardModalProps) => {
    const { show } = props;
    const { hideModal } = useModalContext();

    return (
        <Modal
            isOpen={show}
            onClose={() => hideModal(ModalActionType.FAVOURITES_MODAL)}
        >
            <div className={styles.content}>
                <h3>
                    You've reached the maximum limit of favourite locations.
                </h3>
                <p>
                    Please remove at least one location from your favourites
                    list to add this location.
                </p>
            </div>
        </Modal>
    );
};
