import { Modal } from "../Modal";
import { ModalActionType, useModalContext } from "../../../context/modal";
import { useApiContext } from "../../../context/api";
import tsunamiBanner from "../../../resources/images/hazards/tsunami_banner.png";
import generic from "../../../resources/images/hazards/hazard-symbols_unspecified-hazard.png";
import SafeswimAlert from "../../../types/SafeswimAlert";
import styles from "./AlertsModal.module.scss";
import SafeswimLocation from "../../../types/SafeswimLocation";
import sortBy from "lodash/sortBy";
import WaterQuality from "../../images/icons/WaterQuality";
import { WaterQualityLevel } from "../../../types/WaterQuality";
import RedFlag from "../../images/icons/RedFlag";
import classNames from "classnames";
import { BackButtonDrawer } from "../../drawer/BackButtonDrawer/BackButtonDrawer";

const Header = () => {
    return (
        <div className={styles.alerts_header}>
            <h2>Active warnings</h2>
            <p className={styles.large}>
                Warnings noted here indicate current risks to the health and
                safety of beach users.
            </p>
        </div>
    );
};

// const Disclaimer = () => {
//     return (
//         <div className={styles.disclaimer}>
//             <p className={styles.disclaimer_strapline}>Disclaimer</p>
//             <p>
//                 They are uploaded to the Safeswim website by Surf Lifesaving
//                 professionals or members of the Safeswim project team on the
//                 advice of the Auckland Regional Public Health Service,
//                 Watercare, or Pollution Response.
//             </p>
//         </div>
//     );
// };

const Tsunami = (props: {
    alert: SafeswimAlert;
    locationObjects: { [locationId: number]: SafeswimLocation };
}) => {
    return (
        <div className={styles.text}>
            <img src={tsunamiBanner} alt={"Tsunami alert"} />
            <h4>{props.alert.title}</h4>
            <p className={styles.large}>{props.alert.description}</p>
        </div>
    );
};

const Hazards = (props: {
    hazardAlerts: SafeswimAlert[];
    waterQualityAlerts: SafeswimAlert[];
    locationObjects: { [locationId: number]: SafeswimLocation };
}) => {
    const { hazardAlerts, waterQualityAlerts, locationObjects } = props;
    const blackOverflows = (waterQualityAlerts || []).filter(
        (a) => Number(a.value) === 3
    );

    if (!blackOverflows.length && (!hazardAlerts || !hazardAlerts.length)) {
        return (
            <div className={styles.no_warnings}>
                <p className={styles.large}>No active warnings to report.</p>
                <p>Happy swimming!</p>
            </div>
        );
    }

    const sortedAlerts: {
        [alertTypeId: string]: SafeswimAlert & { locationNames: string[] };
    } = {};

    hazardAlerts.forEach((a) => {
        if (!sortedAlerts[a.alertTypeId]) {
            sortedAlerts[a.alertTypeId] = { ...a, locationNames: [] };
        }

        a.locations.forEach((locationId) => {
            if (
                sortedAlerts[a.alertTypeId].locations.indexOf(
                    Number(locationId)
                ) === -1
            ) {
                sortedAlerts[a.alertTypeId].locationNames.push(
                    locationObjects[Number(locationId)].name
                );
            }
        });
    });

    blackOverflows.forEach((a) => {
        if (!sortedAlerts["overflow"]) {
            sortedAlerts["overflow"] = { ...a, locationNames: [] };
        }

        a.locations.forEach((locationId) => {
            if (
                sortedAlerts["overflow"].locations.indexOf(
                    Number(locationId)
                ) === -1
            ) {
                sortedAlerts["overflow"].locationNames.push(
                    locationObjects[Number(locationId)].name
                );
            }
        });
    });

    return (
        <div className={styles.alerts}>
            {sortBy(Object.values(sortedAlerts), ["ordinal"]).map((a, i) => {
                let icon = <img src={a.imageUrl || generic} alt={"Alert"} />;

                // let title = a.title;
                let description = a.description;

                if (a.value === "dangerous_conditions") {
                    icon = <RedFlag />;
                } else if (Number(a.value) === 3) {
                    icon = (
                        <WaterQuality
                            level={WaterQualityLevel.VERY_HIGH_RISK}
                        />
                    );
                    // title = "Do not swim";
                    // description = "Wastewater overflow detected nearby";
                    description =
                        description ===
                        "Fault in wastewater network, swimming not advised."
                            ? "Wastewater overflow detected nearby"
                            : "Very high risk of illness. Do not swim.";
                }

                return (
                    <div
                        className={classNames(styles.alert_item, {
                            [styles.black]: a.alertType === "water_quality",
                            [styles.yellow]: a.alertType === "hazards"
                        })}
                        key={`alert-${i}`}
                    >
                        {(a.value as string).includes("tsunami") ? (
                            <Tsunami
                                alert={a}
                                locationObjects={locationObjects}
                            />
                        ) : (
                            <>
                                <div className={styles.title}>
                                    <div
                                        className={classNames(styles.icon, {
                                            [styles.yellow_icon]:
                                                a.alertType === "hazards"
                                        })}
                                    >
                                        {icon}
                                    </div>
                                    <div className={styles.text}>
                                        <h4>
                                            {a.alertType === "water_quality"
                                                ? "Do not swim"
                                                : "Safety warning"}
                                        </h4>
                                    </div>
                                </div>
                                <p className={styles.description}>
                                    {description}
                                </p>
                                <div className={styles.content}>
                                    {/* <p className={styles.description}>
                                        {description}
                                    </p> */}
                                    <p className={styles.bold}>
                                        {a.locationNames.length === 1
                                            ? "1 location affected:"
                                            : `${a.locationNames.length} locations
                                        affected:`}
                                    </p>
                                    <div className={styles.divider} />
                                    <ul className={styles.locations}>
                                        {a.locationNames.sort().map((l) => (
                                            <li
                                                className={styles.pill}
                                                key={`${a.value}-${l}`}
                                            >
                                                {l}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}
                    </div>
                );
            })}
            {/* <div className={styles.end_of_warnings}>
                <p className={styles.text_small}>No more warnings</p>
            </div> */}
        </div>
    );
};

export default function AlertsModal(props: { show: boolean }) {
    const { hazardAlerts, locationObjects, waterQualityAlerts } =
        useApiContext();
    const { show } = props;
    const { hideModal } = useModalContext();

    return (
        <Modal
            isOpen={show}
            onClose={() => hideModal(ModalActionType.ALERTS_MODAL)}
        >
            <Header />
            <p className={styles.strapline}>warnings</p>
            <Hazards
                hazardAlerts={hazardAlerts}
                waterQualityAlerts={waterQualityAlerts}
                locationObjects={locationObjects}
            />
            <BackButtonDrawer
                paddingBottom
                noMarginTop
                onClick={() => hideModal(ModalActionType.ALERTS_MODAL)}
            />
        </Modal>
    );
}
