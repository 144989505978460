import classNames from "classnames";
import styles from "./BackButtonDrawer.module.scss";

export const BackButtonDrawer = ({
    onClick,
    paddingBottom,
    noMarginTop,
    noLine,
    noParentPadding
}: {
    onClick: () => void;
    paddingBottom?: boolean;
    noLine?: boolean;
    noMarginTop?: boolean;
    noParentPadding?: boolean;
}) => {
    return (
        <>
            <div
                className={classNames(styles.btnWrapper, {
                    [styles.padding]: paddingBottom,
                    [styles.no_margin_top]: noMarginTop,
                    [styles.no_parent_padding]: noParentPadding,
                    [styles.noLine]: noLine
                })}
            >
                <button onClick={onClick}>Back to map</button>
            </div>
        </>
    );
};

export const EnterButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <div className={styles.btnWrapperSmall}>
            <button onClick={onClick}>Enter</button>
        </div>
    );
};
