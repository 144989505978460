import bbqIcon from "../resources/images/facilities/bbq.png";
import shopsIcon from "../resources/images/facilities/shops_cafes.png";
import toiletIcon from "../resources/images/facilities/toilet_block.png";
import showerIcon from "../resources/images/facilities/shower.png";
import boatIcon from "../resources/images/facilities/boat_ramp.png";
import lifeGuardIcon from "../resources/images/facilities/lifeguards.png";
import campingIcon from "../resources/images/facilities/onsite_camping.png";
import playgroundIcon from "../resources/images/facilities/playground.png";
import changingIcon from "../resources/images/facilities/changing_facilities.png";
import aidIcon from "../resources/images/facilities/first_aid_station.png";
import lifeSaveIcon from "../resources/images/facilities/lifesaving_equipment.png";
import parkingIcon from "../resources/images/facilities/parking.png";
import picnicIcon from "../resources/images/facilities/picnic_area.png";
import publicTransportIcon from "../resources/images/facilities/public_transport.png";
import recyclingIcon from "../resources/images/facilities/recycling_facilities.png";
import swimmingIcon from "../resources/images/facilities/swimming_pontoon.png";
import wheelchairIcon from "../resources/images/facilities/wheelchair_accessible_beach.png";
import wheelchairToiletIcon from "../resources/images/facilities/wheelchair_accessible_toilets.png";

export const BEACH_FEATURES: {
    [key: string]: { id: string; label: string; image: string };
} = {
    gas_bbq: {
        id: "bbq",
        label: "Gas BBQ",
        image: bbqIcon
    },
    shops_nearby: {
        id: "shops_nearby",
        label: "Shops Nearby",
        image: shopsIcon
    },
    toilet_block: {
        id: "toilet_block",
        label: "Toilet Block",
        image: toiletIcon
    },
    shower: {
        id: "shower",
        label: "Shower",
        image: showerIcon
    },
    boat_ramp: {
        id: "boat_ramp",
        label: "Boat Ramp",
        image: boatIcon
    },
    life_guards: {
        id: "life_guards",
        label: "Lifeguards",
        image: lifeGuardIcon
    },
    onsite_camping: {
        id: "onsite_camping",
        label: "Onsite Camping",
        image: campingIcon
    },
    playground: {
        id: "playground",
        label: "Playground",
        image: playgroundIcon
    },
    changing_facilities: {
        id: "changing_facilities",
        label: "Changing Facilities",
        image: changingIcon
    },
    first_aid_station: {
        id: "first_aid_station",
        label: "First aid Station",
        image: aidIcon
    },
    lifesaving_equipment: {
        id: "lifesaving_equipment",
        label: "Lifesaving Equipment",
        image: lifeSaveIcon
    },
    parking: {
        id: "parking",
        label: "Parking",
        image: parkingIcon
    },
    picnic_area: {
        id: "picnic_area",
        label: "Picnic Area",
        image: picnicIcon
    },
    public_transport: {
        id: "public_transport",
        label: "Public Transport",
        image: publicTransportIcon
    },
    recycling_facilities: {
        id: "recycling_facilities",
        label: "Recycling Facilities",
        image: recyclingIcon
    },
    swimming_pontoon: {
        id: "swimming_pontoon",
        label: "Swimming Pontoon",
        image: swimmingIcon
    },
    wheelchair_accessible_beach: {
        id: "wheelchair_accessible_beach",
        label: "Wheelchair Accessible Beach",
        image: wheelchairIcon
    },
    wheelchair_accessible_toilets: {
        id: "wheelchaid_accessible_toilets",
        label: "Wheelchair Accessible Toilets",
        image: wheelchairToiletIcon
    }
};
