import React, { ReactElement } from "react";
import classNames from "classnames";
import styles from "./IconButton.module.scss";

type ButtonProps = {
    text: string;
    icon: ReactElement | string;
    onClick: (e?: any) => void;
    className?: string;
    ariaLabel?: string;
};

const Icon = (props: { icon: string | ReactElement }) => {
    if (typeof props.icon === "string") {
        return <img className={styles.icon} src={props.icon} alt={""} />;
    }

    return props.icon;
};

export default function IconButton(props: ButtonProps) {
    return (
        <button
            onClick={props.onClick}
            className={classNames(styles.icon_button, props.className)}
            aria-label={props.ariaLabel ?? props.text}
        >
            {<Icon icon={props.icon} />}
            <span className={styles.text}>{props.text}</span>
        </button>
    );
}
