import { useMemo } from "react";
import { format, set } from "date-fns";
import { useApiContext } from "../../../context/api";
import SafeswimLocation, {
    Forecast,
    ForecastItem
} from "../../../types/SafeswimLocation";
import styles from "./WaterQualityCard.module.scss";
import {
    containsTag,
    getAlertForecast,
    getCurrentWaterQualityLevel,
    getWaterQualityLevelIgnoreAlerts,
    getWaterQualityText,
    WaterQualityLevel
} from "../../../utils/location";
import classNames from "classnames";
import { utcToZonedTime } from "date-fns-tz";
import WaterQualityIcon from "../../images/icons/WaterQuality";
import SafeswimIcon from "../../images/icons/SafeswimIcon";
// import ChevronRight from "../../images/chevrons/ChevronRight";
// import isEmpty from "lodash/isEmpty";

type Props = {
    location?: SafeswimLocation;
    onLearnMoreClick?: () => void;
    forecast?: Forecast;
};
type ForecastList = Array<ForecastListItem>;
type ForecastListItem = {
    time: string;
    level: string;
    label: string;
    date: string;
};

// const ForecastList = ({ items }: { items: ForecastListItem[] }) => {
//     const ref = useRef<HTMLDivElement>(null);

//     useEffect(() => {
//         if (ref.current) {
//             const nowHours = new Date().getHours();
//             const now = set(new Date(), {
// hours: nowHours % 2 === 0 ? nowHours - 1 : nowHours,
//                 minutes: 0,
//                 seconds: 0,
//                 milliseconds: 0
//             });

//             const element = document.getElementById(now.toISOString());
//             if (element) {
//                 const rect = element.getBoundingClientRect();
//                 const scrollX = rect.x - rect.width / 2;
//                 if (scrollX > 0) {
//                     ref.current.scrollTo(scrollX, 0);
//                 }
//             }
//         }
//     }, [ref]);

//     const getColourClasses = (level?: string) =>
//         classNames({
//             [styles.green]: level === WaterQualityLevel.GOOD,
//             [styles.red]: level === WaterQualityLevel.HIGH_RISK,
//             [styles.dark_grey]: level === WaterQualityLevel.VERY_HIGH_RISK,
//             [styles.grey]: level === WaterQualityLevel.UNAVAILABLE || !level
//         });

//     return (
//         <div ref={ref} className={styles.forecast_container}>
//             {items.map((f) => (
//                 <div
//                     id={f.date}
//                     className={styles.forecast}
//                     key={`wq-${f.date}`}
//                 >
//                     <div>
//                         {f.time.split(/\n/).map((l, i) => (
//                             <div
//                                 className={classNames(styles.date_time, {
//                                     [styles.time_style]: i === 1
//                                 })}
//                                 key={`${f.time}-${i}`}
//                             >
//                                 {l}
//                                 <br />
//                             </div>
//                         ))}
//                     </div>
//                     <WaterQualityIcon
//                         level={f.level}
//                         className={getColourClasses(f.level)}
//                     />
//                     <div className={styles.level}>{f.label}</div>
//                 </div>
//             ))}
//         </div>
//     );
// };

export default function WaterQualityCard(props: Props) {
    const { location, onLearnMoreClick } = props;
    const { alerts } = useApiContext();

    const waterQualityLevel = useMemo((): WaterQualityLevel | undefined => {
        if (
            containsTag(location, "no_water_quality") ||
            containsTag(location, "fresh_water_hazard") ||
            !location
        ) {
            return undefined;
        }
        return getCurrentWaterQualityLevel(location);
    }, [alerts, location]);

    const containerClass = classNames(styles.container, {
        [styles.low_risk]: waterQualityLevel === WaterQualityLevel.GOOD,
        [styles.high_risk]: waterQualityLevel === WaterQualityLevel.HIGH_RISK,
        [styles.very_high_risk]:
            waterQualityLevel === WaterQualityLevel.VERY_HIGH_RISK,
        [styles.unknown]: waterQualityLevel === WaterQualityLevel.UNAVAILABLE,
        [styles.not_monitored]:
            !waterQualityLevel || containsTag(location, "fresh_water_hazard")
    });

    const getMainColourClasses = (level?: string) =>
        classNames({
            [styles.green]: level === WaterQualityLevel.GOOD,
            [styles.red]: level === WaterQualityLevel.HIGH_RISK,
            [styles.black]: level === WaterQualityLevel.VERY_HIGH_RISK,
            [styles.grey]: level === WaterQualityLevel.UNAVAILABLE || !level
        });

    // let items: ForecastListItem[] = [];

    if (!location || containsTag(location, "fresh_water_hazard")) {
        return null;
    }

    // const today = new Date();
    // const tomorrow = addDays(today, 1);
    // const dayAfter = addDays(today, 2);

    const isPermanentRed =
        waterQualityLevel === WaterQualityLevel.HIGH_RISK &&
        location.isPermanent &&
        location.permanentWaterQuality !== undefined &&
        location.permanentWaterQuality !== null &&
        location.permanentWaterQuality > 1;
    const wording = getWording(waterQualityLevel, isPermanentRed);

    // if (location.isPermanent) {
    //     items = [
    //         ...getPermanentWaterQualityForecast(location, today),
    //         ...getPermanentWaterQualityForecast(location, tomorrow),
    //         ...getPermanentWaterQualityForecast(location, dayAfter)
    //     ];
    // } else if (
    //     !location.unavailable &&
    //     !!forecast &&
    //     !!forecast.today.water &&
    //     !isEmpty(forecast.today.water)
    // ) {
    //     items = [
    //         ...getWaterQualityForecast(location, forecast.today || {}, today),
    //         ...getWaterQualityForecast(
    //             location,
    //             forecast.tomorrow || {},
    //             tomorrow
    //         ),
    //         ...getWaterQualityForecast(
    //             location,
    //             forecast.dayAfter || {},
    //             dayAfter
    //         )
    //     ];
    // }

    // let chevronColour = "#769d1c";

    // if (waterQualityLevel === WaterQualityLevel.HIGH_RISK) {
    //     chevronColour = "#de0a2b";
    // } else if (waterQualityLevel === WaterQualityLevel.VERY_HIGH_RISK) {
    //     chevronColour = "#23221f";
    // }

    return (
        <div className={containerClass} onClick={() => onLearnMoreClick?.()}>
            <div className={styles.header}>
                {waterQualityLevel ? (
                    <WaterQualityIcon
                        level={waterQualityLevel}
                        className={getMainColourClasses(waterQualityLevel)}
                        isPermanent={isPermanentRed}
                    />
                ) : (
                    <SafeswimIcon />
                )}

                <div className={styles.title}>
                    <div className={styles.strapline}>
                        Current Water quality
                    </div>
                    <h5 className={styles.subtitle}>{wording.name}</h5>
                </div>

                {/* {waterQualityLevel !== WaterQualityLevel.UNAVAILABLE &&
                    !containsTag(location, "no_water_quality") && (
                        <div className={styles.chevron_right}>
                            <ChevronRight
                                stroke={chevronColour}
                                strokeWidth={2}
                            />
                        </div>
                    )} */}
            </div>
            {!wording.name && (
                <span className={styles.description}>
                    {wording.description}
                </span>
            )}
            {waterQualityLevel &&
                waterQualityLevel !== WaterQualityLevel.UNAVAILABLE &&
                !containsTag(location, "no_water_quality") &&
                waterQualityLevel !== WaterQualityLevel.GOOD && (
                    <div className={styles.learn_more}>
                        <button>Learn more</button>
                    </div>
                )}
        </div>
    );
}

export function getWording(
    level?: WaterQualityLevel,
    isPermanentRed?: boolean
) {
    const wordings = {
        low_risk: {
            name: "Good water quality",
            description:
                "Levels of Faecal Indicator Bacteria are predicted to meet national guidelines for swimming."
        },
        high_risk: {
            name: (
                <>
                    Swimming not advised{"\n"}
                    <span className={styles.no_wrap}>(High risk)</span>
                </>
            ),
            description:
                "Levels of Faecal Indicator Bacteria are predicted to exceed national guidelines for swimming."
        },
        very_high_risk: {
            name: (
                <>
                    Do not swim{"\n"}
                    <span className={styles.no_wrap}>
                        (Wastewater Overflow)
                    </span>
                </>
            ),
            description:
                "Levels of Faecal Indicator Bacteria are predicted to exceed national guidelines for swimming. Very high risk of illness from swimming."
        },
        unknown: {
            name: "Data temporarily unavailable",
            description:
                "Safeswim is currently experiencing a temporary technical problem at this location. \n\nWe are working on a solution and will get the water quality predictions back up and running at this location as soon as we can. We apologise for the interruption in service."
        },
        not_monitored: {
            name: "",
            description:
                "Please contact your local regional council for the most recent water quality information at this location."
        },
        permanent_red: {
            name: "Long-term alert",
            description:
                "Water quality consistently poor, swimming not advised."
        }
    };

    if (!level) {
        return wordings.not_monitored;
    }

    if (isPermanentRed) {
        return wordings.permanent_red;
    }

    let wording: { name: JSX.Element | string; description: string } =
        wordings.unknown;

    switch (level) {
        case WaterQualityLevel.GOOD:
            wording = wordings.low_risk;
            break;
        case WaterQualityLevel.HIGH_RISK:
            wording = wordings.high_risk;
            break;
        case WaterQualityLevel.VERY_HIGH_RISK:
            wording = wordings.very_high_risk;
            break;
        default:
            break;
    }

    return wording;
}

export function getPermanentWaterQualityForecast(
    location: SafeswimLocation,
    date: Date
): ForecastList {
    date = set(date, {
        minutes: 0,
        seconds: 0,
        milliseconds: 0
    });
    const rows: ForecastList = [];
    const level = getCurrentWaterQualityLevel(location);
    const label = getWaterQualityText(location)?.short;
    let nowHours = new Date().getHours();

    if (nowHours < 5) {
        nowHours = 5;
    } else if (nowHours > 21) {
        nowHours = 21;
    }

    const now = set(
        utcToZonedTime(
            set(new Date(), { minutes: 0, seconds: 0, milliseconds: 0 }),
            "Pacific/Auckland"
        ),
        {
            hours: nowHours % 2 === 0 ? nowHours - 1 : nowHours,
            minutes: 0,
            milliseconds: 0
        }
    );

    for (let i = 5; i < 23; i += 2) {
        const itemDate = set(utcToZonedTime(date, "Pacific/Auckland"), {
            hours: i
        });
        const formattedDay = format(itemDate, "eee");
        const formattedTime = format(itemDate, "haaa");

        rows.push({
            level,
            label,
            time:
                itemDate.toISOString() === now.toISOString()
                    ? `${formattedDay} \n Now`
                    : `${formattedDay} \n ${formattedTime}`,
            date: itemDate.toISOString()
        });
    }

    return rows;
}

export function getWaterQualityForecast(
    location: SafeswimLocation,
    forecast: ForecastItem,
    date: Date
) {
    date = set(date, {
        minutes: 0,
        seconds: 0,
        milliseconds: 0
    });
    const rows: ForecastList = [];
    const alert = (location.alerts?.waterQuality || [])[0] || null;
    let nowHours = new Date().getHours();

    if (nowHours < 5) {
        nowHours = 5;
    } else if (nowHours > 21) {
        nowHours = 21;
    }

    const now = set(
        utcToZonedTime(
            set(new Date(), { minutes: 0, seconds: 0, milliseconds: 0 }),
            "Pacific/Auckland"
        ),
        {
            hours: nowHours % 2 === 0 ? nowHours - 1 : nowHours,
            minutes: 0,
            milliseconds: 0
        }
    );

    for (let i = 5; i < 23; i += 2) {
        const itemDate = set(utcToZonedTime(date, "Pacific/Auckland"), {
            hours: i
        });
        const formattedDay = format(itemDate, "eee");
        const formattedTime = format(itemDate, "haaa");

        if (forecast.water[i.toString()]) {
            let level = getWaterQualityLevelIgnoreAlerts(
                location,
                forecast.water[i.toString()].value
            );
            if (alert) {
                level = getAlertForecast(alert, date, i, level);
            }

            let label = "Low risk";
            if (level === "red") {
                label = "High risk";
            } else if (level === "black") {
                label = "Very high risk";
            } else if (level === "grey") {
                label = "Unavailable";
            }

            rows.push({
                level,
                label,
                time:
                    itemDate.toISOString() === now.toISOString()
                        ? `${formattedDay} \n Now`
                        : `${formattedDay} \n ${formattedTime}`,
                date: itemDate.toISOString()
            });
        } else {
            let level = WaterQualityLevel.UNAVAILABLE;
            let label = "Unavailable";

            if (alert) {
                level = getAlertForecast(alert, date, i);

                if (level === WaterQualityLevel.HIGH_RISK) {
                    label = "High risk";
                } else if (level === WaterQualityLevel.VERY_HIGH_RISK) {
                    label = "Very high risk";
                }
            }

            rows.push({
                level,
                label,
                time:
                    itemDate.toISOString() === now.toISOString()
                        ? `${formattedDay} \n Now`
                        : `${formattedDay} \n ${formattedTime}`,
                date: itemDate.toISOString()
            });
        }
    }

    return rows;
}
