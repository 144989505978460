import { ReactElement, useMemo } from "react";
import classNames from "classnames";
import Logo from "../../resources/images/logos/safeswim_logo.png";
import styles from "./NavBar.module.scss";
import IconButton from "../buttons/iconButton/IconButton";
import StarIcon from "../images/icons/menu/StarIcon";
import AlertIcon from "../images/icons/menu/AlertIcon";
import HamburgerIcon from "../images/icons/menu/HamburgerIcon";
import MapIcon from "../images/icons/menu/MapIcon";
import SearchIcon from "../images/icons/SearchIcon";
import { DrawerActionType, useDrawerContext } from "../../context/drawer";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiContext } from "../../context/api";
import { useMobileState } from "../../hooks/useMobileState";
import { useFavouriteBeachContext } from "../../context/favouriteBeaches";
import Button from "../buttons/button/Button";
import Close from "../images/icons/Close";
import { ModalActionType, useModalContext } from "../../context/modal";

enum ActiveRoute {
    MAP = "MAP",
    FAVOURITES = "FAVOURITES",
    ALERTS = "ALERTS",
    MORE = "MORE"
}

type Props = {
    mapReady: boolean;
    lightMode?: boolean;
};

export default function NavBar(props: Props): ReactElement {
    const {
        show,
        hideAll,
        welcomeDrawerOpen,
        menuDrawerOpen,
        favouriteBeachDrawerOpen,
        hideAllRightHandDrawers
    } = useDrawerContext();
    const { showModal } = useModalContext();
    const { setAddingFavouriteBeach } = useFavouriteBeachContext();
    const { hazardAlerts, waterQualityAlerts } = useApiContext();
    const location = useLocation();
    const navigate = useNavigate();
    const { isMobile } = useMobileState();
    const drawerActive =
        welcomeDrawerOpen || favouriteBeachDrawerOpen || menuDrawerOpen;

    const alertCount = useMemo(() => {
        let count = 0;

        if (waterQualityAlerts && waterQualityAlerts.length) {
            count += waterQualityAlerts.filter(
                (a) => Number(a.value) === 3
            ).length;
        }

        if (hazardAlerts && hazardAlerts.length) {
            count += hazardAlerts.length;
        }

        return count;
    }, [hazardAlerts, waterQualityAlerts]);

    let activeNavItem = "";
    if (location.pathname === "/" && !drawerActive) {
        activeNavItem = ActiveRoute.MAP;
    } else if (favouriteBeachDrawerOpen) {
        activeNavItem = ActiveRoute.FAVOURITES;
    } else if (menuDrawerOpen) {
        activeNavItem = ActiveRoute.MORE;
    }

    const onDrawerClick = (type?: DrawerActionType) => () => {
        if (location.pathname !== "/") {
            navigate("/", { replace: true });
        }

        if (isMobile) {
            hideAll();
        } else {
            hideAllRightHandDrawers();
        }

        // Don't re-open drawer when clicking on an open drawer's button again
        if (menuDrawerOpen && type === DrawerActionType.MENU_DRAWER) {
            return;
        }

        if (!type) {
            hideAll();
        } else {
            show(type);
            setAddingFavouriteBeach(false);
        }
    };

    return (
        <header
            className={classNames(styles.container, {
                [styles.loading_map]: !props.mapReady,
                [styles.lightMode]: props.lightMode
            })}
        >
            <div className={classNames(styles.nav_left, styles.hide_on_mobile)}>
                <div className={styles.logo}>
                    <a href={"/"}>
                        <img src={Logo} alt={"Safeswim"} />
                    </a>
                </div>
            </div>
            <div className={styles.nav_right}>
                <IconButton
                    className={classNames(styles.button, styles.map_button, {
                        [styles.active]: activeNavItem === ActiveRoute.MAP
                    })}
                    text={"Map"}
                    icon={
                        <MapIcon
                            active={activeNavItem === ActiveRoute.MAP}
                            className={classNames(styles.icon)}
                        />
                    }
                    onClick={onDrawerClick()}
                />
                <IconButton
                    className={classNames(
                        styles.button,
                        styles.favourites_button,
                        {
                            [styles.active]:
                                activeNavItem === ActiveRoute.FAVOURITES
                        }
                    )}
                    text={"Favourites"}
                    icon={
                        <StarIcon
                            active={activeNavItem === ActiveRoute.FAVOURITES}
                            className={styles.icon}
                        />
                    }
                    onClick={onDrawerClick(
                        DrawerActionType.FAVOURITE_BEACH_DRAWER
                    )}
                />
                <div
                    className={styles.water_safety}
                    onClick={() => {
                        show(DrawerActionType.WATER_SAFETY_101_DRAWER_RIGHT);
                    }}
                >
                    <span>Beach Safety</span>
                </div>
                <IconButton
                    className={classNames(styles.button, {
                        [styles.active]: activeNavItem === ActiveRoute.ALERTS
                    })}
                    text={"Warnings"}
                    icon={
                        <AlertIcon
                            active={activeNavItem === ActiveRoute.ALERTS}
                            className={styles.icon}
                            alertCount={alertCount}
                        />
                    }
                    onClick={() => showModal(ModalActionType.ALERTS_MODAL)}
                />
                <div
                    className={classNames(
                        styles.search_button_group,
                        styles.hide_on_mobile
                    )}
                >
                    <Button
                        variant={"filled"}
                        className={styles.search_button}
                        onClick={() => showModal(ModalActionType.SEARCH_MODAL)}
                        icon={<SearchIcon />}
                        size="lg"
                        iconSize="lg"
                    >
                        <span>Find a beach</span>
                    </Button>
                </div>
                <IconButton
                    className={classNames(styles.button, {
                        [styles.active]: activeNavItem === ActiveRoute.MORE
                    })}
                    text={isMobile ? "More" : ""}
                    ariaLabel={"Menu"}
                    icon={
                        activeNavItem === ActiveRoute.MORE ? (
                            <Close
                                className={classNames(
                                    styles.icon,
                                    styles.menu_button
                                )}
                            />
                        ) : (
                            <HamburgerIcon
                                className={classNames(
                                    styles.icon,
                                    styles.menu_button
                                )}
                            />
                        )
                    }
                    onClick={onDrawerClick(DrawerActionType.MENU_DRAWER)}
                />
            </div>
        </header>
    );
}
