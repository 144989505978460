import React, {
    PropsWithChildren,
    ReactElement,
    useEffect,
    useRef,
    useState
} from "react";
import styles from "./Map.module.scss";
import {
    getMarkerIconSize,
    DEFAULT_LAT,
    DEFAULT_LNG,
    CENTERED_MAP_COORDS,
    getMarkerIcon
} from "../../utils/map";
import SpecialEvent from "../../types/SpecialEvent";
import SafeswimLocation from "../../types/SafeswimLocation";
import { useMapContext } from "../../context/map";
import { useNavigatorContext } from "../../context/navigator";
import { useNavigate, useParams } from "react-router-dom";
import MapKeyButton from "./mapKeyButton/MapKeyButton";
import CentreButton from "./centreButton/CentreButton";
import myLocationIcon from "../../resources/images/random/my_location.svg";
import { useApiContext } from "../../context/api";
import FavBeaches from "./favBeaches/FavBeaches";
import ZoomButtons from "./zoomButtons/ZoomButtons";
import { LocationMarker } from "./marker/LocationMarker";
import { SpecialEventMarker } from "./marker/SpecialEventMarker";
import SpecialEvents from "./pins/SpecialEvents";
import { fireTagEvent } from "../../utils/tagmanager";

interface MapProps {
    selectedLocation: SafeswimLocation | undefined;
    onSpecialEventClick?: (s: SpecialEvent) => void;
    onLocationClick: (l: SafeswimLocation) => void;
    isMobile: boolean;
}

export default function Map(props: PropsWithChildren<MapProps>): ReactElement {
    const [zoom, setZoom] = useState(12);
    const mapRef = useRef(null);
    const { lat, lng } = useNavigatorContext();
    const { map, setMap, lifeguardedFilterActive, setLifeguardFilter } =
        useMapContext();
    const params = useParams();
    const { alerts, locations, locationObjects, specialEvents } =
        useApiContext();
    const { onSpecialEventClick, onLocationClick, isMobile } = props;
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (mapRef.current) {
                const map = new window.google.maps.Map(mapRef.current, {
                    zoom,
                    center: {
                        lat: CENTERED_MAP_COORDS.LAT,
                        lng: CENTERED_MAP_COORDS.LNG
                    },
                    gestureHandling: "greedy",
                    clickableIcons: false,
                    mapTypeControl: false,
                    disableDefaultUI: true,
                    styles: [
                        {
                            featureType: "administrative",
                            elementType: "geometry",
                            stylers: [
                                {
                                    visibility: "off"
                                }
                            ]
                        },
                        {
                            featureType: "poi",
                            stylers: [
                                {
                                    visibility: "off"
                                }
                            ]
                        },
                        {
                            featureType: "road",
                            elementType: "labels.icon",
                            stylers: [
                                {
                                    visibility: "off"
                                }
                            ]
                        },
                        {
                            featureType: "transit",
                            stylers: [
                                {
                                    visibility: "off"
                                }
                            ]
                        },
                        {
                            featureType: "landscape",
                            elementType: "labels",
                            stylers: [{ visibility: "off" }]
                        }
                    ],
                    maxZoom: 18,
                    minZoom: 8,
                    zoomControl: false
                });
                setMap(map);

                map.addListener("zoom_changed", () => {
                    if (map) {
                        const zoom = map.getZoom() || 12;
                        setZoom(zoom);
                    }
                });
            }
        })();
    }, []);

    useEffect(() => {
        const { pathname } = window.location;

        if (
            (pathname.includes("water") ||
                pathname.includes("alert") ||
                pathname.includes("bp")) &&
            params.locationId &&
            locations &&
            locations.length > 0
        ) {
            const location = locationObjects[Number(params.locationId)];
            if (location) {
                onLocationClick(location);
            }

            if (pathname.includes("bp")) {
                fireTagEvent("bp-app", {
                    locationId: Number(params.locationId)
                });
                if (location && location.lat && location.lng) {
                    map?.setCenter({ lat: location.lat, lng: location.lng });
                }
                navigate("/", { replace: true });
            }
        }
    }, [locations, alerts, window.location.pathname]);

    useEffect(() => {
        if (map && lat !== DEFAULT_LAT && lng !== DEFAULT_LNG) {
            map.panTo({ lat, lng });
            new google.maps.Marker({
                icon: myLocationIcon,
                position: {
                    lat: lat || DEFAULT_LAT,
                    lng: lng || DEFAULT_LNG
                },
                map
            });
        }
    }, [lat, lng, map]);

    useEffect(() => {
        if (zoom > 12 && lifeguardedFilterActive) {
            setLifeguardFilter(false);
        }
    }, [zoom, lifeguardedFilterActive]);

    const onCentreClick = () => {
        map?.setCenter({ lat: lat || DEFAULT_LAT, lng: lng || DEFAULT_LNG });
    };
    const onZoomIn = () => {
        map?.setZoom(zoom + 1);
    };
    const onZoomOut = () => {
        map?.setZoom(zoom - 1);
    };

    return (
        <>
            <div ref={mapRef} className={styles.map} />
            <div className={styles.map_controls}>
                <MapKeyButton />
                {!isMobile && (
                    <>
                        <CentreButton onClick={onCentreClick} />
                        <ZoomButtons
                            onZoomIn={onZoomIn}
                            onZoomOut={onZoomOut}
                        />
                    </>
                )}
            </div>
            <FavBeaches onLocationClick={onLocationClick} />
            {locations &&
                alerts &&
                locations.map((l) => (
                    <LocationMarker
                        key={l.id}
                        map={map}
                        zoom={zoom}
                        location={l}
                        position={{ lat: l.lat, lng: l.lng }}
                        onClick={onLocationClick}
                        lifeguardedFilterActive={lifeguardedFilterActive}
                        icon={{
                            url: getMarkerIcon(l),
                            ...getMarkerIconSize(zoom, l)
                        }}
                    />
                ))}

            {specialEvents &&
                specialEvents.map((s) => (
                    <SpecialEventMarker
                        key={s.id}
                        zoom={zoom}
                        specialEvent={s}
                        map={map}
                        position={{
                            lat: Number(s.latLng.lat),
                            lng: Number(s.latLng.lng)
                        }}
                        icon={{
                            ...getMarkerIconSize(zoom),
                            url: s.rahui
                                ? SpecialEvents.rahui
                                : SpecialEvents.specialEvent
                        }}
                        onClick={onSpecialEventClick}
                    />
                ))}
        </>
    );
}
