import { FC, useEffect, useState } from "react";
import SafeswimLocation from "../../../types/SafeswimLocation";
import { useMapContext } from "../../../context/map";
import {
    containsTag,
    getCurrentWaterQualityLevel,
    WaterQualityLevel
} from "../../../utils/location";

interface MarkerProps extends google.maps.MarkerOptions {
    zoom: number;
    location?: SafeswimLocation;
    onClick?: (l: SafeswimLocation) => void;
    lifeguardedFilterActive?: boolean;
}

function createInfoWindow(
    map: google.maps.Map,
    marker: google.maps.Marker,
    location: SafeswimLocation,
    onClick?: (l: SafeswimLocation) => void
) {
    const infoWindow = new google.maps.InfoWindow({
        content: `<span>${location.name}</span>`,
        position: marker.getPosition()
    });

    marker.addListener("mouseover", () => {
        infoWindow.open({ anchor: marker, map, shouldFocus: true });
    });

    marker.addListener("mouseout", () => {
        infoWindow.close();
    });

    marker.addListener("click", () => {
        onClick?.(location);
        const position = marker.getPosition();
        if (position) {
            map.panTo(position);
        }
    });
}

export const LocationMarker: FC<MarkerProps> = (options) => {
    const { location, map, onClick } = options;
    const [marker, setMarker] = useState<google.maps.Marker>();
    const [circle, setCircle] = useState<google.maps.Circle | null>(null);
    const { lifeguardedFilterActive } = useMapContext();

    useEffect(() => {
        if (!marker) {
            const _marker = new google.maps.Marker();
            setMarker(_marker);
        }

        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker && location) {
            createInfoWindow(map as google.maps.Map, marker, location, onClick);
            marker.setOptions(options);
        }
    }, [marker, location]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
            if (
                options.lifeguardedFilterActive &&
                !containsTag(location, "sls_patrolled")
            ) {
                marker.setMap(null);
            }
        }
    }, [options]);

    useEffect(() => {
        if (marker) {
            if (
                lifeguardedFilterActive &&
                location &&
                !containsTag(location, "sls_patrolled")
            ) {
                marker.setMap(null);
                const currentWaterQualityLevel =
                    getCurrentWaterQualityLevel(location);
                let colour = "#8E9BA4";

                if (currentWaterQualityLevel === WaterQualityLevel.HIGH_RISK) {
                    colour = "#DE0A2B";
                } else if (
                    currentWaterQualityLevel ===
                    WaterQualityLevel.VERY_HIGH_RISK
                ) {
                    colour = "#23221F";
                } else if (
                    currentWaterQualityLevel === WaterQualityLevel.GOOD
                ) {
                    colour = "#5B9C33";
                }

                const circle = new google.maps.Circle({
                    strokeColor: colour,
                    fillColor: colour,
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    map: map as google.maps.Map,
                    center: new google.maps.LatLng({
                        lat: location.lat,
                        lng: location.lng
                    }),
                    strokeWeight: 8,
                    strokePosition: 0.0,
                    radius: 50
                });

                setCircle(circle);
            } else {
                marker.setMap(map as google.maps.Map);
                circle?.setMap(null);
            }
        }
    }, [lifeguardedFilterActive]);

    return null;
};
