import { DrawerActionType, useDrawerContext } from "../../../context/drawer";
import {
    HomeButton,
    LargeBackButton
} from "../../buttons/drawerButton/DrawerButton";

import { BackButtonDrawer } from "../BackButtonDrawer/BackButtonDrawer";
import Drawer from "../Drawer";
import SafestSwim from "../../../resources/images/safety/safest-swim.jpeg";
import floatFirstImage from "../../../resources/images/safety/float-first.png";
import floatImage from "../../../resources/images/safety/float-image.jpeg";
import getHelp from "../../../resources/images/safety/get-help.jpeg";
import ifInDoubt from "../../../resources/images/safety/if_in_doubt.jpeg";
import styles from "./WaterSafety101Drawer.module.scss";
import takeCare from "../../../resources/images/safety/take-care.jpeg";

export default function WaterSafety101Drawer({
    openLeft
}: {
    openLeft?: boolean;
}) {
    const {
        waterSafety101DrawerOpen,
        hide,
        waterSafety101DrawerRightOpen,
        hideAllRightHandDrawers
    } = useDrawerContext();

    const closeDrawer = () => {
        if (openLeft) {
            hide(DrawerActionType.WATER_SAFETY_101_DRAWER);
            return;
        }
        hideAllRightHandDrawers();
    };

    return (
        <Drawer
            id={
                openLeft
                    ? DrawerActionType.WATER_SAFETY_101_DRAWER
                    : DrawerActionType.WATER_SAFETY_101_DRAWER_RIGHT
            }
            isOpen={
                openLeft
                    ? !!waterSafety101DrawerOpen
                    : !!waterSafety101DrawerRightOpen
            }
            position={openLeft ? "left" : "right"}
            secondary={openLeft}
        >
            <div className={styles.container}>
                {openLeft ? (
                    <LargeBackButton onClick={closeDrawer} />
                ) : (
                    <HomeButton onClick={closeDrawer} />
                )}
                <h2>Beach Safety</h2>
                <h4>Do you know your beach basics?</h4>
                <p className={styles.subheading}>
                    We know that if you understand and follow this advice, it
                    could save your life.
                </p>
                <p className={styles.strapline}>
                    Beach and Coastal Safety Messages
                </p>
                <ol className={styles.list}>
                    <li>
                        <h5>
                            Learn how the <span>Float first</span> survival
                            response could save your life
                        </h5>
                        <p>
                            <span>Float first</span> gives you the best chance
                            of survival if you are in trouble in the water.
                        </p>
                        <p>
                            Whether you fall in or get caught in a river current
                            or coastal rip, knowing how to respond in the first
                            two minutes can be the difference between life and
                            death.
                        </p>
                        <p>
                            Panic or the effects of cold water shock, can lead
                            to drowning or cardiac arrest. Even the strongest
                            swimmers can be affected.
                        </p>
                        <p>
                            Learn how to <span>Float first</span> - watch this
                            video, today, it could save your life tomorrow
                        </p>
                        <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/O4VDwJ4ZwAg?si=xbi6ExyLyDw7wXy5"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                        ></iframe>
                        <a
                            href="https://www.dpanz.org.nz/float-first/"
                            target="_blank"
                            rel={"noopener noreferrer"}
                        >
                            Access free learning resources including activities,
                            lesson plans, colouring sheets and posters in
                            English, Te Reo Māori and other languages.
                        </a>

                        <img
                            src={floatFirstImage}
                            alt=""
                            className={styles.image}
                        />
                        <p>
                            <span>Float first</span> was developed by water
                            safety educational charity – Drowning Prevention
                            Auckland for Aotearoa, New Zealand and is based on
                            the RNLI’s Float to Live Campaign and research by
                            the University of Portsmouth.
                        </p>
                    </li>

                    <li>
                        <img src={SafestSwim} alt="" />
                        <h5>Find the safest place to swim</h5>
                        <p>
                            Remember if you are heading to the beach, check{" "}
                            <a href="/">www.safeswim.org.nz</a> to find a
                            lifeguarded beach, and always swim between the red
                            and yellow flags, which show the safest place to
                            swim. The surf lifeguards are there to help keep
                            beachgoers safe, by keeping a constant eye on sea as
                            they continuously scan for hazards or people in
                            difficulty, keeping on top of weather forecasts and
                            understanding the swell and tide conditions too.
                        </p>
                    </li>
                    <li>
                        <img src={ifInDoubt} alt="" />
                        <h5>If in doubt, stay out</h5>
                        <p>
                            Waves can be bigger than they look, dangerous rip
                            currents are hard to spot and weather conditions can
                            be unpredictable. If you feel uncomfortable about
                            getting into the water, stay out. It’s better to be
                            safe than sorry. Too many people get into trouble in
                            the water because they overestimate their abilities
                            and underestimate the conditions.
                        </p>
                    </li>
                    <li>
                        <img src={takeCare} alt="" />
                        <h5>Take care of others</h5>
                        <p>
                            Always keep children within arm’s reach in or near
                            the water. Waves can move quickly and unexpectedly
                            and can knock kids off their feet and sweep them
                            away. Everyone has different levels of ability, so
                            watch out for your mates too.
                        </p>
                    </li>
                    <li>
                        <img src={getHelp} alt="" />
                        <h5>Know how to get help</h5>
                        <p>
                            If someone in the water is in trouble and surf
                            lifeguards are on patrol, let them know. If you
                            can’t see any surf lifeguards, call 111 and ask for
                            police. Police have a direct line to surf lifeguards
                            and others who can help.
                        </p>
                        <p>
                            If you’re in the water and in trouble yourself,
                            signal for help.
                        </p>
                    </li>
                </ol>
                <p className={styles.strapline}>
                    Hazard or activity-specific safety messages:
                </p>
                <div className={styles.list_container}>
                    <h5>Open water swimming</h5>
                    <p>
                        Remember these safety tips before open water swimming;
                    </p>
                    <h6>Prepare</h6>
                    <ul>
                        <li>
                            Check the location, tide and weather conditions
                            before you go
                        </li>
                        <li>Wear a brightly coloured cap</li>
                        <li>Use an inflatable safety tow float</li>
                    </ul>
                    <h6>Watch Out</h6>
                    <ul>
                        <li>Swim with a buddy or in a group</li>
                        <li>
                            Tell someone where and when you are going and your
                            expected return time
                        </li>
                    </ul>
                    <h6>Be Aware</h6>
                    <ul>
                        <li>
                            Assess the water conditions and look for hazards
                        </li>
                        <li>
                            Swim outside high activity areas for craft e.g. boat
                            ramps, ski lanes and harbour channels
                        </li>
                    </ul>
                    <h6>Know your Limits</h6>
                    <ul>
                        <li>
                            Test your ability in a pool before the open water
                        </li>
                        <li>
                            Learn how to safely rescue someone and signal for
                            help
                        </li>
                    </ul>
                    <p>
                        Download a free Swimming NZ guide to open water swimming
                        by clicking{" "}
                        <a
                            href="https://www.surflifesaving.org.nz/media/996812/open-water-guidelines.pdf"
                            target="_blank"
                            rel={"noopener noreferrer"}
                        >
                            here.
                        </a>
                    </p>
                </div>
                <div className={styles.list_container}>
                    <h6>Rip currents</h6>
                    <p>
                        Rips are a major hazard on beaches worldwide, including
                        New Zealand and can be deadly. Spotting a rip can be
                        extremely difficult, so SLSNZ recommends learning the
                        3Rs Rip Survival Plan. If you get caught in a rip, it
                        could save your life!
                    </p>
                    <ul>
                        <li>Relax and float to conserve your energy</li>
                        <li>Raise your hand to signal for help</li>
                        <li>
                            Ride the rip until it stops, and you can swim back
                            to shore or help arrives
                        </li>
                    </ul>
                </div>
                <div className={styles.list_container}>
                    <h6>Inflatable toys</h6>
                    <p>
                        Inflatable kayaks, dinghies, and pool toys might be
                        convenient, lightweight, and fun but they also come with
                        risk. Strong currents and winds can easily blow them out
                        to sea, where you can quickly find yourself out of your
                        depth and far away from shore.
                    </p>
                    <h6>No Match for the Surf or Open Sea</h6>
                    <ul>
                        <li>
                            Inflatable dinghies and kayaks are no match for the
                            surf or open seas. The wind can sweep you out to sea
                            fast, and large waves could easily tip you out.
                        </li>
                        <li>
                            Even on the calmest beaches, using inflatable pool
                            toys could be dangerous if an offshore breeze sweeps
                            you or your children out to sea very quickly. Know
                            Their Limits
                        </li>
                        <li>
                            Inflatable dinghies and kayaks aren't a built for
                            the surf or open seas.
                        </li>
                        <li>
                            Make sure what you're doing with your inflatable
                            vessel matches its limits and capabilities, as well
                            as your own skill level.
                        </li>
                        <li>
                            Use inflatable pool toys in the pool not at the
                            beach.
                        </li>
                    </ul>
                    <h6>Keep Calm, Stay on it, Signal for Help!</h6>
                    <p>
                        If you do ignore the safety advice and find yourself
                        being swept out to sea on an inflatable dinghy/kayak:
                    </p>
                    <ul>
                        <li>KEEP CALM</li>
                        <li>STAY ON IT (it will keep you afloat)</li>
                        <li>SIGNAL FOR HELP!</li>
                    </ul>
                    <h6>Kai gathering</h6>
                    <p>
                        For many people kai gathering is an important part of
                        culture and is a popular pastime, however people don’t
                        realise the potential dangers, especially when
                        snorkelling for kai.
                    </p>
                    <ul>
                        <li>Be Prepared</li>
                    </ul>
                    <p>
                        Only go snorkelling for kai when you know how to use
                        your gear safely. If you’re unsure, start with a course,
                        or get an experienced friend to show you the ropes.
                    </p>
                    <ul>
                        <li>Use The Right Equipment</li>
                    </ul>
                    <p>
                        Wear gear that won’t leak or break. Use a catch bag that
                        floats well - it’ll hold your kai and may save your life
                        if you need it. A good wetsuit will keep you warm, help
                        you float, and even help you stay out longer. Use a dive
                        flag too, so others can see you.
                    </p>
                    <ul>
                        <li>Always Snorkel With A Buddy</li>
                    </ul>
                    <p>
                        Stay close to your buddy in the moana as they won’t be
                        able to help you if they don’t know what’s going on.
                        Besides, it’s always more fun with a friend.
                    </p>
                    <ul>
                        <li>Be Aware Of The Dangers</li>
                    </ul>
                    <p>
                        Before you go in check what the tide is doing and how
                        that might affect the area you’re in. Is there a current
                        you need to watch out for? Could waves make things hard
                        for you? If in doubt, don’t go out.
                    </p>
                    <ul>
                        <li>Know Your Limits</li>
                    </ul>
                    <p>
                        The fitter you are, the safer you’ll be. Swimming and
                        snorkelling can be pretty challenging for our bodies.
                        Build up your fitness in the pool first, before hunting
                        for tasty treats in the sea.
                    </p>
                    <h6>Sun protection</h6>
                    <p>
                        New Zealand’s harsh sun during the warmer months and
                        alarming skin cancer rates are why we need to cover up
                        at the beach. Be sun smart and protect your skin and
                        eyes from the suns damaging rays by using a high factor
                        sunscreen and don’t forgot your sunglasses and a hat.
                    </p>
                    <h6>Rock fishing</h6>
                    <p>
                        Rock fishing has been the highest-risk activity for
                        coastal fatal drownings in previous years.
                    </p>
                    <ul>
                        <li>Be Prepared and know how to float</li>
                    </ul>
                    <p>
                        Only go fishing off rocks if you know how to float well.
                        Always wear a lifejacket, it can be a lifesaver, – so
                        use one that’s well fitted and in good condition.
                        Instead of gumboots wear shoes or booties with good grip
                        that are designed for the water. These will help stop
                        you from slipping and won’t fill up with water. Plus, it
                        pays to take a floatation device that could be thrown to
                        you. Even a bucket that floats can be helpful.
                    </p>
                    <ul>
                        <li>Go fishing with a friend</li>
                    </ul>
                    <p>
                        If something happens they can help. If you fall in stay
                        calm, float on your back and ask them to help by
                        throwing you the floatation device. Plus, fishing with a
                        friend makes it all the more fun, right?
                    </p>
                    <ul>
                        <li>Be aware of the dangers</li>
                    </ul>
                    <p>
                        Before you cast off, check what the tide’s doing and
                        think about how it could affect where you’re fishing.
                        Watch the area for a while so you can see what the waves
                        are doing. Never turn your back to the sea as large
                        waves can sweep you off the rocks unexpectedly. If
                        you’re in doubt, don’t go out.
                    </p>
                    <ul>
                        <li>Know your limits</li>
                    </ul>
                    <p>
                        Lastly, if you’re not up for it, or the weather’s
                        looking a bit meh, save it for another day. There’s
                        always tomorrow.
                    </p>
                </div>
                <iframe
                    title={"Have a hmmm Hints - Kaimoana fishing off the rocks"}
                    className={styles.iframe}
                    src="https://www.youtube.com/embed/KeatUKRgQRw?si=FQKawUnfuvJfS_0L"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
                <div className={styles.list_container}>
                    <h6>Surfing</h6>
                    <p>
                        Surfing’s a great way to stay in shape and enjoy nature.
                        Here’s some handy hints that’ll keep you out there.
                    </p>
                    <ul>
                        <li>Check It Before You Charge It</li>
                    </ul>
                    <p>
                        Check for hazards like crowds or rocks before you paddle
                        out. Take it easy on your first couple of waves and only
                        go hard once you know what’s going on out there.
                    </p>
                    <ul>
                        <li>Respect Others And Give Them Space</li>
                    </ul>
                    <p>
                        Lots of injuries (and dings) happen when people drop in
                        on another person’s wave. If someone’s up and riding,
                        leave them to it. Look out for others in case they need
                        help out there.
                    </p>
                    <ul>
                        <li>If In Doubt, Don’t Paddle Out</li>
                    </ul>
                    <p>
                        Before you get in the water, check the conditions to
                        make sure you’re up to it, especially if it’s a spot
                        you’ve never surfed before.
                    </p>
                    <ul>
                        <li>Hold On To Your Board</li>
                    </ul>
                    <p>
                        A runaway board can damage other boards, or worse,
                        injure other surfers. Keep yours close, and always wear
                        a leg rope.
                    </p>
                    <ul>
                        <li>Cover Your Head When You Fall Off</li>
                    </ul>
                    <p>
                        Wiping out happens to the best of us. When you fall off,
                        remember to cover your head so you don’t get an unwanted
                        bump.
                    </p>
                </div>
                <iframe
                    title={"Have a hmmm Hints - Surfing"}
                    className={styles.iframe}
                    src="https://www.youtube.com/embed/Lkz8p-xxx6I?si=C3QacABaNoIqAWEH"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
                {/* Keeping comment till new page is approved */}
                {/* <h2>Beach Safety 101</h2>
            <h4>Do you know your beach basics?</h4>
            <p className={styles.subheading}>
                We know that if you understand and follow this advice, it could
                save your life.
            </p>
            <p className={styles.strapline}>Top 10 Beach safety videos</p>
            <ol className={styles.list}>
                <li>
                    <iframe
                        title={
                            "Choose a lifeguarded beach and swim between the flags."
                        }
                        className={styles.iframe}
                        src="https://www.youtube.com/embed/OAjYcZDmSnw"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    <h5>1. Swim between the flags</h5>
                    <p>
                        If you are heading out to the beach, choose a
                        lifeguarded one and remember the red and yellow flags
                        show the safest area to swim.
                    </p>
                </li>
                <li>
                    <iframe
                        title={"3Rs rip survival plan"}
                        className={styles.iframe}
                        src="https://www.youtube.com/embed/5jKLXN9svdQ"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    <h5>2. Learn how to survive a rip</h5>
                    <p>
                        Rips are a major hazard on New Zealand beaches and can
                        be deadly. Learn the 3Rs Rip Survival Plan – it could
                        save your life!{" "}
                    </p>
                </li>
                <li>
                    <iframe
                        title={
                            "Ask a lifeguard for advice as beach conditions change."
                        }
                        className={styles.iframe}
                        src="https://www.youtube.com/embed/Gd4mKgWta40"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    <h5>3. Talk to the surf lifeguards</h5>
                    <p>
                        Even though conditions can change quickly, our
                        lifeguards keep a constant eye on the beach situation as
                        they continuously scope the beach for hazards, and keep
                        on top of weather forecasts and understand the swell and
                        tide conditions – they’re a friendly bunch too!
                    </p>
                </li>
                <li>
                    <iframe
                        title={
                            "Always keep a very close eye on young children in or near the water."
                        }
                        className={styles.iframe}
                        src="https://www.youtube.com/embed/CFB6RQyeutY"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    <h5>4. Keep kids within arm’s reach</h5>
                    <p>
                        Keep your kids within arm's reach at all times in or
                        near the water – rogue or large waves move really
                        quickly and unexpectedly and can sweep kids away or
                        knock them off their feet.
                    </p>
                </li>
                <li>
                    <iframe
                        title={
                            "Don't overestimate your ability in the water or your children's ability to cope in the conditions."
                        }
                        className={styles.iframe}
                        src="https://www.youtube.com/embed/YHw3uQI_iEA"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    <h5>5. Know your limits</h5>
                    <p>
                        Too many people get into trouble in the water because
                        they simply overestimate their abilities and
                        under-estimate the conditions.
                    </p>
                </li>
                <li>
                    <iframe
                        title={
                            "Get a friend to swim with you - never swim or surf alone"
                        }
                        className={styles.iframe}
                        src="https://www.youtube.com/embed/YAxFEEcF0xM"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    <h5>6. Swim with others</h5>
                    <p>
                        There is safety in numbers. If you get in trouble in the
                        water and you have your friends or family with you, you
                        have instant back-up. They can help you out or get help
                        if needed!
                    </p>
                </li>
                <li>
                    <iframe
                        title={"If in doubt, stay out!"}
                        className={styles.iframe}
                        src="https://www.youtube.com/embed/0JNUfdjFQoU"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    <h5>7. If in doubt, stay out</h5>
                    <p>
                        If you feel uncomfortable about getting into the water,
                        go with your gut feeling and stay out. It's better to be
                        safe than sorry.
                    </p>
                </li>
                <li>
                    <iframe
                        title={
                            "When fishing from rocks, never turn your back to the sea & always wear a lifejacket."
                        }
                        className={styles.iframe}
                        src="https://www.youtube.com/embed/nsSaxDDHQ3Q"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    <h5>8. Rockfishing is a high-risk activity</h5>
                    <p>
                        Rock fishing was the highest-risk activity for fatal
                        drownings in 2018/2019. Take care. Wear your lifejacket,
                        shoes with tread (not gumboots) and never turn your back
                        to the sea as large waves can sweep you off the rocks
                        unexpectedly.
                    </p>
                </li>
                <li>
                    <iframe
                        title={
                            "If you see someone in trouble, call 111 & ask for police as they have a direct line to us."
                        }
                        className={styles.iframe}
                        src="https://www.youtube.com/embed/qlLxtQhKC_M"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    <h5>
                        9. If you see someone in trouble - tell a lifeguard or
                        CALL 111
                    </h5>
                    <p>
                        If there are lifeguards on patrol, let them know. If you
                        can’t see any lifeguards, CALL 111 & ASK FOR THE POLICE.
                        They have a direct line to our emergency call out squads
                        across New Zealand and Coastguard NZ as well.
                    </p>
                </li>
                <li>
                    <iframe
                        title={
                            "Be sun smart. Slip, Slop, Slap & Wrap. Protect your skin and eyes from the sun's damaging rays."
                        }
                        className={styles.iframe}
                        src="https://www.youtube.com/embed/V61pOnrgQZE"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    <h5>10. Remember your sun protection</h5>
                    <p>
                        New Zealand’s harsh sun during the warmer months and
                        alarming skin cancer rates are why we need to cover up
                        at the beach.
                    </p>
                </li>
            </ol> */}
                <BackButtonDrawer paddingBottom onClick={closeDrawer} />
            </div>
        </Drawer>
    );
}
