import ACLogo from "../images/logos/ACLogo";
import nrc from "../../resources/images/logos/nrc_logo.png";
import SLS from "../../resources/images/logos/sls_logo.svg";
import watercare from "../../resources/images/logos/watercare_logo.jpg";
import arphs from "../../resources/images/logos/health-nz-logo.png";
import dpanz from "../../resources/images/logos/drowning-prevention-logo.png";
import styles from "./PartnerLogos.module.scss";
import classNames from "classnames";

export default function PartnerLogos() {
    return (
        <div className={styles.partners_container}>
            <a
                className={classNames(styles.col, styles.ac_logo)}
                href="https://www.aucklandcouncil.govt.nz"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Auckland Council"
            >
                <ACLogo />
            </a>
            <a
                className={classNames(styles.col, styles.sls_logo)}
                href="https://www.surflifesaving.org.nz"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Surf Life Saving NZ"
            >
                <img className={styles.slsnz_logo_flat} src={SLS} alt="" />
            </a>
            <a
                className={classNames(styles.col, styles.northland_logo)}
                href="http://www.nrc.govt.nz"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Northland Regional Council"
            >
                <img className={styles.nrc} src={nrc} alt="" />
            </a>
            <a
                className={classNames(styles.col, styles.watercare_logo)}
                href="http://www.watercare.co.nz"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Watercare"
            >
                <img
                    className={styles.watercare}
                    src={watercare}
                    alt="watercare"
                />
            </a>
            <a
                className={classNames(styles.col, styles.arphs_logo)}
                href="https://www.arphs.health.nz/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Auckland Regional Public Health Service"
            >
                <img className={styles.arphs} src={arphs} alt="" />
            </a>
            <a
                className={classNames(styles.col, styles.dpanz_logo)}
                href="https://www.dpanz.org.nz/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Drowning Prevention Auckland"
            >
                <img className={styles.dpanz} src={dpanz} alt="" />
            </a>
        </div>
    );
}
