import { ButtonHTMLAttributes, PropsWithChildren, ReactNode } from "react";
import classNames from "classnames";
import styles from "./DrawerButton.module.scss";
import Close from "../../images/icons/Close";
import ChevronLeft from "../../images/chevrons/ChevronLeft";
import Star from "../../images/icons/Star";
import ChevronDown from "../../images/chevrons/ChevronDown";

type Props = {
    icon?: ReactNode;
    onClick?: () => void;
} & ButtonHTMLAttributes<HTMLButtonElement>;

type LocationProps = {
    icon?: ReactNode;
    isSubMenu?: boolean;
    onClick?: () => void;
    onFavourite?: () => void;
    isFavourite?: boolean;
    isFavouritesLoading?: boolean;
    locationName?: string;
    locationMaoriName?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const CloseButton = (props: Props) => (
    <div className={classNames(styles.button, styles.flex_end)}>
        <div
            className={styles.close_button}
            onClick={props.onClick}
            role="button"
        >
            <span>Close</span>
            <Close className={styles.large_icon} />
        </div>
    </div>
);
export const HomeButton = (props: Props) => (
    <div className={classNames(styles.button, styles.flex_start)}>
        <div
            className={styles.home_button}
            onClick={props.onClick}
            role="button"
        >
            <ChevronLeft className={styles.large_icon} />
            <span>Map</span>
        </div>
    </div>
);
export const LargeBackButton = (props: Props) => (
    <div className={classNames(styles.button, styles.flex_start)}>
        <div
            className={styles.home_button}
            onClick={props.onClick}
            role="button"
        >
            <ChevronLeft className={styles.large_icon} />
            <span>Back</span>
        </div>
    </div>
);

export const BackButton = (props: Props) => (
    <div className={classNames(styles.button, styles.flex_start)}>
        <div
            className={styles.back_button}
            onClick={props.onClick}
            role="button"
        >
            <ChevronLeft className={styles.icon} />
            <span>Back</span>
        </div>
    </div>
);

export const LocationButtons = (props: LocationProps) => (
    <>
        <div className={classNames(styles.button, styles.flex_between)}>
            {!!props.isSubMenu ? (
                <div
                    className={styles.back_button}
                    onClick={props.onClick}
                    role="button"
                >
                    <ChevronLeft className={styles.icon} />
                    <span>Back</span>
                </div>
            ) : (
                <div
                    className={styles.close_button}
                    onClick={props.onClick}
                    role="button"
                >
                    <ChevronLeft className={styles.icon_desktop} />
                    <ChevronDown className={styles.icon_mobile} />
                    <span>Close</span>
                </div>
            )}
            <div
                className={classNames(styles.favourite_button, {
                    [styles.disabled]: props.isFavouritesLoading
                })}
                onClick={props.onFavourite}
                role="button"
            >
                <Star
                    className={styles.large_icon_favorite}
                    fill={props?.isFavourite}
                />
            </div>
        </div>
        <span className={styles.location}>{props.locationName}</span>
        {props.locationMaoriName && <h5>{props.locationMaoriName}</h5>}
    </>
);

export default function DrawerButton(props: PropsWithChildren<Props>) {
    return (
        <div className={classNames(styles.button)}>
            <div
                className={styles.close_button}
                onClick={props.onClick}
                role="button"
            >
                <span>Close</span>
                <Close className={styles.icon} />
            </div>
        </div>
    );
}
