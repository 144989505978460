import styles from "./AccountDrawers.module.scss";
import { DrawerActionType, useDrawerContext } from "../../../../context/drawer";
import { useFirebase } from "../../../../context/firebase";
import { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import FormikInput from "../../../formik/FormikInput";
import { SignupUserRequest } from "../../../../types/SafeswimUser";
import Drawer from "../../Drawer";
import Button from "../../../buttons/button/Button";
import Loading from "../../../loading/Loading";
import { ModalActionType, useModalContext } from "../../../../context/modal";
import { HomeButton } from "../../../buttons/drawerButton/DrawerButton";

type FormValue = {
    email: string;
    password?: string;
    confirmPassword?: string;
    fullname: string;
};
const updateSchema = Yup.object({
    fullname: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string().min(6).optional(),
    confirmPassword: Yup.string().min(6).optional()
});
export default function UserProfileDrawer() {
    const { profileDrawerOpen, hide, hideAll } = useDrawerContext();
    const { safeswimUser, updateMe } = useFirebase();
    const [loading, setLoading] = useState<boolean>(false);
    const [initValue, setInitValue] = useState<FormValue>();
    const { showModal } = useModalContext();

    const [error, setError] = useState<string>();

    useEffect(() => {
        if (safeswimUser && !initValue) {
            setInitValue({
                email: safeswimUser.email,
                fullname: `${safeswimUser.firstName} ${
                    safeswimUser.lastName ?? ""
                }`
            });
        }
    }, [safeswimUser, initValue]);

    const handleSubmit = async (
        values: FormValue,
        helper: FormikHelpers<FormValue>
    ) => {
        if (!safeswimUser) {
            return;
        }
        const { email, password, fullname, confirmPassword } = values;
        if (password && password !== confirmPassword) {
            setError("Your password and confirmation password do not match");
            helper.setSubmitting(false);
            return;
        }
        const request: SignupUserRequest = {
            email,
            password
        } as SignupUserRequest;
        const names = fullname.split(/ (.*)/);
        if (names.length > 1) {
            request.firstName = names[0];
            request.lastName = names[1];
        } else {
            request.firstName = fullname;
        }
        setInitValue(values);
        setLoading(true);
        try {
            await updateMe(safeswimUser.id, request);
            hide(DrawerActionType.PROFILE_DRAWER);
            // eslint-disable-next-line
        } catch (error: any) {
            const message = error.response?.data?.message;
            setError(`Failed to update: ${message ?? "Please contact admin"}`);
        } finally {
            setLoading(false);
        }
    };

    const closeUserProfileDrawer = () => hideAll();

    return (
        <Drawer
            id={DrawerActionType.PROFILE_DRAWER}
            isOpen={!!profileDrawerOpen}
            position={"right"}
        >
            {loading ? (
                <Loading />
            ) : (
                <div className={styles.container}>
                    <HomeButton onClick={closeUserProfileDrawer} />
                    <div className={styles.content}>
                        <h2>My profile</h2>
                        <Formik<FormValue>
                            initialValues={initValue ?? ({} as FormValue)}
                            validationSchema={updateSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isValid, isSubmitting }) => {
                                return (
                                    <Form className={styles.form}>
                                        <FormikInput
                                            name={"fullname"}
                                            label={"Name"}
                                            placeholder={"Name"}
                                        />
                                        <FormikInput
                                            name={"email"}
                                            label={"Email"}
                                            placeholder={"Email"}
                                        />
                                        <FormikInput
                                            name={"password"}
                                            type={"password"}
                                            label={"Change password"}
                                            placeholder={"Password"}
                                        />
                                        <FormikInput
                                            name={"confirmPassword"}
                                            type={"password"}
                                            label={"Confirm password"}
                                            placeholder={"Confirm password"}
                                        />
                                        {error && (
                                            <div className={"error"}>
                                                {error}
                                            </div>
                                        )}
                                        <div className={styles.button_centre}>
                                            <Button
                                                className={styles.button}
                                                color={"primary"}
                                                type={"submit"}
                                                size="lg"
                                                disabled={
                                                    isSubmitting ||
                                                    !isValid ||
                                                    loading
                                                }
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                        <div className={styles.footer_links}>
                            <Button
                                className={styles.delete_account_link}
                                variant={"link"}
                                onClick={() =>
                                    showModal(
                                        ModalActionType.DELETE_ACCOUNT_MODAL
                                    )
                                }
                            >
                                Delete account
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Drawer>
    );
}
