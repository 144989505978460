import React, {
    createContext,
    PropsWithChildren,
    ReactElement,
    useContext,
    useEffect,
    useState
} from "react";
import { DEFAULT_LAT, DEFAULT_LNG } from "../utils/map";
interface NavigatorContextType {
    loadingGeoLocationPreferences: boolean;
    lat: number;
    lng: number;
}

export const NavigatorContext = createContext<NavigatorContextType>({
    loadingGeoLocationPreferences: true,
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG
});

export const NavigatorProvider = (
    props: PropsWithChildren<unknown>
): ReactElement => {
    const [loadingGeoLocationPreferences, setLoadingGeoLocationPreferences] =
        useState<boolean>(true);
    const [lat, setLat] = useState<number>(DEFAULT_LAT);
    const [lng, setLng] = useState<number>(DEFAULT_LNG);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLat(position.coords.latitude);
                    setLng(position.coords.longitude);
                    setLoadingGeoLocationPreferences(false);
                },
                () => {
                    setLoadingGeoLocationPreferences(false);
                }
            );
        } else {
            setLoadingGeoLocationPreferences(false);
        }
    }, []);

    return (
        <NavigatorContext.Provider
            value={{ lat, lng, loadingGeoLocationPreferences }}
            {...props}
        />
    );
};

export function useNavigatorContext() {
    const { lat, lng, loadingGeoLocationPreferences } =
        useContext(NavigatorContext);
    return { lat, lng, loadingGeoLocationPreferences };
}
