import { DrawerActionType, useDrawerContext } from "../../../context/drawer";
import styles from "./MapKeyButton.module.scss";

export default function MapKeyButton() {
    const { show } = useDrawerContext();

    return (
        <button
            type={"button"}
            onClick={() => show(DrawerActionType.MAP_KEY_DRAWER)}
            className={styles.map_key_button}
            aria-label="Map key"
        ></button>
    );
}
