import Drawer from "../../Drawer";
import React, { useEffect, useState } from "react";
import SafeswimLocation, { Forecast } from "../../../../types/SafeswimLocation";
import noSls from "../../../../resources/images/random/no_sls_drawer.svg";
// import phoneCircle from "../../../../resources/images/random/phone_circle.png";
import safety from "../../../../resources/images/random/swim_hazard.svg";
import tide from "../../../../resources/images/random/tide_circle.svg";
import slsFlag from "../../../../resources/images/random/sls_flag.svg";
import {
    containsTag,
    getCurrentPatrolHours,
    getCurrentWaterQualityLevel,
    getFavoriteWaterQualityText,
    getNextTides
} from "../../../../utils/location";
import styles from "./favouritesDrawer.module.scss";
import Button from "../../../buttons/button/Button";
import { useFirebase } from "../../../../context/firebase";
// import removeBeach from "../../../../resources/images/random/remove_beach.svg";
import PulseLoader from "react-spinners/PulseLoader";
import SearchBox from "../../../searchBox/SearchBox";
import { DrawerActionType, useDrawerContext } from "../../../../context/drawer";
import { useFavouriteBeachContext } from "../../../../context/favouriteBeaches";
import classNames from "classnames";
import { HomeButton } from "../../../buttons/drawerButton/DrawerButton";
import WaterQualityIcon from "../../../images/icons/WaterQuality";
import { WaterQualityLevel } from "../../../../types/WaterQuality";
import orderBy from "lodash/orderBy";
import { getLocationForecast } from "../../../../api/locations";
import { useApiContext } from "../../../../context/api";
import { useMapContext } from "../../../../context/map";
import ChevronRight from "../../../images/chevrons/ChevronRight";
import { ModalActionType, useModalContext } from "../../../../context/modal";

interface FavouritesDrawerProps {
    onViewLocationClick: (l: SafeswimLocation) => void;
}

export const getWaterSafetyAlertOverviewText = (location: SafeswimLocation) => {
    const alert = location.alerts!.hazards[0];
    const isFreshWaterHazard = containsTag(location, "fresh_water_hazard");

    if (isFreshWaterHazard) {
        return "Swimming not advised";
    }

    if (!alert) {
        return "No safety warnings";
    }

    return alert.description.length > 30
        ? `${alert.description.substring(0, 30)}...`
        : alert.description;
};

const NoUser = () => {
    const { show } = useDrawerContext();

    return (
        <>
            <div className={styles.no_user_container}>
                <h2>Save your favourite beaches</h2>
                {/* <p className={styles.large}>
                    Make deciding even simpler by shortlisting your favourite
                    spots to swim.
                </p> */}
                <p>
                    Sign up for notifications of the latest water quality and
                    water safety information at your favourite spot.
                </p>

                <div className={styles.button_group}>
                    <Button
                        onClick={() => show(DrawerActionType.LOGIN_DRAWER)}
                        color={"primary"}
                        size={"lg"}
                    >
                        Login
                    </Button>
                    <Button
                        onClick={() => show(DrawerActionType.SIGNUP_DRAWER)}
                        variant={"outline"}
                        size={"lg"}
                    >
                        Sign up
                    </Button>
                </div>
            </div>
        </>
    );
};

const NoLocation = ({
    onAddLocationClick
}: {
    onAddLocationClick: () => void;
}) => {
    return (
        <div className={styles.no_locations_container}>
            <div className={styles.no_locations}>
                <p className={styles.no_locations_text}>
                    Easily find reports <br /> for your favourite locations in
                    one place.
                </p>
                <a onClick={onAddLocationClick} className={styles.add_location}>
                    + Add a location to get started
                </a>
            </div>
            <div className={styles.footer}>
                <Button color={"primary"} onClick={onAddLocationClick}>
                    + Add location
                </Button>
            </div>
        </div>
    );
};

type AddLocationProps = {
    onLocationAdd: (l: SafeswimLocation) => void;
    favouriteBeaches?: SafeswimLocation[];
};

const AddingLocation = ({
    favouriteBeaches,
    onLocationAdd
}: AddLocationProps) => {
    const [loadingSearch, setLoadingSearch] = useState<boolean>(true);
    const [searchResults, setSearchResults] = useState<SafeswimLocation[]>([]);
    const favouriteIds = (favouriteBeaches || []).map((f) => f.id);
    const filteredResults = searchResults.filter(
        (l) => !favouriteIds.includes(l.id)
    );

    return (
        <>
            <div className={styles.favourites_header}>
                <h2>Add a location</h2>
            </div>
            <div className={styles.search_container}>
                <SearchBox
                    results={filteredResults}
                    onResultClick={(l: SafeswimLocation) => onLocationAdd(l)}
                    setResultsCallback={setSearchResults}
                    loadingCallback={setLoadingSearch}
                />
                {filteredResults.length === 0 && (
                    <p>
                        Safeswim is a collaborative programme providing
                        real-time advice on the level of risk associated with
                        swimming at specific locations. Safeswim allows beach
                        users to make informed decisions on when and where to
                        swim.
                    </p>
                )}
                {!loadingSearch ? (
                    <div className={styles.search_results}>
                        {filteredResults.map((r, i) => (
                            <p
                                className={styles.search_result}
                                onClick={() => onLocationAdd(r)}
                                key={`search-result-${i}`}
                            >
                                {r.name}{" "}
                                {r.maoriName && <span>({r.maoriName})</span>}
                            </p>
                        ))}
                    </div>
                ) : (
                    <div className={styles.search_loader_container}>
                        <PulseLoader size={10} color={"#ccc"} />
                    </div>
                )}
            </div>
        </>
    );
};

type FavouriteLocationProps = {
    location: SafeswimLocation;
    onViewLocationClick: (location: SafeswimLocation) => void;
};

const FavouriteLocation = ({
    location: l,
    onViewLocationClick
}: FavouriteLocationProps) => {
    const { safeswimUser } = useFirebase();
    const { removeLocation } = useFavouriteBeachContext();
    const { locationObjects } = useApiContext();
    const [forecast, setForecast] = useState<Forecast | null>(null);
    const patrol = getCurrentPatrolHours(l);
    const tides = getNextTides(forecast);
    const isSLSPatrolled = containsTag(l, "sls_patrolled");

    useEffect(() => {
        (async () => {
            const forecast = await getLocationForecast(l.id);
            setForecast(forecast);
        })();
    }, []);

    const onRemoveLocation = async (l: SafeswimLocation) => {
        if (safeswimUser) {
            await removeLocation(l.id);
        }
    };

    const waterQualityLevel = getCurrentWaterQualityLevel(
        locationObjects[l.id]
    );

    return (
        <div className={styles.favourite_location}>
            <div className={styles.location_header}>
                <h4 className={styles.favourite_name}>{l.name}</h4>
                <a
                    className={styles.view_button}
                    onClick={() => onViewLocationClick(locationObjects[l.id])}
                >
                    {" "}
                    View <ChevronRight />
                </a>
            </div>
            <div className={styles.location_row}>
                <img
                    className={classNames(
                        styles.favourites_icon,
                        styles.shrink
                    )}
                    src={isSLSPatrolled && patrol ? slsFlag : noSls}
                    alt={""}
                />
                <div>
                    <div className={styles.row_title}>
                        Surf lifesaving patrol hours
                    </div>
                    <div>
                        {isSLSPatrolled && patrol ? (
                            <span>{patrol.from + " - " + patrol.to}</span>
                        ) : (
                            "This beach is not lifeguarded"
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.location_row}>
                <img
                    className={classNames(
                        styles.favourites_icon,
                        styles.shrink
                    )}
                    src={safety}
                    alt={""}
                />
                <div>
                    <h6 className={styles.row_title}>Safety warnings</h6>
                    <div>
                        {l.alerts && l.alerts.hazards.length > 0
                            ? getWaterSafetyAlertOverviewText(l)
                            : "No safety warnings"}
                    </div>
                </div>
            </div>
            <div className={styles.location_row}>
                <WaterQualityIcon
                    level={waterQualityLevel}
                    className={classNames(styles.favourites_icon, {
                        [styles.green]:
                            waterQualityLevel === WaterQualityLevel.GOOD,
                        [styles.red]:
                            waterQualityLevel === WaterQualityLevel.HIGH_RISK,
                        [styles.black]:
                            waterQualityLevel ===
                            WaterQualityLevel.VERY_HIGH_RISK,
                        [styles.grey]:
                            waterQualityLevel ===
                                WaterQualityLevel.UNAVAILABLE ||
                            !waterQualityLevel
                    })}
                />
                <div>
                    <h6 className={styles.row_title}>Water quality</h6>
                    <div>
                        {getFavoriteWaterQualityText(l, waterQualityLevel) ||
                            "No water quality information available"}
                    </div>
                </div>
            </div>

            <div className={styles.favourite_tide}>
                <div className={styles.tide_column}>
                    <img
                        className={styles.favourites_icon}
                        src={tide}
                        alt={""}
                    />
                    <div>
                        <h6 className={styles.row_title}>Next high tide</h6>
                        <span>{tides.high}</span>
                    </div>
                </div>
                <div className={styles.tide_column}>
                    <img
                        className={styles.favourites_icon}
                        src={tide}
                        alt={""}
                    />
                    <div>
                        <h6 className={styles.row_title}>Next low tide</h6>
                        <span>{tides.low}</span>
                    </div>
                </div>
            </div>
            <div className={styles.remove} onClick={() => onRemoveLocation(l)}>
                Remove
            </div>
        </div>
    );
};

export default function FavouritesDrawer({
    onViewLocationClick
}: FavouritesDrawerProps) {
    const { safeswimUser, authenticating } = useFirebase();
    const { favouriteBeachDrawerOpen, hideAll } = useDrawerContext();
    const { locationObjects } = useApiContext();
    const { map } = useMapContext();
    const { showModal } = useModalContext();
    const {
        favouriteBeaches,
        loadingFavouriteBeaches,
        addLocation,
        addingFavouriteBeach,
        setAddingFavouriteBeach,
        canAddLocation
    } = useFavouriteBeachContext();

    // const canAddFavourite = favouriteBeaches.length < 5;

    const onAddLocationClick = () => {
        if (canAddLocation()) {
            setAddingFavouriteBeach(true);
        } else {
            showModal(ModalActionType.FAVOURITES_MODAL);
        }
    };

    const onLocationAdd = async (l: SafeswimLocation) => {
        if (safeswimUser) {
            await addLocation(l.id);
            setAddingFavouriteBeach(false);
        }
    };

    const renderContent = () => {
        if (!safeswimUser) {
            return <NoUser />;
        }
        if (addingFavouriteBeach) {
            return (
                <AddingLocation
                    onLocationAdd={onLocationAdd}
                    favouriteBeaches={favouriteBeaches}
                />
            );
        }

        if (favouriteBeaches && favouriteBeaches.length > 0) {
            return (
                <>
                    <div className={styles.favourites_header}>
                        <h2>Favourites</h2>
                    </div>
                    <div className={styles.favourite_locations}>
                        {orderBy(favouriteBeaches, ["name"]).map((l) => (
                            <FavouriteLocation
                                key={l.id}
                                location={l}
                                onViewLocationClick={() => {
                                    onViewLocationClick(locationObjects[l.id]);
                                    if (map) {
                                        map.panTo({ lat: l.lat, lng: l.lng });
                                    }
                                }}
                            />
                        ))}
                    </div>
                </>
            );
        }

        return <NoLocation onAddLocationClick={onAddLocationClick} />;
    };

    return (
        <Drawer
            id={DrawerActionType.FAVOURITE_BEACH_DRAWER}
            isOpen={!!favouriteBeachDrawerOpen}
            position={"right"}
            className={"favouritesDrawerBody"}
        >
            {authenticating || loadingFavouriteBeaches ? (
                <div className={styles.favourites_loader}>
                    <PulseLoader size={20} color={"#ccc"} />
                </div>
            ) : (
                <div className={styles.container}>
                    <div className={styles.home_button_container}>
                        <HomeButton onClick={() => hideAll()} />
                    </div>
                    {renderContent()}
                    {!addingFavouriteBeach &&
                        safeswimUser &&
                        (favouriteBeaches || []).length > 0 && (
                            <div className={styles.add_location_button}>
                                <Button
                                    // disabled={!canAddLocation()}
                                    color={"primary"}
                                    size={"lg"}
                                    onClick={onAddLocationClick}
                                >
                                    + Add location
                                </Button>
                            </div>
                        )}
                </div>
            )}
        </Drawer>
    );
}
