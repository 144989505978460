import { useCallback } from "react";
import { useApiContext } from "../../../context/api";
import { DrawerActionType, useDrawerContext } from "../../../context/drawer";
import { useFavouriteBeachContext } from "../../../context/favouriteBeaches";
import SafeswimAlert from "../../../types/SafeswimAlert";
import SafeswimLocation from "../../../types/SafeswimLocation";
import styles from "./FavBeaches.module.scss";
import Button from "../../buttons/button/Button";
import Close from "../../images/icons/Close";
import Star from "../../images/icons/Star";
import { useMapContext } from "../../../context/map";

export default function FavBeaches({
    onLocationClick
}: {
    onLocationClick: (loc: SafeswimLocation) => void;
}) {
    const { favouriteBeaches, setAddingFavouriteBeach, canAddLocation } =
        useFavouriteBeachContext();
    const { alerts, locationObjects } = useApiContext();
    const { show } = useDrawerContext();
    const { map } = useMapContext();
    const onAddFavClick = useCallback(() => {
        show(DrawerActionType.FAVOURITE_BEACH_DRAWER);
        setAddingFavouriteBeach(true);
    }, [show]);

    return (
        <div className={styles.container}>
            {favouriteBeaches.map((b, i) => (
                <FavBeach
                    onClick={() => {
                        onLocationClick(locationObjects[b.id]);
                        if (map) {
                            map.panTo({ lat: b.lat, lng: b.lng });
                        }
                    }}
                    key={`fav_${i}`}
                    location={b}
                    alerts={alerts.filter((a) =>
                        a.locations.map((al) => Number(al)).includes(b.id)
                    )}
                />
            ))}
            {canAddLocation() && (
                <Button
                    color="primary"
                    variant="filled"
                    size="lg"
                    onClick={onAddFavClick}
                    icon={<Close className={styles.rotate_45} />}
                    iconPosition="left"
                >
                    Save a beach
                </Button>
            )}
        </div>
    );
}

type FavBeachProps = {
    location: SafeswimLocation;
    alerts: SafeswimAlert[];
    onClick: () => void;
};

function FavBeach(props: FavBeachProps) {
    const { location, onClick } = props;
    return (
        <Button
            onClick={onClick}
            size="md"
            color="white"
            variant="filled"
            icon={<Star fill />}
            iconPosition="left"
            className={styles.favourite_location}
        >
            {location.name}
        </Button>
    );
}
