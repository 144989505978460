import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useReducer
} from "react";
import SafeswimAlert from "../types/SafeswimAlert";
import SafeswimLocation from "../types/SafeswimLocation";
import SpecialEvent from "../types/SpecialEvent";
import {
    getAllLocationsPatrolsToday,
    getAllMapLocations
} from "../api/locations";
import { getAlerts } from "../api/alerts";
import { getSpecialEvents } from "../api/events";
import Patrol from "../types/Patrol";
import { format } from "date-fns";
import sortBy from "lodash/sortBy";

enum ApiActionTypes {
    SET_LOCATIONS = "SET_LOCATIONS",
    SET_ALERTS = "SET_ALERTS",
    SET_SPECIAL_EVENTS = "SET_SPECIAL_EVENTS",
    SET_PATROLS = "SET_PATROLS"
}

type ApiAction = {
    type: ApiActionTypes;
    payload: SafeswimAlert[] | SafeswimLocation[] | SpecialEvent[] | Patrol[];
};

type ApiState = {
    alerts: SafeswimAlert[];
    waterQualityAlerts: SafeswimAlert[];
    hazardAlerts: SafeswimAlert[];
    locations: SafeswimLocation[];
    locationObjects: { [locationId: number]: SafeswimLocation };
    specialEvents: SpecialEvent[];
    patrols: { [locationId: number]: { [date: string]: Patrol } };
    setPatrols: (patrols: Patrol[]) => void;
};

export const ApiContext = createContext<ApiState>({
    alerts: [] as SafeswimAlert[],
    waterQualityAlerts: [] as SafeswimAlert[],
    hazardAlerts: [] as SafeswimAlert[],
    locations: [] as SafeswimLocation[],
    locationObjects: {},
    specialEvents: [] as SpecialEvent[],
    patrols: {} as { [locationId: number]: { [date: string]: Patrol } },
    setPatrols: () => console.log("context init")
});

const apiReducer = (state: ApiState, action: ApiAction) => {
    switch (action.type) {
        case ApiActionTypes.SET_ALERTS: {
            const alerts = action.payload as SafeswimAlert[];
            const locationObjects = { ...state.locationObjects };
            const waterQualityAlerts: SafeswimAlert[] = [];
            const hazardAlerts: SafeswimAlert[] = [];

            alerts.forEach((a) => {
                if (a.alertType === "water_quality") {
                    waterQualityAlerts.push(a);
                } else if (a.alertType === "hazards") {
                    hazardAlerts.push(a);
                }

                a.locations.forEach((l) => {
                    if (locationObjects[l]) {
                        const location = locationObjects[l];
                        if (!location.alerts) {
                            location.alerts = { waterQuality: [], hazards: [] };
                        }

                        if (
                            a.alertType === "water_quality" &&
                            location.alerts.waterQuality.indexOf(a) === -1
                        ) {
                            if (Number(a.value) === 3) {
                                a.ordinal = -1;
                            }

                            location.alerts.waterQuality.push(a);
                            location.alerts.waterQuality = sortBy(
                                location.alerts.waterQuality,
                                ["ordinal"]
                            );
                        } else if (
                            a.alertType === "hazards" &&
                            location.alerts.hazards.indexOf(a) === -1
                        ) {
                            if (a.value === "dangerous_conditions") {
                                a.ordinal = -2;
                            }

                            location.alerts.hazards.push(a);
                            location.alerts.hazards = sortBy(
                                location.alerts.hazards,
                                ["ordinal"]
                            );
                        }
                    }
                });
            });

            return {
                ...state,
                alerts,
                waterQualityAlerts,
                hazardAlerts,
                locationObjects
            };
        }
        case ApiActionTypes.SET_LOCATIONS: {
            const payload = action.payload as SafeswimLocation[];
            const locationObjects = {} as {
                [locationId: number]: SafeswimLocation;
            };
            payload.forEach((l) => {
                if (!locationObjects[l.id]) {
                    locationObjects[l.id] = l;
                }
            });

            return {
                ...state,
                locations: Object.values(locationObjects),
                locationObjects
            };
        }
        case ApiActionTypes.SET_SPECIAL_EVENTS: {
            const payload = action.payload as SpecialEvent[];
            return {
                ...state,
                specialEvents: payload
            };
        }
        case ApiActionTypes.SET_PATROLS: {
            const payload = action.payload as Patrol[];
            const patrols = state.patrols ?? {};
            for (const patrol of payload) {
                const { location: locationId, date } = patrol;
                const formattedDate = format(new Date(date), "yyyy-MM-dd");
                patrols[locationId] = patrols[locationId] ?? {};
                patrols[locationId][formattedDate] = patrol;
                const location = state.locationObjects[locationId];
                if (location) {
                    if (!location.patrolHours) {
                        location.patrolHours = {};
                    }
                    location.patrolHours[formattedDate] = patrol;
                }
            }
            return {
                ...state,
                patrols
            };
        }
        default: {
            return state;
        }
    }
};

export function useApiContext() {
    return useContext(ApiContext);
}

export const ApiProvider = (props: PropsWithChildren<unknown>) => {
    const [state, dispatch] = useReducer(apiReducer, {} as ApiState);

    const setPatrols = (payload: Patrol[]) => {
        dispatch({
            type: ApiActionTypes.SET_PATROLS,
            payload
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            const [locations, alerts, specialEvents, patrols]: [
                SafeswimLocation[],
                SafeswimAlert[],
                SpecialEvent[],
                Patrol[]
            ] = await Promise.all([
                getAllMapLocations(),
                getAlerts(),
                getSpecialEvents(),
                getAllLocationsPatrolsToday()
            ]);

            dispatch({
                type: ApiActionTypes.SET_LOCATIONS,
                payload: locations
            });

            dispatch({
                type: ApiActionTypes.SET_ALERTS,
                payload: alerts
            });

            dispatch({
                type: ApiActionTypes.SET_SPECIAL_EVENTS,
                payload: specialEvents
            });

            setPatrols(patrols);
        };

        fetchData();
    }, []);

    return <ApiContext.Provider value={{ ...state, setPatrols }} {...props} />;
};
