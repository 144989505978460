export const Wind = ({
    speed,
    direction
}: {
    speed?: number | null;
    direction?: string | null;
}) => {
    let rotate = 0;

    switch (direction) {
        case "N":
            rotate = 180;
            break;
        case "NNE":
            rotate = 202.5;
            break;
        case "NE":
            rotate = 225;
            break;
        case "ENE":
            rotate = 247.5;
            break;
        case "E":
            rotate = 270;
            break;
        case "ESE":
            rotate = 292.5;
            break;
        case "SE":
            rotate = 315;
            break;
        case "SSE":
            rotate = 337.5;
            break;
        case "S":
            rotate = 0;
            break;
        case "SSW":
            rotate = 22.5;
            break;
        case "SW":
            rotate = 45;
            break;
        case "WSW":
            rotate = 67.5;
            break;
        case "W":
            rotate = 90;
            break;
        case "WNW":
            rotate = 112.5;
            break;
        case "NW":
            rotate = 135;
            break;
        case "NNW":
            rotate = 157.5;
            break;
        default:
            break;
    }

    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="48" height="48" rx="8" fill="#21263D" />
            <g transform={`rotate(${rotate}, 24, 24)`}>
                <path
                    opacity={
                        !speed || Number(speed.toFixed(0)) < 39 ? "0.5" : "1"
                    }
                    d="M19.0825 20.8128L24.0825 15.8128L29.0825 20.8128"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.0156 26.3571L24.0156 21.3571L29.0156 26.3571"
                    opacity={
                        !speed || Number(speed.toFixed(0)) < 20 ? "0.5" : "1"
                    }
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.9175 32.1872L23.9175 27.1872L28.9175 32.1872"
                    opacity={
                        !speed || Number(speed.toFixed(0)) < 6 ? "0.5" : "1"
                    }
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};
