type Props = {
    className?: string;
};

export default function ChevronLeft(props: Props) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 7 12"
            fill="none"
        >
            <path
                d="M6 11L1 6l5-5"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
