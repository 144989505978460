import React, { SetStateAction, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { HomeButton } from "../buttons/drawerButton/DrawerButton";
import styles from "./Modal.module.scss";

interface Modal extends React.HTMLAttributes<HTMLDivElement> {
    isOpen: boolean;
    onClose: (e?: any) => SetStateAction<boolean> | void;
}

export const Modal: React.FC<Modal> = ({ isOpen, onClose, children }) => {
    const [isMounted, setIsMounted] = useState<boolean>(false);

    const modalCloseHandler = (
        event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
    ) => {
        event.preventDefault();
        event.stopPropagation();
        onClose();
    };

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        }
        return () => {
            document.body.style.overflow = "unset";
        };
    }, [isOpen]);

    const modalContent = (
        <>
            {isOpen ? (
                <div className={styles.modalWrapper}>
                    <div
                        className={styles.modalOverlay}
                        onClick={modalCloseHandler}
                    />
                    <div className={styles.modalInner}>
                        <div className={styles.modalBody}>
                            <HomeButton onClick={onClose} />
                            {children}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );

    if (isMounted) {
        const portalElement = document.getElementById("modal-portal");
        if (portalElement) {
            return ReactDOM.createPortal(modalContent, portalElement);
        }
        return null;
    }
    return null;
};
