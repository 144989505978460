type Props = {
    className?: string;
};

export default function ChevronDown(props: Props) {
    return (
        <svg
            className={props.className}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="CaretDown">
                <path
                    id="Vector"
                    d="M13 6L8 11L3 6"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
