import { useDrawerContext } from "../../../../context/drawer";
import Drawer from "../../Drawer";
import styles from "./FacilitySearchDrawer.module.scss";
import { BEACH_FEATURES } from "../../../../utils/constants";
import { useCallback, useMemo, useState } from "react";
import SafeswimLocation from "../../../../types/SafeswimLocation";
import { fireTagEvent } from "../../../../utils/tagmanager";
import { useMapContext } from "../../../../context/map";
import { useMobileState } from "../../../../hooks/useMobileState";
import Check from "../../../images/icons/Check";
import Close from "../../../images/icons/Close";
import { useApiContext } from "../../../../context/api";
import { HomeButton } from "../../../buttons/drawerButton/DrawerButton";
import IconButton from "../../../buttons/iconButton/IconButton";

type Props = {
    onLocationClick: (location: SafeswimLocation) => void;
};

export default function FacilitySearchDrawer({ onLocationClick }: Props) {
    const { facilitySearchDrawerOpen, hideAll } = useDrawerContext();
    const { map } = useMapContext();
    const { isMobile } = useMobileState();
    const { locations } = useApiContext();
    const [filters, setFilters] = useState<{
        [key: string]: { id: string; label: string };
    }>({});

    const features = useMemo((): {
        [key: string]: { id: string; label: string };
    } => {
        const _features: { [key: string]: { id: string; label: string } } = {};
        Object.values(BEACH_FEATURES).forEach((f) => {
            _features[f.id] = f;
        });
        return _features;
    }, []);

    const onFilterChange = useCallback(
        (key: string) => {
            if ((key ?? "") !== "") {
                const _filters = { ...filters };
                _filters[key] = features[key];
                setFilters(_filters);
            }
        },
        [filters, locations]
    );

    const onClearFilter = useCallback(() => {
        setFilters({});
    }, []);

    const onRemoveFilter = useCallback(
        (id: string) => {
            const _filters = { ...filters };
            delete _filters[id];
            setFilters(_filters);
        },
        [filters]
    );

    const onResultClick = useCallback(
        (location: SafeswimLocation) => {
            hideAll();
            fireTagEvent("search-click", {
                beachSearch: location.name,
                beachName: location.name
            });
            if (map) {
                map.panTo({
                    lat: location.lat,
                    lng: location.lng
                });
            }
            onLocationClick(location);
        },
        [map, hideAll, onLocationClick]
    );

    const results = useMemo((): SafeswimLocation[] => {
        if (Object.keys(filters).length === 0) {
            return [];
        }
        const filteredFeatureIds = Object.keys(filters);
        return locations
            .filter((l) =>
                filteredFeatureIds.every((f) => l.features.includes(f))
            )
            .sort((a, b) => a.name.localeCompare(b.name));
    }, [locations, filters]);

    const tableData = results.length ? results : locations || [];

    return (
        <Drawer
            id={"facility-search-drawer"}
            isOpen={!!facilitySearchDrawerOpen}
            position={"right"}
            bodyClassName={styles.facility_drawer_body}
        >
            <div className={styles.container}>
                <HomeButton onClick={() => hideAll()} />
                <div className={styles.content}>
                    <h2>Find a facility</h2>
                    <div className={styles.filter_container}>
                        Add one or more facilities to start filtering locations
                        <select
                            className={styles.select}
                            onChange={(event) =>
                                onFilterChange(event.target.value)
                            }
                        >
                            <option value="">
                                Select one or more facilities
                            </option>
                            {Object.keys(features)
                                .sort()
                                .map((o, i) => (
                                    <option key={i} value={o}>
                                        {features[o].label}
                                    </option>
                                ))}
                        </select>
                        <div className={styles.filter}>
                            {Object.values(filters).map((f, i) => (
                                <ActiveFilter
                                    key={i}
                                    filter={f}
                                    onRemove={onRemoveFilter}
                                />
                            ))}
                            {Object.keys(filters).length > 0 && (
                                <div
                                    className={styles.clear_button}
                                    onClick={onClearFilter}
                                >
                                    Clear all filters
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.results_count}>
                    {Object.keys(filters).length > 0 && (
                        <h6>{results.length} locations match your criteria</h6>
                    )}
                </div>
            </div>
            <div className={styles.results_wrapper}>
                {isMobile ? (
                    results.map((result) => (
                        <div
                            key={result.id}
                            className={styles.result}
                            onClick={() => onResultClick(result)}
                        >
                            {result.name}
                        </div>
                    ))
                ) : (
                    <table className={styles.results_table}>
                        <thead>
                            <tr>
                                <th></th>
                                {Object.values(BEACH_FEATURES).map(
                                    (feature) => (
                                        <th key={feature.id}>
                                            {feature.label}
                                        </th>
                                    )
                                )}
                            </tr>
                            <tr>
                                <td></td>
                                {Object.values(BEACH_FEATURES).map(
                                    (feature) => (
                                        <td key={feature.id}>
                                            <img
                                                className={styles.feature_icon}
                                                src={feature.image}
                                                alt={""}
                                            />
                                        </td>
                                    )
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((location) => (
                                <tr key={location.id}>
                                    <td className={styles.location_name}>
                                        {location.name}
                                    </td>
                                    {Object.keys(features).map((feature) => (
                                        <td
                                            key={feature}
                                            className={styles.feature_status}
                                        >
                                            {location.features.includes(
                                                feature
                                            ) ? (
                                                <Check
                                                    className={styles.green}
                                                />
                                            ) : (
                                                <Close
                                                    className={styles.grey}
                                                />
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </Drawer>
    );
}

function ActiveFilter({
    filter,
    onRemove
}: {
    filter: { id: string; label: string };
    onRemove: (id: string) => void;
}) {
    return (
        <div className={styles.active_filter_container}>
            <div className={styles.circle} />
            <span>{filter.label}</span>
            <IconButton
                className={styles.remove_filter_button}
                text={""}
                ariaLabel={"Remove filter"}
                icon={<Close className={styles.remove_filter} />}
                onClick={() => onRemove(filter.id)}
            />
        </div>
    );
}
