import classNames from "classnames";
import { motion, MotionConfig } from "framer-motion";
import { useEffect, useMemo } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import SafeswimLocation, { Theme } from "../../types/SafeswimLocation";
import ACLogo from "../images/logos/ACLogo";
import NRCLogo from "../images/logos/NRCLogo";
import styles from "./Loading.module.scss";
import { useMobileState } from "../../hooks/useMobileState";

interface Props {
    loading?: boolean;
    setLoading: (e: "loaded" | "loading" | "closing") => void;
    location?: SafeswimLocation;
}

const LocationLoading = (props: Props) => {
    const { isMobile } = useMobileState();
    useEffect(() => {
        props.setLoading("loading");
        // make sure loader stays visible at least 1 seconds
        const timeout = setTimeout(() => {
            props.setLoading("loaded");
        }, 1000);
        return () => {
            clearTimeout(timeout);
            props.setLoading("loaded");
        };
    }, [props.location]);

    // const isPtChevBeach = (location?: SafeswimLocation) =>
    //     location?.name?.includes("Point Chevalier");
    // const isPiha = (location?: SafeswimLocation) =>
    //     location?.name?.includes("Piha");
    // const isTakapuna = (location?: SafeswimLocation) =>
    //     location?.name?.includes("Takapuna");

    const creativeImageObj = useMemo(() => {
        const result: { fallback: boolean; clazz?: string; url?: string } = {
            fallback: false
        };
        const { location } = props;
        if (!location?.creativeImage) {
            result.fallback = true;
            const isNorthernBeach =
                location?.regionBased?.includes("Northland");
            const isCentralBeach =
                location?.region === "easternBays" ||
                location?.region === "westernBays" ||
                location?.region === "hauraki";
            const isEasternBeach =
                location?.region === "east" ||
                location?.region === "southEast" ||
                location?.region === "northShore" ||
                location?.region.includes("whangaparaoa");
            result.clazz = classNames({
                [styles.northern_beach]: isNorthernBeach,
                [styles.central_beach]: isCentralBeach,
                [styles.eastern_beach]: isEasternBeach,
                [styles.western_beach]:
                    !isEasternBeach && !isNorthernBeach && !isCentralBeach
            });
        } else {
            const fileUrl = encodeURIComponent(
                decodeURIComponent(location.creativeImage)
                    .replace(/.*creative_/, "")
                    .replace(/_\d+\.svg.*$/, "")
            );
            result.url = `url(/creative-images/${fileUrl}.svg)`;
        }
        return result;
    }, [props.location]);

    return (
        <div className={styles.location_container}>
            {props.location && (
                <div className={styles.wrapper}>
                    <div className={classNames(styles.location_loading)}>
                        <MotionConfig
                            transition={{
                                ease: "easeIn",
                                delay: isMobile ? 0.25 : 0,
                                duration: 0.5
                            }}
                            reducedMotion={"user"}
                        >
                            <motion.div
                                initial={{ opacity: isMobile ? 0 : 1 }}
                                animate={{ opacity: 1 }}
                                className={styles.location_header}
                            >
                                <div className={styles.location_logo}>
                                    {props.location?.regionBased?.includes(
                                        "Auckland"
                                    ) && (
                                        <ACLogo
                                            light={
                                                props.location
                                                    .creativeImageTheme ===
                                                Theme.DARK
                                            }
                                            dark={
                                                props.location
                                                    .creativeImageTheme ===
                                                Theme.LIGHT
                                            }
                                        />
                                    )}
                                    {props.location?.regionBased?.includes(
                                        "Northland"
                                    ) && (
                                        <NRCLogo
                                            light={
                                                props.location
                                                    .creativeImageTheme ===
                                                Theme.DARK
                                            }
                                        />
                                    )}
                                </div>
                                <span
                                    className={classNames(
                                        styles.location_name,
                                        {
                                            [styles.dark_theme]:
                                                props.location
                                                    .creativeImageTheme ===
                                                Theme.DARK
                                        }
                                    )}
                                >
                                    {props.location?.name}
                                </span>
                                <h5>{props.location?.maoriName}</h5>
                            </motion.div>
                            <motion.div
                                initial={{ y: 0, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                className={styles.loading_dots}
                            >
                                <PulseLoader
                                    size={8}
                                    color="rgba(159, 152, 142, 0.3)"
                                />
                            </motion.div>
                            <motion.div
                                initial={{ y: 0, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                style={
                                    !creativeImageObj.fallback
                                        ? {
                                              backgroundImage:
                                                  creativeImageObj.url
                                          }
                                        : undefined
                                }
                                className={classNames(
                                    styles.wrapper,
                                    styles.loading_beach_image,
                                    creativeImageObj.clazz
                                )}
                            />
                        </MotionConfig>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LocationLoading;
