import React, {
    createContext,
    PropsWithChildren,
    ReactElement,
    useContext,
    useReducer
} from "react";

// feel free to add more drawers here, add in DrawerState & drawerReducer correspondingly
export enum DrawerActionType {
    WELCOME_DRAWER = "welcome-drawer",
    EVENT_DRAWER = "event-drawer",
    LOCATION_DRAWER = "location-drawer",
    LONG_TERM_DRAWER = "long-term-drawer",
    MENU_DRAWER = "menu-drawer",
    FAQ_DRAWER = "faq-drawer",
    ABOUT_DRAWER = "about-drawer",
    FAVOURITE_BEACH_DRAWER = "favourite-beach-drawer",
    CONTACT_DRAWER = "contact-drawer",
    NOTIFICATION_DRAWER = "notification-drawer",
    PROFILE_DRAWER = "profile-drawer",
    WATER_QUALITY_DRAWER = "water-quality-drawer",
    WATER_SAFETY_DRAWER = "water-safety-drawer",
    WATER_SAFETY_101_DRAWER = "water-safety-101-drawer",
    WATER_SAFETY_101_DRAWER_RIGHT = "water-safety-101-drawer-right",
    SLS_DRAWER = "sls-drawer",
    HAZARD_DRAWER = "hazard-drawer",
    CLOSE_ALL = "close-all",
    FACILITY_SEARCH_DRAWER = "facility-search-drawer",
    MAP_KEY_DRAWER = "map-key-drawer",
    LOGIN_DRAWER = "login-drawer",
    SIGNUP_DRAWER = "signup-drawer",
    FORGOT_PASSWORD_DRAWER = "forgot-password-drawer",
    CLOSE_ALL_RIGHT_HAND_DRAWERS = "close-all-right-hand-drawers",
    VIDEOS_DRAWER = "latest-video-drawer",
    POLICY_DRAWER = "policy-drawer"
}

type DrawerAction = {
    type: DrawerActionType;
    payload: boolean;
};

type DrawerState = {
    // set properties optionally so you don't need to init one by one
    welcomeDrawerOpen?: boolean;
    eventDrawerOpen?: boolean;
    locationDrawerOpen?: boolean;
    longTermDrawerOpen?: boolean;
    menuDrawerOpen?: boolean;
    loginDrawerOpen?: boolean;
    aboutDrawerOpen?: boolean;
    faqDrawerOpen?: boolean;
    favouriteBeachDrawerOpen?: boolean;
    contactDrawerOpen?: boolean;
    notificationsDrawerOpen?: boolean;
    profileDrawerOpen?: boolean;
    forgotPasswordDrawerOpen?: boolean;
    signupDrawerOpen?: boolean;
    waterQualityDrawerOpen?: boolean;
    waterSafetyDrawerOpen?: boolean;
    waterSafety101DrawerOpen?: boolean;
    waterSafety101DrawerRightOpen?: boolean;
    LatestVideosDrawerOpen?: boolean;
    slsDrawerOpen?: boolean;
    hazardDrawerOpen?: boolean;
    facilitySearchDrawerOpen?: boolean;
    mapKeyDrawerOpen?: boolean;
    policyDrawerOpen?: boolean;
};

type ContextType = {
    hide: (name: DrawerActionType) => void;
    show: (name: DrawerActionType) => void;
    hideAll: () => void;
    hideAllRightHandDrawers: () => void;
    getDrawerState: () => DrawerState;
} & DrawerState;

function drawerReducer(state: DrawerState, action: DrawerAction) {
    switch (action.type) {
        case DrawerActionType.EVENT_DRAWER: {
            const newState = state;
            newState.eventDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.LOCATION_DRAWER: {
            const newState = state;
            newState.locationDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.WELCOME_DRAWER: {
            const newState = state;
            newState.welcomeDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.LONG_TERM_DRAWER: {
            const newState = state;
            newState.longTermDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.MENU_DRAWER: {
            const newState = state;
            newState.menuDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.FAVOURITE_BEACH_DRAWER: {
            const newState = state;
            newState.favouriteBeachDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.FAQ_DRAWER: {
            const newState = state;
            newState.faqDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.ABOUT_DRAWER: {
            const newState = state;
            newState.aboutDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.CONTACT_DRAWER: {
            const newState = state;
            newState.contactDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.NOTIFICATION_DRAWER: {
            const newState = state;
            newState.notificationsDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.PROFILE_DRAWER: {
            const newState = state;
            newState.profileDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.HAZARD_DRAWER: {
            const newState = state;
            newState.hazardDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.WATER_QUALITY_DRAWER: {
            const newState = state;
            newState.waterQualityDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.WATER_SAFETY_DRAWER: {
            const newState = state;
            newState.waterSafetyDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.WATER_SAFETY_101_DRAWER: {
            const newState = state;
            newState.waterSafety101DrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.WATER_SAFETY_101_DRAWER_RIGHT: {
            const newState = state;
            newState.waterSafety101DrawerRightOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.POLICY_DRAWER: {
            const newState = state;
            newState.policyDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.VIDEOS_DRAWER: {
            const newState = state;
            newState.LatestVideosDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.SLS_DRAWER: {
            const newState = state;
            newState.slsDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.FACILITY_SEARCH_DRAWER: {
            const newState = state;
            newState.facilitySearchDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.MAP_KEY_DRAWER: {
            const newState = state;
            newState.mapKeyDrawerOpen = action.payload;

            return { ...newState };
        }
        case DrawerActionType.LOGIN_DRAWER: {
            const newState = state;
            newState.loginDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.SIGNUP_DRAWER: {
            const newState = state;
            newState.signupDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.FORGOT_PASSWORD_DRAWER: {
            const newState = state;
            newState.forgotPasswordDrawerOpen = action.payload;
            return { ...newState };
        }
        case DrawerActionType.CLOSE_ALL: {
            return {};
        }
        case DrawerActionType.CLOSE_ALL_RIGHT_HAND_DRAWERS: {
            return {
                ...state,
                menuDrawerOpen: false,
                loginDrawerOpen: false,
                favouriteBeachDrawerOpen: false,
                contactDrawerOpen: false,
                notificationsDrawerOpen: false,
                profileDrawerOpen: false,
                forgotPasswordDrawerOpen: false,
                signupDrawerOpen: false,
                searchDrawerOpen: false,
                facilitySearchDrawerOpen: false,
                mapKeyDrawerOpen: false,
                waterSafety101DrawerRightOpen: false,
                LatestVideosDrawerOpen: false,
                aboutDrawerOpen: false,
                faqDrawerOpen: false,
                policyDrawerOpen: false
            };
        }
        default:
            return state;
    }
}

const DrawerContext = createContext<ContextType>({
    hide: () => console.log("No provider yet"),
    show: () => console.log("No provider yet"),
    hideAll: () => console.log("No provider yet"),
    hideAllRightHandDrawers: () => console.log("No provider yet"),
    getDrawerState: () => ({} as DrawerState)
});

export function DrawerStateProvider(
    props: PropsWithChildren<unknown>
): ReactElement {
    const [state, dispatch] = useReducer(drawerReducer, {
        welcomeDrawerOpen: false
    });
    const hide = (name: DrawerActionType) =>
        dispatch({
            payload: false,
            type: name
        });

    const show = (name: DrawerActionType) =>
        dispatch({
            payload: true,
            type: name
        });

    const hideAll = () =>
        dispatch({
            payload: false,
            type: DrawerActionType.CLOSE_ALL
        });

    const hideAllRightHandDrawers = () =>
        dispatch({
            payload: false,
            type: DrawerActionType.CLOSE_ALL_RIGHT_HAND_DRAWERS
        });

    const getDrawerState = () => state;

    return (
        <DrawerContext.Provider
            value={{
                hide,
                show,
                hideAll,
                hideAllRightHandDrawers,
                getDrawerState,
                ...state
            }}
            {...props}
        />
    );
}

export function useDrawerContext() {
    return useContext(DrawerContext);
}
