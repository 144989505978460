import React, { useEffect } from "react";
import styles from "./Header.module.scss";
import SafeswimLocation from "../../../../../types/SafeswimLocation";
import { LocationButtons } from "../../../../buttons/drawerButton/DrawerButton";
import {
    DrawerActionType,
    useDrawerContext
} from "../../../../../context/drawer";
import { useFavouriteBeachContext } from "../../../../../context/favouriteBeaches";
import { useFirebase } from "../../../../../context/firebase";
import classNames from "classnames";
import { ModalActionType, useModalContext } from "../../../../../context/modal";

type Props = {
    location?: SafeswimLocation;
    submenuDrawer?: DrawerActionType;
    onClick?: () => void;
    isSigningUp?: boolean;
    setIsSigningUp?: any;
};

export default function Header(props: Props) {
    const { hide, show } = useDrawerContext();
    const { location, submenuDrawer } = props;
    const { showModal } = useModalContext();

    const { safeswimUser } = useFirebase();

    const {
        favouriteBeaches,
        loadingFavouriteBeaches,
        addLocation,
        removeLocation,
        canAddLocation
    } = useFavouriteBeachContext();

    const isFavouriteBeach =
        (favouriteBeaches || []).filter((l) => l.id === location?.id).length >
        0;

    // hook to add favourites if user is signed out and clicked the add to favourites button
    useEffect(() => {
        if (safeswimUser && props.isSigningUp && favouriteBeaches && location) {
            (async () => {
                if (!isFavouriteBeach) {
                    if (canAddLocation()) {
                        await addLocation(location.id);
                    } else {
                        showModal(ModalActionType.FAVOURITES_MODAL);
                    }
                    props.setIsSigningUp(false);
                }
            })();
        }
    }, [
        safeswimUser,
        props.isSigningUp,
        location,
        favouriteBeaches,
        isFavouriteBeach
    ]);

    const handleFavouritesClick = async () => {
        if (!location) {
            return null;
        }
        if (!isFavouriteBeach) {
            if (!safeswimUser) {
                show(DrawerActionType.LOGIN_DRAWER);
                props.setIsSigningUp(true);
            } else if (canAddLocation()) {
                await addLocation(location.id);
            } else {
                showModal(ModalActionType.FAVOURITES_MODAL);
            }
        } else {
            await removeLocation(location.id);
        }
    };

    const closeLocationDrawer = () =>
        props.onClick
            ? props.onClick()
            : hide(DrawerActionType.LOCATION_DRAWER);

    return (
        <div
            className={classNames(styles.headline, {
                [styles.no_border]: !!submenuDrawer
            })}
        >
            {location && (
                <LocationButtons
                    locationName={location?.name}
                    locationMaoriName={location?.maoriName}
                    onClick={
                        submenuDrawer
                            ? () => hide(submenuDrawer)
                            : closeLocationDrawer
                    }
                    onFavourite={handleFavouritesClick}
                    isFavourite={isFavouriteBeach}
                    isFavouritesLoading={loadingFavouriteBeaches}
                    isSubMenu={!!submenuDrawer}
                />
            )}
            {/* TODO - check designs to include māori names */}
            {/* {location?.maoriName && (
                <h3 className={styles.secondary}>{location?.maoriName}</h3>
            )} */}
        </div>
    );
}
