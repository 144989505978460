import React, {
    createContext,
    PropsWithChildren,
    ReactElement,
    useContext,
    useReducer
} from "react";

export enum ModalActionType {
    DELETE_ACCOUNT_MODAL = "delete-account-modal",
    ALERTS_MODAL = "alerts-modal",
    FAVOURITES_MODAL = "favourites-modal",
    SEARCH_MODAL = "search-modal",
    LIFEGUARD_MODAL = "lifeguard-modal",

    CLOSE_ALL = "close-all"
}

type ModalAction = {
    type: ModalActionType;
    payload: boolean;
};

type ModalState = {
    deleteAccountModalOpen?: boolean;
    alertsModalOpen?: boolean;
    favouritesModalOpen?: boolean;
    searchModalOpen?: boolean;
    lifeguardModalOpen?: boolean;
};

type ContextType = {
    hideModal: (name: ModalActionType) => void;
    showModal: (name: ModalActionType) => void;
    hideAllModals: () => void;
    getModalState: () => ModalState;
} & ModalState;

function modalReducer(state: ModalState, action: ModalAction) {
    switch (action.type) {
        case ModalActionType.DELETE_ACCOUNT_MODAL: {
            const newState = state;
            newState.deleteAccountModalOpen = action.payload;
            return { ...newState };
        }
        case ModalActionType.ALERTS_MODAL: {
            const newState = state;
            newState.alertsModalOpen = action.payload;
            return { ...newState };
        }
        case ModalActionType.SEARCH_MODAL: {
            const newState = state;
            newState.searchModalOpen = action.payload;
            return { ...newState };
        }
        case ModalActionType.FAVOURITES_MODAL: {
            const newState = state;
            newState.favouritesModalOpen = action.payload;
            return { ...newState };
        }
        case ModalActionType.LIFEGUARD_MODAL: {
            const newState = state;
            newState.lifeguardModalOpen = action.payload;
            return { ...newState };
        }
        case ModalActionType.CLOSE_ALL: {
            return {};
        }
        default:
            return state;
    }
}

const ModalContext = createContext<ContextType>({
    hideModal: () => console.log("No provider yet"),
    showModal: () => console.log("No provider yet"),
    hideAllModals: () => console.log("No provider yet"),
    getModalState: () => ({} as ModalState)
});

export function ModalStateProvider(
    props: PropsWithChildren<unknown>
): ReactElement {
    const [state, dispatch] = useReducer(modalReducer, {});
    const hide = (name: ModalActionType) =>
        dispatch({
            payload: false,
            type: name
        });

    const show = (name: ModalActionType) =>
        dispatch({
            payload: true,
            type: name
        });

    const hideAll = () =>
        dispatch({
            payload: false,
            type: ModalActionType.CLOSE_ALL
        });

    const getModalState = () => state;

    return (
        <ModalContext.Provider
            value={{
                hideModal: hide,
                showModal: show,
                hideAllModals: hideAll,
                getModalState,
                ...state
            }}
            {...props}
        />
    );
}

export function useModalContext() {
    return useContext(ModalContext);
}
