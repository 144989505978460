import React, {
    createContext,
    PropsWithChildren,
    ReactElement,
    useContext,
    useReducer
} from "react";
import { LocationMarkers } from "../types/SafeswimLocation";

enum MapActionTypes {
    SET_MAP = "SET_MAP",
    MOVE_MAP = "MOVE_MAP",
    SET_MARKERS = "SET_MARKERS",
    SET_LIFEGUARDED_FILTER = "SET_LIFEGUARDED_FILTER"
}

type MapAction = {
    type: MapActionTypes;
    payload: google.maps.Map | boolean | null | LocationMarkers;
};

type ContextType = {
    setMap: (map: google.maps.Map | null) => void;
    setMoveMap: (moveMap: boolean) => void;
    setMarkers: (markers: LocationMarkers) => void;
    setLifeguardFilter: (state: boolean) => void;
} & MapState;

type MapState = {
    map: google.maps.Map | null;
    moveMap: boolean;
    lifeguardedFilterActive: boolean;
    locationMarkers?: LocationMarkers;
};

export const MapContext = createContext<ContextType>({
    map: null,
    moveMap: false,
    lifeguardedFilterActive: false,
    setMap: () => console.log("No provider yet"),
    setMoveMap: () => console.log("No provider yet"),
    locationMarkers: {},
    setMarkers: () => console.log("No provider yet"),
    setLifeguardFilter: () => console.log("No provider yet")
});

export const MapProvider = (
    props: PropsWithChildren<unknown>
): ReactElement => {
    const [state, dispatch] = useReducer(mapReducer, {
        map: null,
        moveMap: false,
        lifeguardedFilterActive: false
    });
    const setMap = (map: google.maps.Map | null) =>
        dispatch({
            type: MapActionTypes.SET_MAP,
            payload: map
        });
    const setMoveMap = (moveMap: boolean) =>
        dispatch({
            type: MapActionTypes.MOVE_MAP,
            payload: moveMap
        });
    const setMarkers = (markers: LocationMarkers) =>
        dispatch({
            type: MapActionTypes.SET_MARKERS,
            payload: markers
        });
    const setLifeguardFilter = (state: boolean) =>
        dispatch({
            type: MapActionTypes.SET_LIFEGUARDED_FILTER,
            payload: state
        });
    return (
        <MapContext.Provider
            value={{
                map: state.map,
                moveMap: state.moveMap,
                locationMarkers: state.locationMarkers,
                lifeguardedFilterActive: state.lifeguardedFilterActive,
                setMarkers,
                setMap,
                setMoveMap,
                setLifeguardFilter
            }}
            {...props}
        />
    );
};

export function useMapContext() {
    const {
        map,
        locationMarkers,
        setMap,
        moveMap,
        setMoveMap,
        setMarkers,
        setLifeguardFilter,
        lifeguardedFilterActive
    } = useContext(MapContext);
    return {
        map,
        locationMarkers,
        setMap,
        moveMap,
        setMoveMap,
        setMarkers,
        setLifeguardFilter,
        lifeguardedFilterActive
    };
}

function mapReducer(state: MapState, action: MapAction): MapState {
    switch (action.type) {
        case MapActionTypes.SET_MAP:
            return {
                ...state,
                map: action.payload as google.maps.Map | null
            };
        case MapActionTypes.MOVE_MAP:
            return {
                ...state,
                moveMap: action.payload as boolean
            };
        case MapActionTypes.SET_MARKERS:
            return {
                ...state,
                locationMarkers: action.payload as LocationMarkers
            };
        case MapActionTypes.SET_LIFEGUARDED_FILTER:
            return {
                ...state,
                lifeguardedFilterActive: action.payload as boolean
            };
        default:
            return state;
    }
}
