import { useField, useFormikContext } from "formik";

type Props = {
    name: string;
    options: Array<{ value: string; label: string }>;
    label?: string;
    helperText?: string;
    disabled?: boolean;
    required?: boolean;
};

export default function FormikSelect(props: Props) {
    const { name, ...otherProps } = props;
    const { isSubmitting } = useFormikContext();
    const fieldProps = useField(name);

    const [field, meta] = fieldProps;
    const fieldError = meta.error;
    const showError = meta.touched && !!fieldError;

    const disabled = otherProps?.disabled || isSubmitting;
    const helperText = showError ? fieldError : otherProps?.helperText;

    return (
        <>
            {otherProps.label ? (
                <label>
                    {otherProps.label}
                    {otherProps.required ? (
                        <span className="required-star">*</span>
                    ) : null}
                </label>
            ) : null}
            <select
                {...field}
                value={field.value}
                onChange={field.onChange}
                disabled={disabled}
            >
                {otherProps.options.map((o, i) => (
                    <option key={`select_${i}`} value={o.value}>
                        {o.label}
                    </option>
                ))}
            </select>
            {showError && <div className={"error"}>{helperText}</div>}
        </>
    );
}
