import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import isEmpty from "lodash/isEmpty";
import { DrawerActionType, useDrawerContext } from "../../../context/drawer";
import SafeswimLocation from "../../../types/SafeswimLocation";
import { containsTag } from "../../../utils/location";
import styles from "./WaterSafetyDrawer.module.scss";
import Drawer from "../Drawer";
import Header from "../locationDrawer/components/header/Header";

type Props = {
    location?: SafeswimLocation;
};

const renderAlerts = (location: SafeswimLocation) => {
    const isFreshWaterHazard = containsTag(location, "fresh_water_hazard");

    if (
        (!location.alerts ||
            isEmpty(location.alerts) ||
            !location.alerts.hazards.length) &&
        !isFreshWaterHazard
    ) {
        return (
            <>
                <p>
                    Warnings noted here indicate risks to the health and safety
                    of beach users. They are uploaded to the Safeswim website by
                    Surf Lifesaving professionals or members of the Safeswim
                    project team on the advice of the Auckland Regional Public
                    Health Service, Watercare, or water quality and pollution
                    response teams in participating councils.
                </p>
                <p>
                    There are no current safety warnings in place at this site.
                </p>
            </>
        );
    }

    if (
        location.alerts &&
        !isEmpty(location.alerts) &&
        location.alerts.hazards.length > 0
    ) {
        return (
            <>
                {location.alerts.hazards.map((alert) => {
                    const alertTitle = alert.title;
                    const date = format(
                        utcToZonedTime(alert.createdAt, "Pacific/Auckland"),
                        "EEE dd MMM yyyy, hh:mm a"
                    );

                    return (
                        <div className={styles.safety_alert} key={alert.id}>
                            <h4>{alertTitle}</h4>
                            <div className={styles.report_date}>
                                Report date {date}
                            </div>
                            <p>
                                {!alertTitle
                                    ? "No safety warnings."
                                    : alert.description}
                            </p>
                        </div>
                    );
                })}
            </>
        );
    }

    return null;
};

const renderFreshWaterHazardCopy = (location: SafeswimLocation) => {
    const isFreshWaterHazard = containsTag(location, "fresh_water_hazard");

    if (!isFreshWaterHazard) {
        return null;
    }

    //  TODO is this still valid? yep :cry emoji:
    if (location.name === "Waitipu") {
        return (
            <>
                <h2>Swimming not advised</h2>
                <p>
                    When swimming in cold water, below 15°C, cold-shock response
                    can occur, and become a causal factor of drowning. This can
                    lead to rapid skin cooling which initiates an immediate gasp
                    response, the inability to breath-hold, and
                    hyperventilation. The gasp response may cause drowning if
                    the head is submersed during the initial entry into cold
                    water. Furthermore, physical functionality to float or swim
                    is reduced in cold water.
                </p>
                <p>
                    The murky water makes it difficult to spot objects under the
                    surface of the water. Any submerged rocks or objects may
                    present a hazard to members of the public diving and jumping
                    into the water. Abrasions, bruising, broken bones, or more
                    serious injuries, such as head or spinal injuries, and death
                    may result.
                </p>
                <p>
                    Slippery rocks are an issue below and above the water line.
                    Rocks submerged under the water are extremely slippery.
                    Individuals should avoid climbing the quarry walls.
                </p>
            </>
        );
    }

    return (
        <>
            <h2>Swimming not advised</h2>
            <p>
                The pool at the bottom of the Hunua Falls drops away sharply
                from the edge in most places. The pool is approximately 17
                metres deep at its deepest point. There are steep drop offs at
                all three main access points into the pool below the falls.
            </p>
            <p>
                The murky water makes it difficult to spot objects under the
                surface of the water. Submerged rocks present a hazard to
                members of the public diving and jumping into the water.
                Abrasions, bruising, broken bones, or more serious injuries,
                such as head or spinal injuries, and death may result.
            </p>
            <p>
                Slippery rocks are an issue below and above the water line.
                Rocks submerged under the water are extremely slippery. Crossing
                the outflow of the falls is hazardous due to the slippery
                submerged rocks. The rock faces either side of the waterfall are
                slippery due to the wash from the falls. Individuals should
                avoid climbing these rocks.
            </p>
            <p>
                The water temperature at Hunua Falls can drop to 7°C. When below
                15°C, cold-shock response can occur, and become a causal factor
                of drowning. This can lead to rapid skin cooling which initiates
                an immediate gasp response, the inability to breath-hold, and
                hyperventilation. The gasp response may cause drowning if the
                head is submersed during the initial entry into cold water.
                Furthermore, physical functionality to float or swim is reduced
                in cold water.
            </p>
            <p>
                Water around the base of the waterfall is highly aerated due to
                agitation of the water, so it is less dense and therefore less
                buoyant. This means it is easier to sink and more difficult to
                float.
            </p>
            <p>
                Flooding during high rainfall events results in increased water
                velocity and an increase in the strength of currents. During
                river flooding events, individuals who enter the water are at
                elevated risk of being swept away, being injured, or drowning.
                The water level may rise rapidly, due to the steep nature of the
                surrounding catchment, and users may be caught unaware.
            </p>
        </>
    );
};

export default function WaterSafetyDrawer(props: Props) {
    const { location } = props;
    const { waterSafetyDrawerOpen } = useDrawerContext();

    if (!location) {
        return null;
    }

    return (
        <Drawer
            isOpen={!!waterSafetyDrawerOpen}
            secondary
            position={"left"}
            id={DrawerActionType.WATER_SAFETY_DRAWER}
        >
            <Header
                location={location}
                submenuDrawer={DrawerActionType.WATER_SAFETY_DRAWER}
            />
            <div className={styles.container}>
                {renderFreshWaterHazardCopy(location)}
                {renderAlerts(location)}
            </div>
        </Drawer>
    );
}
