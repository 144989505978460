import React from "react";
import styles from "./EventDrawer.module.scss";
import green from "../../../resources/images/pins/event_status_green.svg";
import yellow from "../../../resources/images/pins/event_status_orange.svg";
import red from "../../../resources/images/pins/event_status_red.svg";
import IconExternalLink from "../../images/icons/IconExternalLink";
import Footer from "../../footer/Footer";
import SpecialEvent from "../../../types/SpecialEvent";
import Drawer from "../Drawer";
import Loading from "../../loading/Loading";
import { DrawerActionType, useDrawerContext } from "../../../context/drawer";
import { CloseButton } from "../../buttons/drawerButton/DrawerButton";

type Props = {
    event?: SpecialEvent;
};
export default function EventDrawer(props: Props) {
    const { event } = props;
    const { hide, eventDrawerOpen } = useDrawerContext();

    const getStatusIcon = (event: SpecialEvent) => {
        if (event.eventStatus === "ACTIVE") {
            return green;
        } else if (event.eventStatus === "SUBJECT_TO_CONDITIONS") {
            return yellow;
        } else if (
            event.eventStatus === "CANCELLED" ||
            event.eventStatus === "RESTRICTIONS_IN_PLACE"
        ) {
            return red;
        }
    };

    const renderStatusText = (text: string) => {
        let newText = "";

        if (text === "ACTIVE") {
            newText = "Event is on";
        } else if (text === "SUBJECT_TO_CONDITIONS") {
            newText = "Subject to conditions";
        } else if (text === "CANCELLED") {
            newText = "Cancelled";
        } else if (text === "RESTRICTIONS_IN_PLACE") {
            newText = "Restrictions in place";
        }

        return newText;
    };

    const renderContent = () => {
        if (!event) {
            return <Loading />;
        }

        return (
            <div className={styles.location_content}>
                <div className={styles.wrapper}>
                    {!!event.logoUrl && (
                        <div className={styles.event_logo}>
                            <img src={event.logoUrl} alt={""} />
                        </div>
                    )}

                    <h6>{event.rahui ? "Rāhui" : "Special Event"}</h6>
                    <h2 className="header">{event.name}</h2>

                    {!event.rahui && (
                        <div className={styles.event_status_container}>
                            <img src={getStatusIcon(event)} alt={""} />
                            <div>
                                <div className={styles.strapline}>
                                    Event status
                                </div>
                                <div>{renderStatusText(event.eventStatus)}</div>
                            </div>
                        </div>
                    )}

                    <div className={styles.event_content}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: event.description
                            }}
                        />
                        <div className={styles.event_link_container}>
                            {!!event.eventLink && (
                                <React.Fragment>
                                    <a
                                        href={event.eventLink}
                                        target={"_blank"}
                                        rel={"noopener noreferrer"}
                                    >
                                        {event.eventLink}
                                    </a>
                                    <IconExternalLink />
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>

                <div className={styles.footer}>
                    <Footer />
                </div>
            </div>
        );
    };

    return (
        <Drawer
            id={DrawerActionType.EVENT_DRAWER}
            isOpen={!!eventDrawerOpen}
            position={"left"}
        >
            {!event ? (
                <Loading />
            ) : (
                <div className={styles.container}>
                    <div className={styles.close_button}>
                        <CloseButton
                            onClick={() => hide(DrawerActionType.EVENT_DRAWER)}
                        />
                    </div>
                    {renderContent()}
                </div>
            )}
        </Drawer>
    );
}
