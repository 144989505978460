import Drawer from "../Drawer";
import { DrawerActionType, useDrawerContext } from "../../../context/drawer";
import green from "../../../resources/images/mapkey/pin_green_shadow.png";
import red from "../../../resources/images/mapkey/pin_red_shadow.png";
import black from "../../../resources/images/mapkey/pin_black_shadow.png";
import grey from "../../../resources/images/mapkey/pin_grey_shadow.png";
import alert from "../../../resources/images/mapkey/pin_hazard_shadow.png";
import longTermAlert from "../../../resources/images/mapkey/pin_no_swim_shadow.png";
import branded from "../../../resources/images/mapkey/pin_branded_shadow.png";
import sls from "../../../resources/images/mapkey/sls_flag.png";
import slsNoPatrol from "../../../resources/images/mapkey/sls_off_patrol.png";
import redFlag from "../../../resources/images/mapkey/sls_flag_red.png";
import information from "../../../resources/images/mapkey/pin_info_shadow.png";
import styles from "./MapKeyDrawer.module.scss";
import { HomeButton } from "../../buttons/drawerButton/DrawerButton";

export default function MapKeyDrawer() {
    const { mapKeyDrawerOpen, hide } = useDrawerContext();
    return (
        <Drawer
            id={"map-key-drawer"}
            isOpen={!!mapKeyDrawerOpen}
            position={"right"}
        >
            <div className={styles.container}>
                <HomeButton
                    onClick={() => hide(DrawerActionType.MAP_KEY_DRAWER)}
                />

                <div className={styles.content}>
                    <h2>What do the Safeswim icons mean?</h2>
                    <p className={styles.large}>
                        Most regional councils around New Zealand recommend that
                        people shouldn’t swim for at least 48 hours – and
                        sometimes up to five days – after heavy rain.
                    </p>
                    <p>
                        But not all beaches are the same. Depending on the
                        sources of contamination and physical conditions -
                        tides, winds, currents, sunlight – water quality at some
                        beaches may return to normal faster than that and some
                        beaches may take longer.
                    </p>
                    <p>
                        Safeswim provides a more accurate advice tailored to the
                        specific conditions of each location in the programme.
                    </p>
                    <p>
                        The Safeswim website uses colour-coded pins to alert
                        users of health and safety risks in relation to current
                        and predicted swimming conditions.
                    </p>
                    <div className={styles.map_key_container}>
                        <div className={styles.water_quality_block}>
                            <div className={styles.image}>
                                <img src={green} alt={""} />
                            </div>
                            <div className={styles.text}>
                                <h5>Good water quality</h5>
                                <p>
                                    Water quality predicted to meet guidelines –
                                    low risk of illness
                                </p>
                            </div>
                        </div>

                        <div className={styles.water_quality_block}>
                            <div className={styles.image}>
                                <img src={red} alt={""} />
                            </div>
                            <div className={styles.text}>
                                <h5>Swimming not advised</h5>
                                <p>
                                    Water quality predicted to exceed guidelines
                                    – high risk of illness
                                </p>
                            </div>
                        </div>

                        <div className={styles.water_quality_block}>
                            <div className={styles.image}>
                                <img src={black} alt={""} />
                            </div>
                            <div className={styles.text}>
                                <h5>Do not swim</h5>
                                <p>
                                    Wastewater overflow detected nearby – high
                                    risk of illness
                                </p>
                            </div>
                        </div>

                        <div className={styles.water_quality_block}>
                            <div className={styles.image}>
                                <img src={grey} alt={""} />
                            </div>
                            <div className={styles.text}>
                                <h5>Data temporarily unavailable</h5>
                                <p>Data temporarily unavailable</p>
                            </div>
                        </div>
                        <div className={styles.water_quality_block}>
                            <div className={styles.image}>
                                <img src={longTermAlert} alt={""} />
                            </div>
                            <div className={styles.text}>
                                <h5>Long-term alert</h5>
                                <p>
                                    Water quality consistently poor, swimming
                                    not advised
                                </p>
                            </div>
                        </div>
                        <div className={styles.water_quality_block}>
                            <div className={styles.image}>
                                <img src={branded} alt={""} />
                            </div>
                            <div className={styles.text}>
                                <h5>No water quality</h5>
                                <p>
                                    Water safety information only (real-time
                                    water quality not available)
                                </p>
                            </div>
                        </div>
                        <div className={styles.water_quality_block}>
                            <div className={styles.image}>
                                <img src={sls} alt={""} />
                            </div>
                            <div className={styles.text}>
                                <h5>Surf life-saving</h5>
                                <p>
                                    Beach is currently patrolled by surf
                                    lifeguards
                                </p>
                            </div>
                        </div>
                        <div className={styles.water_quality_block}>
                            <div className={styles.image}>
                                <img src={slsNoPatrol} alt={""} />
                            </div>
                            <div className={styles.text}>
                                <h5>Surf life-saving</h5>
                                <p>
                                    Beach is lifeguarded, but surf lifeguards
                                    are not on duty.
                                </p>
                            </div>
                        </div>
                        <div className={styles.water_quality_block}>
                            <div className={styles.image}>
                                <img src={redFlag} alt={""} />
                            </div>
                            <div className={styles.text}>
                                <h5>Dangerous conditions</h5>
                                <p>Dangerous Conditions - No swimming</p>
                            </div>
                        </div>
                        <div className={styles.water_quality_block}>
                            <div className={styles.image}>
                                <img src={alert} alt={""} />
                            </div>
                            <div className={styles.text}>
                                <h5>Hazard</h5>
                                <p>Hazard warning</p>
                            </div>
                        </div>
                        <div className={styles.water_quality_block}>
                            <div className={styles.image}>
                                <img src={information} alt={""} />
                            </div>
                            <div className={styles.text}>
                                <h5>Information</h5>
                                <p>
                                    Take note (used, for instance, when rāhui
                                    are in place)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}
