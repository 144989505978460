import { useField, useFormikContext } from "formik";
import styles from "./Formik.module.scss";

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    disabled?: boolean;
    type?: string;
};

export default function FormikInput({ name, ...props }: Props) {
    const { isSubmitting } = useFormikContext();
    const fieldProps = useField(name);

    const [field, meta] = fieldProps;
    const fieldError = meta.error;

    const disabled = props?.disabled || isSubmitting;
    let helperText = undefined;

    if (!!fieldError && meta.touched) {
        helperText = fieldError;
    }

    if (props.helperText) {
        helperText = props.helperText;
    }

    return (
        <>
            {!!props.label && (
                <label className={styles.label} htmlFor={field.name}>
                    {props.label}
                </label>
            )}
            <input
                className={styles.input}
                id={field.name}
                disabled={disabled}
                type={props.type ?? "text"}
                placeholder={props.placeholder}
                {...field}
            />
            {helperText && <div className={styles.error}>{helperText}</div>}
        </>
    );
}
