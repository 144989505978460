import { Form, Formik } from "formik";
import { useState } from "react";
import { DrawerActionType, useDrawerContext } from "../../../../context/drawer";
import Button from "../../../buttons/button/Button";
import Loading from "../../../loading/Loading";
import Drawer from "../../Drawer";
import styles from "./AccountDrawers.module.scss";
import * as Yup from "yup";
import FormikInput from "../../../formik/FormikInput";
import { HomeButton } from "../../../buttons/drawerButton/DrawerButton";
import { useFirebase } from "../../../../context/firebase";

type FormValue = {
    email: string;
    password: string;
};

const loginSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Required")
});

export default function Login() {
    const { loginDrawerOpen, hideAll, hide, show } = useDrawerContext();
    const { signIn, authenticating: loading } = useFirebase();

    const [error, setError] = useState<string>();
    const initValue = {
        email: "",
        password: ""
    };
    const handleSubmit = async (values: FormValue) => {
        const { email, password } = values;
        try {
            await signIn(email, password);
            hide(DrawerActionType.LOGIN_DRAWER);
        } catch (error) {
            setError("Failed to log in, email or password incorrect");
        }
    };

    const closeLoginDrawer = () => hideAll();

    return (
        <Drawer
            id={DrawerActionType.LOGIN_DRAWER}
            isOpen={!!loginDrawerOpen}
            position={"right"}
        >
            {loading ? (
                <Loading />
            ) : (
                <div className={styles.container}>
                    <HomeButton onClick={closeLoginDrawer} />
                    <div className={styles.content}>
                        <h2>Log in</h2>
                        <Formik<FormValue>
                            initialValues={initValue}
                            validationSchema={loginSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isValid, isSubmitting }) => {
                                return (
                                    <Form className={styles.form}>
                                        <FormikInput
                                            name={"email"}
                                            label={"Email"}
                                            placeholder={"e.g: john@gmail.com"}
                                        />
                                        <FormikInput
                                            name={"password"}
                                            label={"Password"}
                                            type={"password"}
                                            placeholder={"*******"}
                                        />
                                        {error && (
                                            <div className={styles.error}>
                                                {error}
                                            </div>
                                        )}
                                        <div className={styles.button_centre}>
                                            <Button
                                                className={styles.button}
                                                color={"primary"}
                                                type={"submit"}
                                                size="lg"
                                                disabled={
                                                    isSubmitting ||
                                                    !isValid ||
                                                    loading
                                                }
                                            >
                                                Login
                                            </Button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                        <div className={styles.footer_links}>
                            <p>Dont have an account?</p>
                            <Button
                                className={styles.button}
                                variant="link"
                                size="md"
                                onClick={() =>
                                    show(DrawerActionType.SIGNUP_DRAWER)
                                }
                            >
                                Sign up
                            </Button>
                            <Button
                                className={styles.button}
                                variant="link"
                                size="md"
                                onClick={() =>
                                    show(
                                        DrawerActionType.FORGOT_PASSWORD_DRAWER
                                    )
                                }
                            >
                                {" "}
                                Forgot password
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Drawer>
    );
}
