export default function ChevronRight(props: {
    stroke?: string;
    strokeWidth?: number;
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="12"
            fill="none"
            viewBox="0 0 7 12"
            {...props}
        >
            <path
                d="M1 1l5 5-5 5"
                stroke={props.stroke || "currentColor"}
                strokeWidth={props.strokeWidth || 1}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
