import classNames from "classnames";
import styles from "./ZoomButtons.module.scss";
import { useMapContext } from "../../../context/map";
import Close from "../../images/icons/Close";
import Minus from "../../images/icons/Minus";

type Props = {
    onZoomIn: () => void;
    onZoomOut: () => void;
};

export default function ZoomButtons({ onZoomIn, onZoomOut }: Props) {
    const { map } = useMapContext();

    if (!map) {
        return null;
    }

    return (
        <div className={styles.container}>
            <button
                className={classNames(styles.button, styles.underline)}
                onClick={onZoomIn}
                aria-label="Zoom in"
            >
                <Close className={styles.rotate_45} />
            </button>
            <button
                className={styles.button}
                onClick={onZoomOut}
                aria-label="Zoom out"
            >
                <Minus />
            </button>
        </div>
    );
}
