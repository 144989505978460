import styles from "./TideCard.module.scss";
import SafeswimLocation, {
    Forecast,
    TideDTO
} from "../../../types/SafeswimLocation";
import { containsTag } from "../../../utils/location";
import { TideIcon, TideIn, TideOut } from "../../images/forecast/Tides";
import { differenceInMinutes, format, isAfter, parseISO } from "date-fns";
import capitalize from "lodash/capitalize";
import uniqBy from "lodash/uniqBy";
import classNames from "classnames";

type TideCardProps = {
    forecast?: Forecast;
    location?: SafeswimLocation;
};

const getCurrentTide = (tides: TideDTO[]): TideDTO => {
    const now = new Date();
    let tideItem = tides[0];
    tides.forEach((t) => {
        if (now.toISOString() >= t.date) {
            tideItem = t;
        }
    });
    return tideItem;
};

function getTideTitle(tides: TideDTO[]) {
    const currentTide = getCurrentTide(tides);
    const currentTideIndex = tides.indexOf(currentTide);
    let nextTide;

    if (currentTideIndex === tides.length - 1) {
        nextTide = tides[tides.length - 1];
    } else {
        nextTide = tides[currentTideIndex + 1];
    }

    const timeToNextTide = differenceInMinutes(
        parseISO(nextTide.date),
        new Date()
    );
    const timeElapsedFromCurrentTide = differenceInMinutes(
        new Date(),
        parseISO(currentTide.date)
    );

    if (timeToNextTide > 0 && timeToNextTide <= 30) {
        return nextTide.level === "HIGH"
            ? {
                  description: "High tide",
                  icon: <TideIcon level={"HIGH"} />
              }
            : { description: "Low tide", icon: <TideIcon level={"LOW"} /> };
    }

    if (timeElapsedFromCurrentTide > 0 && timeElapsedFromCurrentTide <= 30) {
        return currentTide.level === "HIGH"
            ? {
                  description: "High tide",
                  icon: <TideIcon level={"HIGH"} />
              }
            : { description: "Low tide", icon: <TideIcon level={"LOW"} /> };
    }

    return currentTide.level === "HIGH"
        ? {
              description: "Outgoing tide",
              icon: <TideOut />
          }
        : { description: "Incoming tide", icon: <TideIn /> };
}

export default function TideCard({ forecast, location }: TideCardProps) {
    const isFreshWaterHazard = containsTag(location, "fresh_water_hazard");
    const isFreshWater = containsTag(location, "fresh_water");

    if (
        !forecast ||
        !forecast.today ||
        !forecast.today.tides ||
        !location ||
        isFreshWater ||
        isFreshWaterHazard
    ) {
        return null;
    }

    const currentTide = getCurrentTide(forecast.today.tides);

    const items = uniqBy(
        [
            ...forecast.today.tides,
            ...((forecast.tomorrow || {}).tides || []),
            ...((forecast.dayAfter || {}).tides || [])
        ],
        "date"
    );

    const title = getTideTitle(forecast.today.tides);

    return (
        <div className={styles.tide_card}>
            <div className={styles.header}>
                {title.icon}
                <div>
                    <div className={styles.title}>Tides at this beach</div>
                    <h4>{title.description}</h4>
                </div>
            </div>
            <div className={styles.tide_list}>
                {items.map((t) => {
                    const inactive =
                        isAfter(new Date(), new Date(t.date)) &&
                        items.indexOf(currentTide) > 0;
                    return (
                        <div
                            key={t.date}
                            className={styles.tide_item_container}
                        >
                            {t.date === currentTide.date && (
                                <>
                                    <div className={styles.border} />
                                    <div className={styles.marker} />
                                </>
                            )}
                            <div className={classNames(styles.tide_item)}>
                                <span
                                    className={classNames({
                                        [styles.inactive]: inactive
                                    })}
                                >
                                    {format(new Date(t.date), "eee")}
                                </span>
                                <span
                                    className={classNames({
                                        [styles.inactive]: inactive,
                                        [styles.bold]: !inactive
                                    })}
                                >
                                    {format(new Date(t.date), "h:mmaaa")}
                                </span>
                                <TideIcon level={t.level} />
                                <span
                                    className={classNames({
                                        [styles.inactive]: inactive
                                    })}
                                >
                                    {capitalize(t.level)}
                                </span>
                                <span
                                    className={classNames({
                                        [styles.inactive]: inactive
                                    })}
                                >
                                    {Number(t.value).toFixed(2)}m
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
