import {
    createContext,
    PropsWithChildren,
    ReactElement,
    useContext,
    useEffect,
    useReducer,
    useState
} from "react";
import SafeswimLocation from "../types/SafeswimLocation";
import { useFirebase } from "./firebase";
import {
    addBeachToFavourites,
    getUserFavouriteLocations,
    removeBeachFromFavourites
} from "../api/users";

enum FavouriteActionTypes {
    SET_FAVOURITE_LOCATIONS = "SET_FAVOURITE_LOCATIONS"
}

type FavouriteState = {
    favouriteBeaches: SafeswimLocation[];
};

type FavouriteAction = {
    type: FavouriteActionTypes;
    payload: SafeswimLocation[];
};

function favouriteReducer(
    state: FavouriteState,
    action: FavouriteAction
): FavouriteState {
    switch (action.type) {
        case FavouriteActionTypes.SET_FAVOURITE_LOCATIONS: {
            return {
                ...state,
                favouriteBeaches: action.payload
            };
        }
        default: {
            return state;
        }
    }
}

type ContextType = {
    addLocation: (locationId: number) => Promise<void>;
    removeLocation: (locationId: number) => Promise<void>;
    canAddLocation: () => Boolean;
    loadingFavouriteBeaches: boolean;
    addingFavouriteBeach: boolean;
    setAddingFavouriteBeach: (adding: boolean) => void;
} & FavouriteState;

export const FavouriteContext = createContext<ContextType>({
    favouriteBeaches: [],
    addLocation: () => Promise.resolve(),
    removeLocation: () => Promise.resolve(),
    canAddLocation: () => true,
    loadingFavouriteBeaches: false,
    addingFavouriteBeach: false,
    setAddingFavouriteBeach: () => console.log("")
});

export const FavouriteBeachProvider = (
    props: PropsWithChildren<unknown>
): ReactElement => {
    const [loadingFavouriteBeaches, setLoadingFavouriteBeaches] =
        useState<boolean>(false);
    const [addingFavouriteBeach, setAddingFavouriteBeach] =
        useState<boolean>(false);
    const [state, dispatch] = useReducer(favouriteReducer, {
        favouriteBeaches: []
    });
    const { safeswimUser, authenticating } = useFirebase();

    useEffect(() => {
        if (safeswimUser && !authenticating) {
            const favouriteBeaches = async () => {
                setLoadingFavouriteBeaches(true);
                const locations = await getUserFavouriteLocations(
                    safeswimUser.id
                );
                dispatch({
                    type: FavouriteActionTypes.SET_FAVOURITE_LOCATIONS,
                    payload: locations
                });
                setLoadingFavouriteBeaches(false);
            };
            favouriteBeaches();
        }
    }, [safeswimUser, authenticating]);

    const addLocation = async (locationId: number) => {
        if (safeswimUser) {
            setLoadingFavouriteBeaches(true);
            const newLocations = await addBeachToFavourites(
                safeswimUser.id,
                locationId
            );
            dispatch({
                type: FavouriteActionTypes.SET_FAVOURITE_LOCATIONS,
                payload: newLocations
            });
            setLoadingFavouriteBeaches(false);
        }
    };

    const removeLocation = async (locationId: number) => {
        if (safeswimUser) {
            setLoadingFavouriteBeaches(true);
            const newLocations = await removeBeachFromFavourites(
                safeswimUser.id,
                locationId
            );
            dispatch({
                type: FavouriteActionTypes.SET_FAVOURITE_LOCATIONS,
                payload: newLocations
            });
            setLoadingFavouriteBeaches(false);
        }
    };

    const canAddLocation = () => {
        return state.favouriteBeaches.length < 5;
    };

    return (
        <FavouriteContext.Provider
            value={{
                favouriteBeaches: state.favouriteBeaches,
                addLocation,
                removeLocation,
                canAddLocation,
                loadingFavouriteBeaches,
                addingFavouriteBeach,
                setAddingFavouriteBeach
            }}
            {...props}
        />
    );
};

export function useFavouriteBeachContext() {
    return useContext(FavouriteContext);
}
