import centre from "../../../resources/images/random/centre_button.svg";
import styles from "./CentreButton.module.scss";
import { useMapContext } from "../../../context/map";

type Props = {
    onClick: () => void;
};

export default function CentreButton(props: Props) {
    const { map } = useMapContext();

    if (!map) {
        return null;
    }

    return (
        <button
            className={styles.centre_button_container}
            onClick={props.onClick}
            aria-label="Centre map"
        >
            <img src={centre} alt={""} />
        </button>
    );
}
