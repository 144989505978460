export enum WaterQualityLevel {
    UNAVAILABLE = "grey",
    GOOD = "green",
    HIGH_RISK = "red",
    VERY_HIGH_RISK = "black"
}

export default interface WaterQuality {
    createdAt?: Date;
    recordedAt?: Date;
    value: number;
}